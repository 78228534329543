import { useEffect, useRef, useState } from "react";
import {
  NavLink,
  useLocation,
  useMatches,
  useNavigate,
  useParams,
} from "react-router-dom";
import { attractionRoutes } from "../../../App";
import Footer from "../../../components/global/amp/Footer";
import Header from "../../../components/global/amp/Header";
import { useWindowResize } from "../../../hooks/useWindowResize";
import { useAppSelector } from "../../../store";
import { isTabActive } from "../../../helpers/newAttractionsTabPermission";
import { LockIcon, icons } from "../../../components/WithNavigationBar";
import { apiSlice } from "../../../store/apiSlice";
import { clearNewAttractionLeftOverTab, getNewAttractionWhichTabLeftOver } from "../../../helpers/newAttractionsFinishLater";

export default function AttractionMain() {
  const location = useLocation();
  const navigate = useNavigate();
  const windowSize = useWindowResize();
  const state = useAppSelector((state) => state);
  const { user } = useAppSelector((state) => state.global);
  const matches = useMatches();
  const parentRoute = matches[matches.length > 1 ? matches.length - 2 : 0];
  const fromPage = location.state?.from as string | undefined;
  const claimData = location?.state?.claim ?? undefined;

  const { attractionId } = useParams();

  const {
    data: getAttraction,
  } = apiSlice.useGetAttractionQuery(attractionId ?? "");
  const isStepperActive = user?.role !== 'ADMIN' && !getAttraction?.result?.finished_onboarding;

  const [prevRoute, setPrevRoute] = useState(location.state);
  useEffect(() => {
    setPrevRoute(location.state);
  }, [attractionId, location.pathname]);

  useEffect(() => {
    if (windowSize?.width! >= 1280) {
      navigate("overview", {
        state: {
          from: fromPage ?? undefined,
          claim: claimData ?? undefined,
        },
        replace: true,
      });
    }
  }, []);

  let timeout = useRef<NodeJS.Timeout | undefined>(undefined);
  useEffect(() => {
    const shouldGoToLeftOverTab = getNewAttractionWhichTabLeftOver(attractionId ?? "")
    if (shouldGoToLeftOverTab) {
      navigate(`/attractions/${attractionId}/${attractionRoutes.find(r => r.key === shouldGoToLeftOverTab)?.routeObject.path}`, {
        state: {
          from: fromPage ?? undefined,
          claim: claimData ?? undefined,
        },
        replace: true,
      })

      timeout.current = setTimeout(() => {
        clearNewAttractionLeftOverTab(attractionId ?? "")
      },500)
    }

    return () => {
      // clearTimeout(timeout.current)
    }
  }, [navigate, attractionId])

  const hasPaid = getAttraction?.result?.published_version?.has_paid_to_publish || getAttraction?.result?.published_version?.skip_payment; 

  return (
    <div className="flex flex-col flex-1 bg-[#F5F5FA] doodle-bg">
      <Header />
      <div
        className={`flex flex-col bg-white align-center h-full w-full overflow-y-scroll xl:m-0 xl:overflow-y-hidden xl:left-0 xl:max-w-cs-230 xl:px-0 mt-[85px]`}
      >
        <div className="text-lg text-white px-8 py-3 bg-cs-gray xl:text-base xl:font-normal text-center">
          Choose a section
        </div>

        <div className="p-5">
          <NavLink
            to={prevRoute?.from ?? "/dashboard"}
            className="relative flex items-center py-[11px] px-[13px] text-black bg-transparent hover:bg-[#E9E9F2] rounded-[4px] font-roboto-flex text-sm font-bold max-h-[38px]"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-5 h-5 mr-2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 19.5L8.25 12l7.5-7.5"
              />
            </svg>
            Back to dashboard
          </NavLink>
          {attractionRoutes &&
            attractionRoutes.filter((route) => {
              const isClaim = getAttraction?.result?.ownership_claim?.created_by_user?.email !== getAttraction?.result?.creation_claim?.created_by_user?.email;
              if (route.key === 'ATTRACTION_PAY_PUBLISH' && isClaim) return false;

              if (
                user?.role !== "ADMIN" &&
                route.key === "ATTRACTION_PREMIUM_ENHANCED"
              ) {
                return false;
              }
              if (user?.role === 'ADMIN' && route.key === 'VENUE_OPT_SCORE') return false
              if (user?.role === 'ADMIN' && route.key === 'ATTRACTION_VENUE_OPT_SCORE') return true
              if (!window.connectFeatureFlags?.venueOptScore && ['VENUE_OPT_SCORE', 'ATTRACTION_VENUE_OPT_SCORE'].includes(route.key!)) return false;
              if ('ATTRACTION_VENUE_OPT_SCORE' === route.key && !["STANDARD", "ENHANCED", "PREMIUM"].includes(getAttraction?.result?.listing_package || "BASIC")) return false;
              
              const attraction = state.global.user?.attractions_operator?.find(
                (a) => a.short_id === attractionId
              );

              // hiding "subscription tab" for attractions that haven't had their publishing fee paid yet
              if (!attraction?.show_subscription_options && route.key === 'ATTRACTION_UPGRADE_PLAN') { return false }
              // if (!attraction?.show_ticketing_in_connect && route.key === 'ATTRACTION_TICKETS') { return false }
              // disable ticketing for mobile for now
              if (route.key === 'ATTRACTION_TICKETS') { return false }

              if (hasPaid && route.key === 'ATTRACTION_PAY_PUBLISH') {
                return false;
              }
              // if (isStepperActive && r.key === 'ATTRACTION_UPGRADE_PLAN') {
              //   return false
              // }
              return true
            }).map((route) => {
              const key = route.key;
              const isLocked =
                isTabActive(attractionId ?? "", key ?? "") === "LOCKED" &&
                user?.role !== "ADMIN" && isStepperActive && !['ATTRACTION_CHANGE_LOG'].includes(route.key ?? "");
              return (
                <NavLink
                  end
                  key={route.key}
                  state={{ from: location.pathname }}
                  className={({ isActive }) =>
                    `relative flex items-center gap-3 py-[11px] px-[13px] bg-transparent hover:bg-[#E9E9F2] rounded-[4px] font-roboto-flex text-sm font-bold max-h-[38px] ${
                      isLocked
                        ? "text-[#979797] pointer-events-none cursor-not-allowed"
                        : "text-black"
                    }`
                  }
                  to={`${parentRoute.pathname}${
                    route.routeObject.path ? `/${route.routeObject.path}` : ""
                  }`}
                >
                  {isLocked ? <LockIcon /> : icons[route.key!] ?? ""}
                  {typeof route.title == "function"
                    ? route.title(state)
                    : route.title}
                </NavLink>
              );
            })}
        </div>
      </div>
      <Footer />
    </div>
  );
}
