import { Navigate } from "react-router-dom";
import { useAuthUser } from "../../hooks/useAuth";
import { useAppSelector } from "../../store";
import { Link, useNavigate, useSearchParams, useLocation} from "react-router-dom";

interface IProtectedRoute extends ComponentWithChildren {
    loggedOutOnly?: boolean
}

/*
    TODO - Extend this component to contain role/permission logic
        Grab the user from state and check their permissions against the required permission passed through props
        Specify an optional "redirectTo" prop and default to "/login" if logged out or "/".
*/

const ProtectedRoute: React.FC<IProtectedRoute> = ({ children, loggedOutOnly }) => {

    const { isLoggedIn } = useAuthUser();
    const [searchParams] = useSearchParams()


    if(loggedOutOnly){
        return isLoggedIn ? <Navigate to={"/"} replace /> : <>{children}</>
    } else {
        return isLoggedIn ? <>{children}</> : <Navigate to={"/login"} replace />
    }
}

export default ProtectedRoute;