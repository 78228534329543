import Header from "../../../components/global/amp/Header";
import Footer from "../../../components/global/amp/Footer";
import { z } from "zod";
import { useEffect, useState, useMemo, ReactNode } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  SubmitHandler,
  useForm,
  Controller,
  useFieldArray,
  useWatch,
  FieldArrayWithId,
  UseFieldArrayRemove,
  UseFieldArrayAppend,
  Control,
  UseFormGetValues,
  UseFormSetValue,
  FieldErrors,
  UseFormRegister,
  FieldPath,
  UseFormTrigger,
  FieldError,
} from "react-hook-form";
import TextInput from "../../../components/form/TextInput";
import { useNavigate, useParams } from "react-router-dom";
import { apiSlice } from "../../../store/apiSlice";
import RichTextEditor, {
  getWordLength,
} from "../../../components/form/RichTextEditor";
import { globalActions } from "../../../store/globalSlice";
import { restartAnimation } from "../../../components/global/amp/ToastNotification";
import { useAppDispatch, useAppSelector } from "../../../store";
import { UnsavedModal } from "../../../components/global/amp/UnsavedModal";
import { hasUrl } from "../../../helpers/hasUrl";
import { PrimaryButton } from "../../../components/global/PrimaryButton";
import { OutlineButton } from "../../../components/global/OutlineButton";
import { ErrorMessage } from "../../../components/global/ErrorMessage";
import { SingleAttractionTemplate } from "../../../components/global/SingleAttractionTemplate";
import { isImageTabLocked, unlockNewAttractionTab } from "../../../helpers/newAttractionsTabPermission";
import { RevertToSavedModal } from "../../../components/global/RevertToSavedModal";
import Stepper from "../../../components/amp/Stepper";
import Select from "react-select";
import SingleSelectOption from "../../../lib/react-select/SingleSelectOption";
import { SaveButtonsEditAttraction } from "../../../components/amp/SaveButtonsEditAttraction";
import { PointingArrowHintSm } from "../../../components/amp/PointingArrowHintSm";
import { Button } from "../../../components/global/Button";
import {
  clearNewAttractionStepLeftOver,
  getNewAttractionWhichStepLeftOver,
  setNewAttractionLeftOverTab,
} from "../../../helpers/newAttractionsFinishLater";
import { LightBulb, LightningIcon } from "../../../components/amp/LightBulbTooltip";
import { getVoScoreTooltip, useVoScoreNeedImprovementsFields } from "../../../hooks/useVoScoreNeedImprovementsFields";

const textareaClassName = `w-full h-40 py-2.5 px-5 mt-3 rounded-lg text-cs-gray text-md border-[#CFDBD5] border-cs-1 outline-cs-pink placeholder:text-sm placeholder:text-[#CFDBD5] placeholder:font-normal placeholder:leading-[21px]`;
const categories = [
  "parking",
  "toilets",
  "familyFriendly",
  "fooddrinks",
  "a11y",
  "booking",
  "privateHire",
  "publicTransport",
  "other",
] as const;
type Categories = (typeof categories)[number];
const categoriesEmojies: Record<Categories, string> = {
  parking: "🚗",
  toilets: "🚻",
  familyFriendly: "👨‍👩‍👧‍👦",
  fooddrinks: "🍟",
  a11y: "♿",
  booking: "📅",
  privateHire: "👩‍💻",
  publicTransport: "🚄",
  other: "",
};

const categoriesOptions: {
  label: string;
  value: Categories;
}[] = [
  {
    label: "Parking At Your Attraction",
    value: "parking",
  },
  {
    label: "Toilets At Your Attraction",
    value: "toilets",
  },
  {
    label: "Family Friendly Options",
    value: "familyFriendly",
  },
  {
    label: "Food & Drink",
    value: "fooddrinks",
  },
  {
    label: "Accessibility Information",
    value: "a11y",
  },
  {
    label: "Tickets & Booking",
    value: "booking",
  },
  {
    label: "Private Hire & Birthday Parties",
    value: "privateHire",
  },
  {
    label: "Public Transport To Your Attraction",
    value: "publicTransport",
  },
];

interface IAttractionFaqs {
  showPublishingButtons: boolean;
}

const extraNoteWordValidator = (value: string | null | undefined) => {
  if (!value) return true;

  const characterCount = getWordLength(value);
  const maxCharCount = 100;

  return characterCount <= maxCharCount;
};
const priceValidator = z
  .number({ invalid_type_error: "Please enter a number" })
  .min(1, { message: "Please input a price greater than 1 pound." })
  .max(9000, { message: "Please input a price less than 9000 pounds." });
const extraFaq = z.object({
  title: z
    .string()
    .min(5, "Please enter a question longer than 5 characters")
    .nullable()
    .optional(),
  extraNotes: z
    .string()
    .min(1, "Answer is required")
    .superRefine((value, ctx) => {
      if (hasUrl(value ?? "")) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `You are not allowed include a url.`,
        });
      }
    })
    .nullable()
    .optional(),
  category: z.enum([...categories, "other"]),
});
const richTextFieldValidation = z
  .string()
  .optional()
  .nullable()
  .refine(extraNoteWordValidator)
  .superRefine((value, ctx) => {
    if (hasUrl(value ?? "")) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `You are not allowed include a url.`,
      });
    }
  });
export const booleanValidation = z
  .boolean({
    invalid_type_error: "Please select Yes or No",
  })
  .nullable()
  .superRefine((val, ctx) => {
    if (typeof val !== "boolean") {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Please select Yes or No",
      });
    }
  });

const schema = (role: string) => z.object({
  // Parking
  parking: z.object({
    facilities_parking: booleanValidation,
    extraNotes: richTextFieldValidation,

    facilities_no_parking: booleanValidation, // Not Used / [OLD]
    facilities_onsite_parking: booleanValidation.optional(), // Not Used / [OLD]
    facilities_nearby_parking: booleanValidation.optional(), // Not Used / [OLD]

    accessible: z.object({
      value: booleanValidation,
      note: richTextFieldValidation,
    }),
    prebooked: z.object({
      value: booleanValidation,
      note: richTextFieldValidation,
    }),
    fees: z.object({
      value: booleanValidation,
      charge: z.array(
        z.object({
          time: z.string(),
          fee: priceValidator,
        })
      ),
      note: richTextFieldValidation
    }),
    location: z
      .enum(["onsite", "5min", "10min", "over10min"])
      .nullable()
      .superRefine((val, ctx) => {
        if (typeof val !== "string") {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Required",
          });
        }
      }),
    locationNote: richTextFieldValidation  
  }),

  // Toilets
  toilets: z.object({
    extraNotes: richTextFieldValidation,

    facilities_toilets: booleanValidation,
    facilities_baby_changing: booleanValidation,
    facilities_baby_changing_note: richTextFieldValidation,
    facilities_accessible_toilets: booleanValidation,

    accessibleNote: richTextFieldValidation,
    easy_to_find: booleanValidation,
    maintained: booleanValidation,
  }),

  // Family Friendly
  babyFriendly: z.object({
    facilities_baby_changing: booleanValidation,
    extraNotes: richTextFieldValidation,
  }),
  pushchair: z.object({
    facilities_pushchair_friendly: booleanValidation,
    extraNotes: richTextFieldValidation,
  }),
  dog: z.object({
    pet_friendly: booleanValidation,
    extraNotes: richTextFieldValidation,
  }),
  family: z.object({
    babyFriendly: booleanValidation,

    prams: z.object({
      allowed: booleanValidation,
      forHire: z.object({
        hire: booleanValidation,
        price: z
          .object({
            value: priceValidator,
            type: z.string().optional(),
          })
          .array(),
      }),
    }),
    babyPlay: z.object({
      value: booleanValidation,
      note: z
        .string()
        .nullable()
        .optional()
        .superRefine((val, ctx) => {
          if ((val?.trim().split(" ")?.length ?? 0) > 100) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: "Please enter less than 100 words.",
            });
          }
        }),
    }),

    pushchair: z.object({
      parking: booleanValidation,
      showArea: z
        .enum(["yes", "no", "none"])
        .nullable()
        .superRefine((val, ctx) => {
          if (typeof val !== "string") {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: "Required",
            });
          }
        }),
    }),

    breastfeedingRestroom: booleanValidation,
    breastfeedingRestroomNote: richTextFieldValidation,

    dog: z.object({
      lead: booleanValidation,
      breed: booleanValidation,
      breedNote: z.string().nullable().optional(),
      water: booleanValidation,
      fee: booleanValidation,
      feePrice: priceValidator,
    }),
  }),

  // Food and drinks
  bringFood: z.object({
    facilities_picnics_allowed: booleanValidation,
    extraNotes: richTextFieldValidation,
  }),
  buyFood: z.object({
    facilities_food: booleanValidation,
    extraNotes: richTextFieldValidation,

    facilities_onsite_restaurant: booleanValidation.optional(), // Not used [OLD]
    facilities_snacks_available: booleanValidation.optional(), // Not used [OLD]
    facilities_no_food_available: booleanValidation.optional(), // Not used [OLD]
  }),
  foodAndDrink: z.object({
    extraNotes: richTextFieldValidation,
  }),
  food: z.object({
    pricingPoints: z.object({
      value: booleanValidation,
      note: z.string(),
    }),
    bringInFoodRestrictions: z.object({
      value: booleanValidation,
      note: z.string(),
    }),
    picnic: booleanValidation,
    warmUpPlace: booleanValidation,
    healthyOptions: booleanValidation,
    averageCost: priceValidator.nullable(),
    averageCostNote: richTextFieldValidation,
    kidsHighChairs: booleanValidation,
  }),

  // A11y
  wheelchair: z.object({
    extraNotes: richTextFieldValidation,
    facilities_wheelchair_friendly: booleanValidation, // not used, TODO: maybe map?
  }),
  a11y: z.object({
    allAreas: booleanValidation,
    rampLifts: booleanValidation,
    wheelchairAssist: booleanValidation,
    wheelchairAssistNote: richTextFieldValidation,
    wheelchairSeating: z.enum(["yes", "no", "none"]).nullable(),
    wheelchairRent: z.object({
      value: booleanValidation,
      price: z
      .number({ invalid_type_error: "Please enter a number" })
      .min(0, { message: "Please input a price greater than 0 pounds (free)." })
      .max(9000, { message: "Please input a price less than 9000 pounds." }),
      note: richTextFieldValidation,
    }),
  }),

  // Booking
  tickets: z.object({
    prebook_tickets: booleanValidation,
    sell_tickets: z
      .boolean({
        invalid_type_error: "Please select Yes or No",
      })
      .nullable()
      .superRefine((val, ctx) => {
        if (typeof val !== "boolean" && role !== 'ADMIN') {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Please select Yes or No",
          });
        }
      }),
    discount: z
      .boolean({
        invalid_type_error: "Please select Yes or No",
      })
      .nullable()
      .superRefine((val, ctx) => {
        if (typeof val !== "boolean" && role !== 'ADMIN') {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Please select Yes or No",
          });
        }
      }).optional(),
    extraNotes: richTextFieldValidation,
  }),

  // Private Hire
  privateHire: z
    .object({
      private_hire: booleanValidation,
      extraNotes: richTextFieldValidation,

      packages: z.string(),
      privateRoom: z.object({
        value: booleanValidation,
        note: z.string(),
      }),
      catering: booleanValidation,
      cateringNote: richTextFieldValidation,
      maxGuests: z
        .number({ invalid_type_error: "Please enter a number" })
        .multipleOf(1, { message: "Please enter a full number" })
        .min(0, { message: "Please input a value greater than 0." })
        .max(9000, { message: "Please input a value less than 9000." })
        .nullable()
        .superRefine((val, ctx) => {
          if (typeof val !== "number") {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: "Required",
            });
          }
        }),
      maxGuestsNote: richTextFieldValidation,
    })
    .superRefine((val, ctx) => {
      // packages only required if
      if (val.private_hire && !val.packages.trim()) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Required",
          path: ["packages"],
        });
      }
    }),

  // Public transport
  publicTransportInformation: z.object({
    extraNotes: richTextFieldValidation,

    frequency: z.string(),
    closestStop: z.string(),
    available: z
      .object({
        none: z.boolean().nullable(),
        note: z.string(),
      })
      .superRefine((val, ctx) => {
        if (!val.none && !val.note) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Required",
            path: ["note"],
          });
        }
      }),
  }),

  // Other
  extraFaqs: z.array(extraFaq).nullable().optional(),
  extraFaqsNew: extraFaq.optional(), // Not sent to backend

  additionalInformation: z.object({
    extraNotes: richTextFieldValidation,
  }),

  // Config
  _completed: z.boolean(),
});

export type AttractionFaqsForm = z.infer<ReturnType<typeof schema>>;

type Keys = FieldPath<AttractionFaqsForm>;
function createQuestion<T extends Keys>({
  category,
  condition,
  question,
  description,
  key,
  render,
  extraKeys,
  lightbulb = false,
  overrideTitle
}: {
  category: Categories;
  condition?: boolean;
  question: string | ReactNode;
  description?: string;
  key: T;
  render: React.FC<{
    control: Control<AttractionFaqsForm, any>;
  }>;
  extraKeys?: T[];
  lightbulb?: boolean;
  overrideTitle?: string
}) {
  return {
    category,
    question,
    description,
    key,
    render,
    extraKeys,
    condition,
    lightbulb,
    overrideTitle
  };
}

export default function AttractionFaqs({
  showPublishingButtons,
}: IAttractionFaqs) {
  const { user } = useAppSelector((state) => state.global);
  const { toastNotification } = useAppSelector((state) => state.global);
  const dispatch = useAppDispatch();

  const [getShowRevertModal, setShowRevertModal] = useState<boolean>(false);

  const { attractionId } = useParams();
  const {
    data: getAttraction,
    isSuccess: isGetSuccess,
    isFetching,
    refetch,
    error: attractionError,
  } = apiSlice.useGetAttractionQuery(attractionId ?? "");
  const { result } = getAttraction ?? {};

  const schemaValue = useMemo(() => schema(user?.role || ""), [user?.role])
  const {
    register,
    handleSubmit,
    formState: { isValid, isSubmitted, errors, isDirty, dirtyFields },
    control: formControl,
    setValue,
    getValues,
    reset,
    trigger: triggerValidation,
  } = useForm<AttractionFaqsForm>({
    resolver: async (data, context, options) => {
      return zodResolver(schemaValue)(data, context, options);
    },
    defaultValues: {
      _completed: false,
    },
  });

  const privateHireValue = useWatch({
    control: formControl,
    name: "privateHire.private_hire",
  });
  const canTakeDog = useWatch({
    control: formControl,
    name: "dog.pet_friendly",
  });
  const canBuyFood = useWatch({
    control: formControl,
    name: "buyFood.facilities_food",
  });
  const isThereParking = useWatch({
    control: formControl,
    name: "parking.facilities_parking",
  });
  const toiletsAvailable = useWatch({
    control: formControl,
    name: "toilets.facilities_toilets",
  });
  const questions = useMemo(
    () => [
      // Parking
      [
        createQuestion({
          category: "parking",
          key: "parking.facilities_parking",
          extraKeys: ["parking.extraNotes"],
          question: "Is there parking?",
          render: (props) => (
            <Controller
              control={props.control}
              name={`parking.facilities_parking`}
              render={({
                field: { ref, value, onChange },
                fieldState: { error },
              }) => (
                <YesNoRadio
                  error={error!}
                  id="parking"
                  value={value === undefined ? null : value}
                  onChange={onChange}
                  extraNote={
                    <Controller
                      control={props.control}
                      name={`parking.extraNotes`}
                      render={({
                        field: { ref, value, onChange },
                        fieldState: { error },
                      }) => (
                        <ExtraNoteContainer value={value || ""}>
                          <RichTextEditor
                            isWordCount={true}
                            maxChars={100}
                            label="Notes"
                            charLabel={"Limited to 100 words"}
                            initialValue={value || ""}
                            onChange={onChange}
                          />
                          {errors["parking"]?.["extraNotes"] && (
                            <ErrorMessage>
                              {errors["parking"]?.["extraNotes"].message}
                            </ErrorMessage>
                          )}
                        </ExtraNoteContainer>
                      )}
                    />
                  }
                />
              )}
            />
          ),
        }),
        createQuestion({
          category: "parking",
          key: "parking.fees.value",
          extraKeys: ["parking.fees.charge", "parking.fees.note"],
          condition: isThereParking === true,
          question:
            "Are there parking fees? If so, how much does it cost for a standard car?",
          render: (props) => {
            return (
              <Controller
                control={props.control}
                name={`parking.fees.value`}
                render={({
                  field: { ref, value, onChange },
                  fieldState: { error },
                }) => (
                  <YesNoRadio
                    error={error!}
                    id="parking.fees.value"
                    value={value === undefined ? null : value}
                    onChange={onChange}
                    afterYes={
                      <div className="relative inline-block flex-col">
                        <div className="flex flex-col">
                          <ParkingFeeRepeater control={props.control} />
                        </div>
                        <PointingArrowHintSm className="static -right-[325px] top-3">
                          Need more variations? Click the + to add another row
                        </PointingArrowHintSm>
                      </div>
                    }
                    extraNote={
                      <Controller
                      control={props.control}
                      name={`parking.fees.note`}
                      render={({
                        field: { ref, value, onChange },
                        fieldState: { error },
                      }) => (
                        <ExtraNoteContainer value={value || ""}>
                          <RichTextEditor
                            isWordCount={true}
                            maxChars={100}
                            label="Notes"
                            charLabel={"Limited to 100 words"}
                            initialValue={value || ""}
                            onChange={onChange}
                          />
                          {errors["parking"]?.["fees"]?.['note'] && (
                            <ErrorMessage>
                              {errors["parking"]?.["fees"]?.['note'].message}
                            </ErrorMessage>
                          )}
                        </ExtraNoteContainer>
                      )}
                    />
                    }
                  />
                )}
              />
            );
          },
        }),
        createQuestion({
          category: "parking",
          key: "parking.location",
          extraKeys: ["parking.locationNote"],
          question: "How far is the parking from the attraction?",
          condition: isThereParking === true,
          render: (props) => (
            <Controller
              control={props.control}
              name={`parking.location`}
              render={({
                field: { ref, value, onChange },
                fieldState: { error },
              }) => (
                <>
                  <MultiRadio<AttractionFaqsForm["parking"]["location"]>
                    error={error!}
                    id="parking.location"
                    value={value}
                    options={[
                      {
                        value: "onsite",
                        label: "On-site parking available",
                      },
                      {
                        value: "5min",
                        label: "Less than 5 minutes walking distance",
                      },
                      {
                        value: "10min",
                        label: "Over 10 minutes walking distance",
                      },

                      {
                        value: "over10min",
                        label: "Less than 10 minutes walking distance",
                      },
                    ]}
                    onChange={onChange}
                  />
                   <Controller
                      control={props.control}
                      name={`parking.locationNote`}
                      render={({
                        field: { ref, value, onChange },
                        fieldState: { error },
                      }) => (
                        <ExtraNoteContainer value={value || ""}>
                          <RichTextEditor
                            isWordCount={true}
                            maxChars={100}
                            label="Notes"
                            charLabel={"Limited to 100 words"}
                            initialValue={value || ""}
                            onChange={onChange}
                          />
                          {errors["parking"]?.["locationNote"] && (
                            <ErrorMessage>
                              {errors["parking"]?.["locationNote"].message}
                            </ErrorMessage>
                          )}
                        </ExtraNoteContainer>
                      )}
                    />
                </>
              )}
            />
          ),
        }),
      ],
      [
        createQuestion({
          category: "parking",
          key: "parking.prebooked.value",
          extraKeys: ["parking.prebooked.note"],
          question: "Can parking spaces be pre-booked or reserved?",
          condition: isThereParking === true,
          render: (props) => {
            return (
              <Controller
                control={props.control}
                name={`parking.prebooked.value`}
                render={({
                  field: { ref, value, onChange },
                  fieldState: { error },
                }) => (
                  <YesNoRadio
                    error={error!}
                    id="parking.prebooked.value"
                    value={value === undefined ? null : value}
                    onChange={onChange}
                    afterYes={
                      <div className="relative w-full md:w-3/4 xl:w-1/2 ">
                        <textarea
                          {...props.control.register("parking.prebooked.note")}
                          placeholder="Please include information such as: What is the process for pre-booking parking spaces, and can it be done online or over the phone? How far in advance should parking spaces be reserved to ensure availability, especially during peak visiting times?"
                          className="h-40 w-full py-2.5 px-5 mt-3 rounded-lg text-cs-gray text-md border-[#CFDBD5] border-cs-1 outline-cs-pink placeholder:text-sm placeholder:text-[#CFDBD5] placeholder:font-normal placeholder:leading-[21px]"
                        ></textarea>
                        <CharacterCount
                          control={props.control}
                          className="w-full"
                          name="parking.prebooked.note"
                          max={100}
                        />
                        <PointingArrowHintSm
                          className="top-0 -right-[275px]"
                          boxClassName="w-[208px]"
                        >
                          <div>
                            <div>
                              Adding more detailed information boosts your
                              listings ranking!
                            </div>

                            <div className="mt-2.5">
                              We recommend stating:
                              <ul className="list-disc pl-4">
                                <li>How can spaces be pre-booked?</li>
                                <li>
                                  Are there any costs involved? If so, how much?
                                </li>
                              </ul>
                            </div>
                          </div>
                        </PointingArrowHintSm>
                      </div>
                    }
                  />
                )}
              />
            );
          },
        }),
        createQuestion({
          category: "parking",
          key: "parking.accessible.value",
          extraKeys: ["parking.accessible.note"],
          question:
            "Are there accessible spaces for visitors with disabilities?",
          condition: isThereParking === true,
          render: (props) => {
            return (
              <Controller
                control={props.control}
                name={`parking.accessible.value`}
                render={({
                  field: { ref, value, onChange },
                  fieldState: { error },
                }) => (
                  <YesNoRadio
                    error={error!}
                    id="parking.accessible.value"
                    value={value === undefined ? null : value}
                    onChange={onChange}
                    afterYes={
                      <div className="relative w-full md:w-3/4 xl:w-[53%] ">
                        <textarea
                          {...props.control.register("parking.accessible.note")}
                          placeholder="Please include information such as: How many accessible parking spaces are available, and are they located near the main entrance or attractions?"
                          className={`${textareaClassName} ${
                            errors["parking"]?.["accessible"]?.["note"]
                              ? "border-cs-red"
                              : ""
                          }`}
                        ></textarea>

                        {errors["parking"]?.["accessible"]?.["note"] && (
                          <ErrorMessage>
                            {
                              errors["parking"]?.["accessible"]?.["note"]
                                .message
                            }
                          </ErrorMessage>
                        )}
                        <CharacterCount
                          control={props.control}
                          className="w-full"
                          name={"parking.accessible.note"}
                          max={100}
                        />

                        <PointingArrowHintSm
                          className="top-5 -right-[310px]"
                          boxClassName="w-[245px]"
                        >
                          <div>
                            <div>
                              Recommended content:
                              <ul className="list-disc pl-4">
                                <li>
                                  Is there a specific location for this parking?
                                </li>
                                <li>
                                  Are accessible spaces easy for visitors to
                                  find?
                                </li>
                              </ul>
                            </div>
                          </div>
                        </PointingArrowHintSm>
                      </div>
                    }
                  />
                )}
              />
            );
          },
        }),
      ],
      // Toilets
      [
        createQuestion({
          category: "toilets",
          key: "toilets.facilities_toilets",
          extraKeys: ["toilets.extraNotes"],
          question: "Are there toilets available to the public?",
          render: (props) => (
            <Controller
              control={props.control}
              name={`toilets.facilities_toilets`}
              render={({
                field: { ref, value, onChange },
                fieldState: { error },
              }) => (
                <YesNoRadio
                  error={error!}
                  id="toilets.facilities_toilets"
                  value={value === undefined ? null : value}
                  onChange={onChange}
                  extraNote={
                    <Controller
                      control={props.control}
                      name={`toilets.extraNotes`}
                      render={({
                        field: { ref, value, onChange },
                        fieldState: { error },
                      }) => (
                        <ExtraNoteContainer value={value || ""}>
                          <RichTextEditor
                            isWordCount={true}
                            maxChars={100}
                            label="Notes"
                            charLabel={"Limited to 100 words"}
                            initialValue={value || ""}
                            onChange={onChange}
                          />

                          {errors["toilets"]?.["extraNotes"] && (
                            <ErrorMessage>
                              {errors["toilets"]?.["extraNotes"].message}
                            </ErrorMessage>
                          )}
                        </ExtraNoteContainer>
                      )}
                    />
                  }
                />
              )}
            />
          ),
        }),
        createQuestion({
          category: "toilets",
          key: "toilets.facilities_baby_changing",
          extraKeys: ["toilets.facilities_baby_changing_note"],
          question: "Are there baby changing facilities available?",
          condition: toiletsAvailable === true,
          render: (props) => (
            <Controller
              control={props.control}
              name={`toilets.facilities_baby_changing`}
              render={({
                field: { ref, value, onChange },
                fieldState: { error },
              }) => (
                <YesNoRadio
                  error={error!}
                  id="toilets.facilities_baby_changing"
                  value={value === undefined ? null : value}
                  onChange={onChange}
                  extraNote={
                    <Controller
                    control={props.control}
                    name={`toilets.facilities_baby_changing_note`}
                    render={({
                      field: { ref, value, onChange },
                      fieldState: { error },
                    }) => (
                      <ExtraNoteContainer value={value || ""}>
                        <RichTextEditor
                          isWordCount={true}
                          maxChars={100}
                          label="Notes"
                          charLabel={"Limited to 100 words"}
                          initialValue={value || ""}
                          onChange={onChange}
                        />
                        {errors["toilets"]?.["facilities_baby_changing_note"] && (
                          <ErrorMessage>
                            {errors["toilets"]?.["facilities_baby_changing_note"].message}
                          </ErrorMessage>
                        )}
                      </ExtraNoteContainer>
                    )}
                  />
                  }
                />
              )}
            />
          ),
        }),
        createQuestion({
          category: "toilets",
          key: "toilets.facilities_accessible_toilets",
          extraKeys: ["toilets.accessibleNote"],
          question: "Are there accessible toilets?",
          condition: toiletsAvailable === true,
          render: (props) => {
            return (
              <Controller
                control={props.control}
                name={`toilets.facilities_accessible_toilets`}
                render={({
                  field: { ref, value, onChange },
                  fieldState: { error },
                }) => (
                  <YesNoRadio
                    error={error!}
                    id="toilets.facilities_accessible_toilets"
                    value={value === undefined ? null : value}
                    onChange={onChange}
                    afterYes={
                      <div className="relative w-full md:w-3/4 xl:w-1/2">
                        <textarea
                          {...props.control.register("toilets.accessibleNote")}
                          placeholder="Please include information such as: Are the accessible toilets clearly marked and easily accessible from main attractions or common areas?"
                          className={`${textareaClassName} ${
                            errors["toilets"]?.["accessibleNote"]
                              ? "border-cs-red"
                              : ""
                          }`}
                        ></textarea>

                        {errors["toilets"]?.["accessibleNote"] && (
                          <ErrorMessage>
                            {errors["toilets"]?.["accessibleNote"]?.message}
                          </ErrorMessage>
                        )}
                        <CharacterCount
                          control={props.control}
                          className="w-full"
                          name="toilets.accessibleNote"
                          max={100}
                        />
                        <PointingArrowHintSm
                          className="top-5 -right-[310px]"
                          boxClassName="w-[245px]"
                        >
                          <div>
                            <div>
                              Recommended content:
                              <ul className="list-disc pl-4">
                                <li>
                                  Will visitors require a key to access this
                                  toilet? If so, how do they access it?
                                </li>
                              </ul>
                            </div>
                          </div>
                        </PointingArrowHintSm>
                      </div>
                    }
                  />
                )}
              />
            );
          },
        }),
      ],
      // Family Friendly
      [
        createQuestion({
          category: "familyFriendly",
          key: "babyFriendly.facilities_baby_changing",
          extraKeys: ["babyFriendly.extraNotes"],
          question: `Is the attraction baby friendly?`,
          render: (props) => (
            <Controller
              control={props.control}
              name={`babyFriendly.facilities_baby_changing`}
              render={({
                field: { ref, value, onChange },
                fieldState: { error },
              }) => (
                <YesNoRadio
                  error={error!}
                  id="babyFriendly.facilities_baby_changing"
                  value={value === undefined ? null : value}
                  onChange={onChange}
                  extraNote={
                    <Controller
                      control={props.control}
                      name={`babyFriendly.extraNotes`}
                      render={({
                        field: { ref, value, onChange },
                        fieldState: { error },
                      }) => (
                        <ExtraNoteContainer value={value || ""}>
                          <RichTextEditor
                            isWordCount={true}
                            maxChars={100}
                            label="Notes"
                            charLabel={"Limited to 100 words"}
                            initialValue={value || ""}
                            onChange={onChange}
                          />

                          {errors["babyFriendly"]?.["extraNotes"] && (
                            <ErrorMessage>
                              {errors["babyFriendly"]?.["extraNotes"].message}
                            </ErrorMessage>
                          )}
                        </ExtraNoteContainer>
                      )}
                    />
                  }
                />
              )}
            />
          ),
        }),
        // createQuestion({
        //   category: "familyFriendly",
        //   key: "family.prams.allowed",
        //   question: "Are prams allowed at the attraction?",
        //   render: (props) => {
        //     return (
        //       <Controller
        //         control={props.control}
        //         name={`family.prams.allowed`}
        //         render={({
        //           field: { ref, value, onChange },
        //           fieldState: { error },
        //         }) => (
        //           <YesNoRadio
        //             error={error!}
        //             id="family.prams.allowed"
        //             value={value === undefined ? null : value}
        //             onChange={onChange}
        //           />
        //         )}
        //       />
        //     );
        //   },
        // }),
        // createQuestion({
        //   category: "familyFriendly",
        //   key: "family.prams.forHire.hire",
        //   extraKeys: ["family.prams.forHire.price"],
        //   question: `Are there prams available for hire?`,
        //   render: (props) => (
        //     <Controller
        //       control={props.control}
        //       name={`family.prams.forHire.hire`}
        //       render={({
        //         field: { ref, value, onChange },
        //         fieldState: { error },
        //       }) => (
        //         <YesNoRadio
        //           error={error!}
        //           id="family.prams.forHire.hire"
        //           value={value === undefined ? null : value}
        //           onChange={onChange}
        //           afterYes={
        //             <div className="flex flex-col">
        //               <PramsPricesRepeater control={props.control} />
        //             </div>
        //           }
        //         />
        //       )}
        //     />
        //   ),
        // }),
        createQuestion({
          category: "familyFriendly",
          key: "pushchair.facilities_pushchair_friendly",
          extraKeys: ["pushchair.extraNotes"],
          question:
            "Are all areas of the attraction accessible with a pushchair?",
          render: (props) => (
            <Controller
              control={props.control}
              name={`pushchair.facilities_pushchair_friendly`}
              render={({
                field: { ref, value, onChange },
                fieldState: { error },
              }) => (
                <YesNoRadio
                  error={error!}
                  id="pushchair.facilities_pushchair_friendly"
                  value={value === undefined ? null : value}
                  onChange={onChange}
                  extraNote={
                    <Controller
                      control={props.control}
                      name={`pushchair.extraNotes`}
                      render={({
                        field: { ref, value, onChange },
                        fieldState: { error },
                      }) => (
                        <ExtraNoteContainer value={value || ""}>
                          <RichTextEditor
                            isWordCount={true}
                            maxChars={100}
                            label="Notes"
                            charLabel={"Limited to 100 words"}
                            initialValue={value || ""}
                            onChange={onChange}
                          />

                          {errors["pushchair"]?.["extraNotes"] && (
                            <ErrorMessage>
                              {errors["pushchair"]?.["extraNotes"].message}
                            </ErrorMessage>
                          )}
                        </ExtraNoteContainer>
                      )}
                    />
                  }
                />
              )}
            />
          ),
        }),
        createQuestion({
          category: "familyFriendly",
          key: "family.breastfeedingRestroom",
          extraKeys: ["family.breastfeedingRestroomNote"],
          question: `Is there a restroom for mothers that are breastfeeding?`,
          render: (props) => (
            <Controller
              control={props.control}
              name={`family.breastfeedingRestroom`}
              render={({
                field: { ref, value, onChange },
                fieldState: { error },
              }) => (
                <YesNoRadio
                  error={error!}
                  id="family.breastfeedingRestroom"
                  value={value === undefined ? null : value}
                  onChange={onChange}
                  extraNote={
                    <Controller
                    control={props.control}
                    name={`family.breastfeedingRestroomNote`}
                    render={({
                      field: { ref, value, onChange },
                      fieldState: { error },
                    }) => (
                      <ExtraNoteContainer value={value || ""}>
                        <RichTextEditor
                          isWordCount={true}
                          maxChars={100}
                          label="Notes"
                          charLabel={"Limited to 100 words"}
                          initialValue={value || ""}
                          onChange={onChange}
                        />
                        {errors["family"]?.["breastfeedingRestroomNote"] && (
                          <ErrorMessage>
                            {errors["family"]?.["breastfeedingRestroomNote"].message}
                          </ErrorMessage>
                        )}
                      </ExtraNoteContainer>
                    )}
                  />
                  }
                />
              )}
            />
          ),
        }),
      ],
      [
        createQuestion({
          overrideTitle: "🐶 Dog Friendly Attractions",
          category: "familyFriendly",
          key: "dog.pet_friendly",
          extraKeys: ["dog.extraNotes"],
          question: `Are visitors allowed to bring dogs with them?`,
          render: (props) => (
            <Controller
              control={props.control}
              name={`dog.pet_friendly`}
              render={({
                field: { ref, value, onChange },
                fieldState: { error },
              }) => (
                <YesNoRadio
                  error={error!}
                  id="dog.pet_friendly"
                  value={value === undefined ? null : value}
                  onChange={onChange}
                  extraNote={
                    <Controller
                      control={props.control}
                      name={`dog.extraNotes`}
                      render={({
                        field: { ref, value, onChange },
                        fieldState: { error },
                      }) => (
                        <ExtraNoteContainer value={value || ""}>
                          <RichTextEditor
                            isWordCount={true}
                            maxChars={100}
                            label="Notes"
                            charLabel={"Limited to 100 words"}
                            initialValue={value || ""}
                            onChange={onChange}
                          />

                          {errors["dog"]?.["extraNotes"] && (
                            <ErrorMessage>
                              {errors["dog"]?.["extraNotes"].message}
                            </ErrorMessage>
                          )}
                        </ExtraNoteContainer>
                      )}
                    />
                  }
                />
              )}
            />
          ),
        }),
        // createQuestion({
        //   category: "familyFriendly",
        //   key: "family.dog.lead",
        //   condition: canTakeDog === true,
        //   question: "Do you require dogs to be on a lead at all times?",
        //   render: (props) => (
        //     <Controller
        //       control={props.control}
        //       name={`family.dog.lead`}
        //       render={({
        //         field: { ref, value, onChange },
        //         fieldState: { error },
        //       }) => (
        //         <YesNoRadio
        //           error={error!}
        //           id="family.dog.lead"
        //           value={value === undefined ? null : value}
        //           onChange={onChange}
        //         />
        //       )}
        //     />
        //   ),
        // }),
        // createQuestion({
        //   category: "familyFriendly",
        //   key: "family.dog.breed",
        //   condition: canTakeDog === true,
        //   question: "Are there any restrictions on the types of dogs allowed?",
        //   render: (props) => (
        //     <Controller
        //       control={props.control}
        //       name={`family.dog.breed`}
        //       render={({
        //         field: { ref, value, onChange },
        //         fieldState: { error },
        //       }) => (
        //         <YesNoRadio
        //           error={error!}
        //           id="family.dog.breed"
        //           value={value === undefined ? null : value}
        //           onChange={onChange}
        //           afterYes={
        //             <div className="relative w-full md:w-3/4 xl:w-1/2">
        //               <textarea
        //                 {...props.control.register("family.dog.breedNote")}
        //                 placeholder="Please include information such as: Is it guide dog only? Do dogs need to be on leads at all times?"
        //                 className={`${textareaClassName.replace(
        //                   "h-40",
        //                   "h-[84px]"
        //                 )}`}
        //               ></textarea>
        //             </div>
        //           }
        //         />
        //       )}
        //     />
        //   ),
        // }),
        // createQuestion({
        //   category: "familyFriendly",
        //   key: "family.dog.water",
        //   condition: canTakeDog === true,
        //   question: `Are water stations available for dogs?`,
        //   render: (props) => (
        //     <Controller
        //       control={props.control}
        //       name={`family.dog.water`}
        //       render={({
        //         field: { ref, value, onChange },
        //         fieldState: { error },
        //       }) => (
        //         <YesNoRadio
        //           error={error!}
        //           id="family.dog.water"
        //           value={value === undefined ? null : value}
        //           onChange={onChange}
        //         />
        //       )}
        //     />
        //   ),
        // }),
      //   createQuestion({
      //     category: "familyFriendly",
      //     key: "family.dog.fee",
      //     extraKeys: ["family.dog.feePrice"],
      //     condition: canTakeDog === true,
      //     question: "Is there a fee for bringing a dog? Is so, how much?",
      //     render: (props) => (
      //       <Controller
      //         control={props.control}
      //         name={`family.dog.fee`}
      //         render={({
      //           field: { ref, value, onChange },
      //           fieldState: { error },
      //         }) => (
      //           <YesNoRadio
      //             error={error!}
      //             id="family.dog.fee"
      //             value={value === undefined ? null : value}
      //             onChange={onChange}
      //             afterYes={
      //               <div className="relative">
      //                 <div className="absolute z-[1] text-base font-normal text-[#212121] left-4 top-[9px]">
      //                   £
      //                 </div>
      //                 <TextInput
      //                   className="my-5 w-[150px]"
      //                   labelClassName="group-focus-within:text-cs-pink"
      //                   inputClassName="placeholder:text-[#CFDBD5] border pl-8 border-[#CFDBD5] w-[150px] text-cs-gray rounded-lg py-3 px-2 flex flex-col w-full focus:outline-cs-pink h-[41px]"
      //                   control={props.control}
      //                   placeholder="Enter price"
      //                   name={"family.dog.feePrice"}
      //                   required
      //                   label=""
      //                   inputProps={{
      //                     ...props.control.register("family.dog.feePrice", {
      //                       valueAsNumber: true,
      //                     }),
      //                     type: "number",
      //                   }}
      //                 />
      //               </div>
      //             }
      //           />
      //         )}
      //       />
      //     ),
      //   }),
      ],
      // Food and Drinks
      [
        createQuestion({
          category: "fooddrinks",
          key: "buyFood.facilities_food",
          extraKeys: ["buyFood.extraNotes"],
          question: "Is there food and drink available to buy?",
          render: (props) => (
            <Controller
              control={props.control}
              name={`buyFood.facilities_food`}
              render={({
                field: { ref, value, onChange },
                fieldState: { error },
              }) => (
                <YesNoRadio
                  error={error!}
                  id="buyFood.facilities_food"
                  value={value === undefined ? null : value}
                  onChange={onChange}
                  extraNote={
                    <Controller
                      control={props.control}
                      name={`buyFood.extraNotes`}
                      render={({
                        field: { ref, value, onChange },
                        fieldState: { error },
                      }) => (
                        <ExtraNoteContainer value={value || ""}>
                          <RichTextEditor
                            isWordCount={true}
                            maxChars={100}
                            label="Notes"
                            charLabel={"Limited to 100 words"}
                            initialValue={value || ""}
                            onChange={onChange}
                          />

                          {errors["buyFood"]?.["extraNotes"] && (
                            <ErrorMessage>
                              {errors["buyFood"]?.["extraNotes"].message}
                            </ErrorMessage>
                          )}
                        </ExtraNoteContainer>
                      )}
                    />
                  }
                />
              )}
            />
          ),
        }),
        createQuestion({
          category: "fooddrinks",
          key: "food.averageCost",
          extraKeys: ["food.averageCostNote"],
          condition: canBuyFood === true,
          question:
            "How much does food and drink typically cost for a family of 4?",
          render: (props) => (
            <div className="relative">
              <div className="absolute z-[1] text-base font-normal text-[#212121] left-4 top-[9px]">
                £
              </div>
              <TextInput
                className="my-5 w-[118px]"
                labelClassName="group-focus-within:text-cs-pink"
                inputClassName="placeholder:text-[#CFDBD5] border pl-8 text-sm border-[#CFDBD5] w-[150px] text-cs-gray rounded-lg py-3 px-2 flex flex-col w-full focus:outline-cs-pink h-[41px]"
                control={props.control}
                placeholder="Enter price"
                name={"food.averageCost"}
                label=""
                inputProps={{
                  ...props.control.register("food.averageCost", {
                    valueAsNumber: true,
                  }),
                  type: "number",
                }}
              />

            <Controller
              control={props.control}
              name={`food.averageCostNote`}
              render={({
                field: { ref, value, onChange },
                fieldState: { error },
              }) => (
                <ExtraNoteContainer value={value || ""}>
                  <RichTextEditor
                    isWordCount={true}
                    maxChars={100}
                    label="Notes"
                    charLabel={"Limited to 100 words"}
                    initialValue={value || ""}
                    onChange={onChange}
                  />
                  {errors["food"]?.["averageCostNote"] && (
                    <ErrorMessage>
                      {errors["food"]?.["averageCostNote"].message}
                    </ErrorMessage>
                  )}
                </ExtraNoteContainer>)} 
              />
            </div>
          ),
        }),
        createQuestion({
          category: "fooddrinks",
          key: "foodAndDrink.extraNotes",
          lightbulb: false,
          condition: canBuyFood === true,
          question:
            "What type of food and drink is available? Please include price points.",
          render: (props) => (
            <div className="relative w-full md:w-3/4 xl:w-1/2 max-w-[550px]">
              <Controller
                control={props.control}
                name={`foodAndDrink.extraNotes`}
                render={({
                  field: { ref, value, onChange },
                  fieldState: { error },
                }) => (
                  <>
                    <RichTextEditor
                      label=""
                      charLabel={""}
                      initialValue={value || ""}
                      placeholder="Please include information such as: The most popular food and drink options, vegan options, and options that are dairy-free or gluten-free"
                      onChange={onChange}
                    />
                    <PointingArrowHintSm
                      className="top-5 -right-[310px]"
                      boxClassName="w-[245px]"
                    >
                      <div>
                        Recommended content:
                        <ul className="list-disc pl-4">
                          <li>
                            Are there food options for people with special
                            dietary requirements?
                          </li>
                        </ul>
                      </div>
                    </PointingArrowHintSm>
                    {errors["foodAndDrink"]?.["extraNotes"] && (
                      <ErrorMessage>
                        {errors["foodAndDrink"]?.["extraNotes"].message}
                      </ErrorMessage>
                    )}
                  </>
                )}
              />
            </div>
          ),
        }),
        createQuestion({
          category: "fooddrinks",
          key: "bringFood.facilities_picnics_allowed",
          extraKeys: ["bringFood.extraNotes"],
          question: "Can visitors bring their own food?",
          condition: canBuyFood === true,
          render: (props) => (
            <Controller
              control={props.control}
              name={`bringFood.facilities_picnics_allowed`}
              render={({
                field: { ref, value, onChange },
                fieldState: { error },
              }) => (
                <YesNoRadio
                  error={error!}
                  id="bringFood.facilities_picnics_allowed"
                  value={value === undefined ? null : value}
                  onChange={onChange}
                  extraNote={
                    <Controller
                      control={props.control}
                      name={`bringFood.extraNotes`}
                      render={({
                        field: { ref, value, onChange },
                        fieldState: { error },
                      }) => (
                        <ExtraNoteContainer value={value || ""}>
                          <RichTextEditor
                            isWordCount={true}
                            maxChars={100}
                            label="Notes"
                            charLabel={"Limited to 100 words"}
                            initialValue={value || ""}
                            onChange={onChange}
                          />
                          {errors["bringFood"]?.["extraNotes"] && (
                            <ErrorMessage>
                              {errors["bringFood"]?.["extraNotes"].message}
                            </ErrorMessage>
                          )}
                        </ExtraNoteContainer>
                      )}
                    />
                  }
                />
              )}
            />
          ),
        }),
      //   createQuestion({
      //     category: "fooddrinks",
      //     key: "food.bringInFoodRestrictions.value",
      //     extraKeys: ["food.bringInFoodRestrictions.note"],
      //     lightbulb: false,
      //     question:
      //       "Are there any restrictions on food that can be brought in?",
      //     condition: canBuyFood === true,
      //     render: (props) => (
      //       <Controller
      //         control={props.control}
      //         name={`food.bringInFoodRestrictions.value`}
      //         render={({
      //           field: { ref, value, onChange },
      //           fieldState: { error },
      //         }) => (
      //           <YesNoRadio
      //             error={error!}
      //             id="food.bringInFoodRestrictions.value"
      //             value={value === undefined ? null : value}
      //             onChange={onChange}
      //             afterYes={
      //               <>
      //                 <textarea
      //                   {...props.control.register(
      //                     "food.bringInFoodRestrictions.note"
      //                   )}
      //                   placeholder="Please include information such as: Can visitors bring in hot food? Can they bring in food purchased from nearby areas?"
      //                   className="w-full md:w-3/4 xl:w-1/2 h-40 py-2.5 px-5 mt-3 rounded-lg text-cs-gray text-md border-[#CFDBD5] border-cs-1 outline-cs-pink placeholder:text-sm placeholder:text-[#CFDBD5] placeholder:font-normal placeholder:leading-[21px]"
      //                 ></textarea>
      //               </>
      //             }
      //           />
      //         )}
      //       />
      //     ),
      //   }),
      ],
      // [
      //   createQuestion({
      //     category: "fooddrinks",
      //     key: "food.picnic",
      //     question:
      //       "Are there picnic areas/designated spaces for eating your own food?",
      //     render: (props) => (
      //       <Controller
      //         control={props.control}
      //         name={`food.picnic`}
      //         render={({
      //           field: { ref, value, onChange },
      //           fieldState: { error },
      //         }) => (
      //           <YesNoRadio
      //             error={error!}
      //             id="food.picnic"
      //             value={value === undefined ? null : value}
      //             onChange={onChange}
      //           />
      //         )}
      //       />
      //     ),
      //   }),
      //   createQuestion({
      //     category: "fooddrinks",
      //     key: "food.warmUpPlace",
      //     question: "Is there a place to warm up food?",
      //     render: (props) => (
      //       <Controller
      //         control={props.control}
      //         name={`food.warmUpPlace`}
      //         render={({
      //           field: { ref, value, onChange },
      //           fieldState: { error },
      //         }) => (
      //           <YesNoRadio
      //             error={error!}
      //             id="food.warmUpPlace"
      //             value={value === undefined ? null : value}
      //             onChange={onChange}
      //           />
      //         )}
      //       />
      //     ),
      //   }),
      //   createQuestion({
      //     category: "fooddrinks",
      //     key: "food.kidsHighChairs",
      //     question: "Are there high chairs available for kids?",
      //     render: (props) => (
      //       <Controller
      //         control={props.control}
      //         name={`food.kidsHighChairs`}
      //         render={({
      //           field: { ref, value, onChange },
      //           fieldState: { error },
      //         }) => (
      //           <YesNoRadio
      //             error={error!}
      //             id="food.kidsHighChairs"
      //             value={value === undefined ? null : value}
      //             onChange={onChange}
      //           />
      //         )}
      //       />
      //     ),
      //   }),
      // ],
      // A11Y
      [
        createQuestion({
          category: "a11y",
          key: "a11y.allAreas",
          extraKeys: ["wheelchair.extraNotes"],
          question: "Are all areas of the attraction accessible by wheelchair?",
          render: (props) => (
            <Controller
              control={props.control}
              name={`a11y.allAreas`}
              render={({
                field: { ref, value, onChange },
                fieldState: { error },
              }) => (
                <YesNoRadio
                  error={error!}
                  id="a11y.allAreas"
                  value={value === undefined ? null : value}
                  onChange={onChange}
                  extraNote={
                    <Controller
                      control={props.control}
                      name={`wheelchair.extraNotes`}
                      render={({
                        field: { ref, value, onChange },
                        fieldState: { error },
                      }) => (
                        <ExtraNoteContainer value={value || ""}>
                          <RichTextEditor
                            isWordCount={true}
                            maxChars={100}
                            label="Notes"
                            charLabel={"Limited to 100 words"}
                            initialValue={value || ""}
                            onChange={onChange}
                          />
                          {errors["wheelchair"]?.["extraNotes"] && (
                            <ErrorMessage>
                              {errors["wheelchair"]?.["extraNotes"].message}
                            </ErrorMessage>
                          )}
                        </ExtraNoteContainer>
                      )}
                    />
                  }
                />
              )}
            />
          ),
        }),
        // createQuestion({
        //   category: "a11y",
        //   key: "a11y.rampLifts",
        //   question: "Do you have ramps and lifts where needed?",
        //   render: (props) => (
        //     <Controller
        //       control={props.control}
        //       name={`a11y.rampLifts`}
        //       render={({
        //         field: { ref, value, onChange },
        //         fieldState: { error },
        //       }) => (
        //         <YesNoRadio
        //           error={error!}
        //           id="a11y.rampLifts"
        //           value={value === undefined ? null : value}
        //           onChange={onChange}
        //         />
        //       )}
        //     />
        //   ),
        // }),
        createQuestion({
          category: "a11y",
          key: "a11y.wheelchairRent.value",
          extraKeys: ["a11y.wheelchairRent.price", "a11y.wheelchairRent.note"],
          question: "Can wheelchairs be rented? If so, what is the cost?",
          render: (props) => (
            <Controller
              control={props.control}
              name={`a11y.wheelchairRent.value`}
              render={({
                field: { ref, value, onChange },
                fieldState: { error },
              }) => (
                <YesNoRadio
                  error={error!}
                  id="a11y.wheelchairRent.value"
                  value={value === undefined ? null : value}
                  onChange={onChange}
                  afterYes={
                    <div className="relative">
                      <div className="absolute z-[1] text-base font-normal text-[#212121] left-4 top-[9px]">
                        £
                      </div>
                      <TextInput
                        className="my-5 w-[118px]"
                        labelClassName="group-focus-within:text-cs-pink"
                        inputClassName="placeholder:text-[#CFDBD5] border pl-8 text-sm border-[#CFDBD5] w-[150px] text-cs-gray rounded-lg py-3 px-2 flex flex-col w-full focus:outline-cs-pink h-[41px]"
                        control={props.control}
                        placeholder="Enter price"
                        name={"a11y.wheelchairRent.price"}
                        required
                        label=""
                        inputProps={{
                          ...props.control.register(
                            "a11y.wheelchairRent.price",
                            {
                              valueAsNumber: true,
                            }
                          ),
                          type: "number",
                        }}
                      />
                    </div>
                  }
                  extraNote={
                    <Controller
                    control={props.control}
                    name={`a11y.wheelchairRent.note`}
                    render={({
                      field: { ref, value, onChange },
                      fieldState: { error },
                    }) => (
                      <ExtraNoteContainer value={value || ""}>
                        <RichTextEditor
                          isWordCount={true}
                          maxChars={100}
                          label="Notes"
                          charLabel={"Limited to 100 words"}
                          initialValue={value || ""}
                          onChange={onChange}
                        />
                        {errors["a11y"]?.["wheelchairRent"] && (
                          <ErrorMessage>
                            {errors["a11y"]?.["wheelchairRent"].message}
                          </ErrorMessage>
                        )}
                      </ExtraNoteContainer>
                    )}
                  />
                  }
                />
              )}
            />
          ),
        }),
        createQuestion({
          category: "a11y",
          key: "a11y.wheelchairAssist",
          extraKeys: ["a11y.wheelchairAssistNote"],
          question:
            "Do you offer special assistance for visitors that use a wheelchair?",
          render: (props) => (
            <Controller
              control={props.control}
              name={`a11y.wheelchairAssist`}
              render={({
                field: { ref, value, onChange },
                fieldState: { error },
              }) => (
                <YesNoRadio
                  error={error!}
                  id="a11y.wheelchairAssist"
                  value={value === undefined ? null : value}
                  onChange={onChange}
                  extraNote={
                    <Controller
                    control={props.control}
                    name={`a11y.wheelchairAssistNote`}
                    render={({
                      field: { ref, value, onChange },
                      fieldState: { error },
                    }) => (
                      <ExtraNoteContainer value={value || ""}>
                        <RichTextEditor
                          isWordCount={true}
                          maxChars={100}
                          label="Notes"
                          charLabel={"Limited to 100 words"}
                          initialValue={value || ""}
                          onChange={onChange}
                        />
                        {errors["a11y"]?.["wheelchairAssistNote"] && (
                          <ErrorMessage>
                            {errors["a11y"]?.["wheelchairAssistNote"].message}
                          </ErrorMessage>
                        )}
                      </ExtraNoteContainer>
                    )}
                  />
                  }
                />
              )}
            />
          ),
        }),
      ],
      // Booking
      [
        createQuestion({
          category: "booking",
          key: "tickets.prebook_tickets",
          extraKeys: ["tickets.extraNotes"],
          question: (
            <>
              Do visitors need to pre-book timeslots or tickets to get into the
              attraction?
              <br />
              Please provide information on how visitors book their tickets if
              applicable.
            </>
          ),
          render: (props) => (
            <Controller
              control={props.control}
              name={`tickets.prebook_tickets`}
              render={({
                field: { ref, value, onChange },
                fieldState: { error },
              }) => (
                <YesNoRadio
                  error={error!}
                  id="tickets.prebook_tickets"
                  value={value === undefined ? null : value}
                  onChange={onChange}
                  afterYes={
                    <>
                      <textarea
                        {...props.control.register("tickets.extraNotes")}
                        placeholder="Please include information such as: How far in advance should visitors book their time slots or tickets to ensure availability, especially during peak seasons? How can users book their time slots and tickets?"
                        className="w-full md:w-3/4 xl:w-1/2 h-40 py-2.5 px-5 mt-3 rounded-lg text-cs-gray text-md border-[#CFDBD5] border-cs-1 outline-cs-pink placeholder:text-sm placeholder:text-[#CFDBD5] placeholder:font-normal placeholder:leading-[21px]"
                      ></textarea>
                    </>
                  }
                />
              )}
            />
          ),
        }),
        createQuestion({
          category: "booking",
          key: "tickets.sell_tickets",
          question:
            "Would you be interested in selling tickets via your DOWTK page?",
          description:
            "Make converting new audiences into ticket sales even easier, with our Ticket Manager platform.",
          render: (props) => (
            <Controller
              control={props.control}
              name={`tickets.sell_tickets`}
              render={({
                field: { ref, value, onChange },
                fieldState: { error },
              }) => (
                <YesNoRadio
                  error={error!}
                  id="tickets.sell_tickets"
                  value={value === undefined ? null : value}
                  onChange={onChange}
                  afterYes={
                    <div className="text-sm text-[#5F646D] font-normal my-2.5 pl-2.5">
                      A member of the team will be in touch!
                    </div>
                  }
                />
              )}
            />
          ),
        }),
      ],
      // Private hire
      [
        createQuestion({
          category: "privateHire",
          key: "privateHire.private_hire",
          extraKeys: ["privateHire.extraNotes"],
          question:
            "Does your attraction offer private hire, for parties or events?",
          render: (props) => (
            <Controller
              control={props.control}
              name={`privateHire.private_hire`}
              render={({
                field: { ref, value, onChange },
                fieldState: { error },
              }) => (
                <YesNoRadio
                  error={error!}
                  id="privateHire.private_hire"
                  value={value === undefined ? null : value}
                  onChange={onChange}
                  extraNote={
                    <Controller
                      control={props.control}
                      name={`privateHire.extraNotes`}
                      render={({
                        field: { ref, value, onChange },
                        fieldState: { error },
                      }) => (
                        <ExtraNoteContainer value={value || ""}>
                          <RichTextEditor
                            isWordCount={true}
                            maxChars={100}
                            label="Notes"
                            charLabel={"Limited to 100 words"}
                            initialValue={value || ""}
                            onChange={onChange}
                          />
                          {errors["privateHire"]?.["extraNotes"] && (
                            <ErrorMessage>
                              {errors["privateHire"]?.["extraNotes"].message}
                            </ErrorMessage>
                          )}
                        </ExtraNoteContainer>
                      )}
                    />
                  }
                />
              )}
            />
          ),
        }),
        createQuestion({
          category: "privateHire",
          key: "privateHire.packages",
          condition: privateHireValue === true,
          question: "What type of packages do you offer?",
          render: (props) => (
            <div>
              <textarea
                {...props.control.register("privateHire.packages")}
                placeholder="Please include information such as: Are there specific areas or facilities within your attraction that can be reserved for private hire? What services are included in the private hire packages for parties or events?"
                className="w-full md:w-3/4 xl:w-1/2 h-40 py-2.5 px-5 mt-3 rounded-lg text-cs-gray text-md border-[#CFDBD5] border-cs-1 outline-cs-pink placeholder:text-sm placeholder:text-[#CFDBD5] placeholder:font-normal placeholder:leading-[21px]"
              ></textarea>
              {errors["privateHire"]?.["packages"] && (
                <ErrorMessage>
                  {errors["privateHire"]["packages"].message}
                </ErrorMessage>
              )}
            </div>
          ),
        }),
        createQuestion({
          category: "privateHire",
          key: "privateHire.privateRoom.value",
          condition: privateHireValue === true,
          extraKeys: ["privateHire.privateRoom.note"],
          question: "Can you rent a private room? If so, what are the costs?",
          render: (props) => (
            <Controller
              control={props.control}
              name={`privateHire.privateRoom.value`}
              render={({
                field: { ref, value, onChange },
                fieldState: { error },
              }) => (
                <YesNoRadio
                  error={error!}
                  id="privateHire.privateRoom.value"
                  value={value === undefined ? null : value}
                  onChange={onChange}
                  afterYes={
                    <>
                      <textarea
                        {...props.control.register(
                          "privateHire.privateRoom.note"
                        )}
                        placeholder="Please include information such as: Are there different sizes or types of private rooms available for rent? Can visitors decorate the room for private parties?"
                        className="w-full md:w-3/4 xl:w-1/2 h-40 py-2.5 px-5 mt-3 rounded-lg text-cs-gray text-md border-[#CFDBD5] border-cs-1 outline-cs-pink placeholder:text-sm placeholder:text-[#CFDBD5] placeholder:font-normal placeholder:leading-[21px]"
                      ></textarea>
                    </>
                  }
                />
              )}
            />
          ),
        }),
        createQuestion({
          category: "privateHire",
          key: "privateHire.catering",
          extraKeys: ["privateHire.cateringNote"],
          condition: privateHireValue === true,
          question: "Is catering provided?",
          render: (props) => (
            <Controller
              control={props.control}
              name={`privateHire.catering`}
              render={({
                field: { ref, value, onChange },
                fieldState: { error },
              }) => (
                <YesNoRadio
                  error={error!}
                  id="privateHire.catering"
                  value={value === undefined ? null : value}
                  onChange={onChange}
                  extraNote={
                    <Controller
                    control={props.control}
                    name={`privateHire.cateringNote`}
                    render={({
                      field: { ref, value, onChange },
                      fieldState: { error },
                    }) => (
                      <ExtraNoteContainer value={value || ""}>
                        <RichTextEditor
                          isWordCount={true}
                          maxChars={100}
                          label="Notes"
                          charLabel={"Limited to 100 words"}
                          initialValue={value || ""}
                          onChange={onChange}
                        />
                        {errors["privateHire"]?.["cateringNote"] && (
                          <ErrorMessage>
                            {errors["privateHire"]?.["cateringNote"].message}
                          </ErrorMessage>
                        )}
                      </ExtraNoteContainer>
                    )}
                  />
                  }
                />
              )}
            />
          ),
        }),
        createQuestion({
          category: "privateHire",
          key: "privateHire.maxGuests",
          extraKeys: ["privateHire.maxGuestsNote"],
          condition: privateHireValue === true,
          question: "What is the maximum number of guests allowed?",
          render: (props) => (
            <>
            <TextInput
              className="my-5 md:w-1/2 w-full"
              labelClassName="group-focus-within:text-cs-pink"
              inputClassName="placeholder:text-[#CFDBD5] border pl-5 border-[#CFDBD5] w-[150px] text-cs-gray rounded-lg py-3 px-2 flex flex-col w-full focus:outline-cs-pink h-[41px]"
              control={props.control}
              placeholder="Example: 50"
              name={"privateHire.maxGuests"}
              label=""
              inputProps={{
                ...props.control.register("privateHire.maxGuests", {
                  valueAsNumber: true,
                }),
                type: "number",
              }}
            />
             <Controller
              control={props.control}
              name={`privateHire.maxGuestsNote`}
              render={({
                field: { ref, value, onChange },
                fieldState: { error },
              }) => (
                <ExtraNoteContainer value={value || ""}>
                  <RichTextEditor
                    isWordCount={true}
                    maxChars={100}
                    label="Notes"
                    charLabel={"Limited to 100 words"}
                    initialValue={value || ""}
                    onChange={onChange}
                  />
                  {errors["privateHire"]?.["maxGuestsNote"] && (
                    <ErrorMessage>
                      {errors["privateHire"]?.["maxGuestsNote"].message}
                    </ErrorMessage>
                  )}
                </ExtraNoteContainer>
              )}
            />
            </>
          ),
        }),
      ],

      // Public Transport
      [
        createQuestion({
          category: "publicTransport",
          key: "publicTransportInformation.extraNotes",
          question:
            "What public transport options are available to reach your attraction?",
          render: (props) => (
            <div className="relative w-full md:w-3/4 xl:w-1/2 max-w-[550px]">
              <Controller
                control={props.control}
                name={`publicTransportInformation.extraNotes`}
                render={({
                  field: { ref, value, onChange },
                  fieldState: { error },
                }) => (
                  <>
                    <RichTextEditor
                      label=""
                      charLabel={""}
                      initialValue={value || ""}
                      placeholder="Please include information such as: Are there specific bus routes or train lines that stop near the attraction? How far is the nearest public transport stop from the attraction, and is it within walking distance?"
                      onChange={onChange}
                    />

                    {errors["publicTransportInformation"]?.["extraNotes"] && (
                      <ErrorMessage>
                        {
                          errors["publicTransportInformation"]?.["extraNotes"]
                            .message
                        }
                      </ErrorMessage>
                    )}
                  </>
                )}
              />
            </div>
          ),
        }),
        createQuestion({
          category: "publicTransport",
          key: "publicTransportInformation.frequency",
          question: "How frequent is public transport to your attraction?",
          render: (props) => (
            <div>
              <textarea
                {...props.control.register(
                  "publicTransportInformation.frequency"
                )}
                placeholder="Please include information such as: Which type of public transport is most frequent? How often do busses and trains stop nearby your attraction?"
                className="w-full md:w-3/4 xl:w-1/2 h-40 py-2.5 px-5 mt-3 rounded-lg text-cs-gray text-md border-[#CFDBD5] border-cs-1 outline-cs-pink placeholder:text-sm placeholder:text-[#CFDBD5] placeholder:font-normal placeholder:leading-[21px]"
              ></textarea>

              {errors["publicTransportInformation"]?.["frequency"] && (
                <ErrorMessage>
                  {errors["publicTransportInformation"]["frequency"].message}
                </ErrorMessage>
              )}
            </div>
          ),
        }),
        createQuestion({
          category: "publicTransport",
          key: "publicTransportInformation.closestStop",
          question:
            "What are the closest public transport stops or stations to your attraction?",
          render: (props) => (
            <>
              <textarea
                {...props.control.register(
                  "publicTransportInformation.closestStop"
                )}
                placeholder="Please include information such as: The name of the closest train station, and the closest bus stop."
                className="w-full md:w-3/4 xl:w-1/2 h-40 py-2.5 px-5 mt-3 rounded-lg text-cs-gray text-md border-[#CFDBD5] border-cs-1 outline-cs-pink placeholder:text-sm placeholder:text-[#CFDBD5] placeholder:font-normal placeholder:leading-[21px]"
              ></textarea>
              {errors["publicTransportInformation"]?.["closestStop"] && (
                <ErrorMessage className="mb-5">
                  {errors["publicTransportInformation"]["closestStop"].message}
                </ErrorMessage>
              )}
            </>
          ),
        }),
      ],
    ],
    [
      result?.name,
      errors,
      privateHireValue,
      canTakeDog,
      canBuyFood,
      isThereParking,
      toiletsAvailable,
    ]
  );
  const questionsFlat = useMemo(() => questions.flat(), [questions]);

  const [updateAttraction, { isLoading, isError, isSuccess: isUpdateSuccess }] =
    apiSlice.useUpdateAttractionDraftMutation();

  const {
    fields: extraFaqFields,
    append: appendExtraFaq,
    remove: removeExtraFaq,
  } = useFieldArray({
    control: formControl,
    name: `extraFaqs`,
  });

  const [isPublishing, setIsPublishing] = useState(false);

  const stepsFields = useMemo<typeof questions>(() => {
    return [[], ...questions, []];
  }, [questions]);

  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const currentStep =
    (stepsFields[currentStepIndex] as any)
      .filter((q: any) => q.condition !== false)
      .map((q: any) => [q.key, ...(q.extraKeys || [])])
      .flat() ?? [];
  const isStepperActive = result
    ? user?.role !== "ADMIN" &&
      !result?.finished_onboarding
    : false;

  const [currentEditQuestion, setCurrentEditQuestion] = useState("");
  const currentQuestion = questionsFlat.find(
    (q) => q.key === currentEditQuestion
  );
  const lastCurrentQuestion =
    questionsFlat.findIndex((q) => q.key === currentQuestion?.key) ===
    questionsFlat.length - 1;
  const firstCurrentQuestion =
    questionsFlat.findIndex((q) => q.key === currentQuestion?.key) === 0;

  function getNextQuestion(index: number): (typeof questionsFlat)[number] {
    const potential = questionsFlat[index + 1];
    if (!potential) return questionsFlat[index];
    if (potential?.condition === false) return getNextQuestion(index + 1);
    return potential;
  }
  function getPrevQuestion(index: number): (typeof questionsFlat)[number] {
    const potential = questionsFlat[index - 1];
    if (!potential) return questionsFlat[index];
    if (potential?.condition === false) return getPrevQuestion(index - 1);
    return potential;
  }

  const getPayload = () => {
    const payload = {
      faqs_json: {
        _completed: getValues("_completed"),
        a11y: getValues("a11y"),

        parking: getValues("parking"),
        toilets: getValues("toilets"),
        babyFriendly: getValues("babyFriendly"),
        bringFood: getValues("bringFood"),
        buyFood: getValues("buyFood"),
        wheelchair: getValues("wheelchair"),
        pushchair: getValues("pushchair"),
        dog: getValues("dog"),
        tickets: getValues("tickets"),
        privateHire: getValues("privateHire"),
        foodAndDrink: getValues("foodAndDrink"),
        publicTransportInformation: getValues("publicTransportInformation"),
        family: getValues("family"),
        food: getValues("food"),
        extraFaqs: getValues("extraFaqs") ?? null,
        additionalInformation: getValues("additionalInformation"),
      } satisfies AttractionFaqsForm,
    };
    return payload;
  };
  const onSubmit = (publish: boolean = false, isSilent: boolean = false) => {
    const fn: SubmitHandler<AttractionFaqsForm> = async (e) => {
      setIsPublishing(publish);
      if (result) {
        const rawPayload = getPayload();
        const payload = rawPayload;

        const results = await updateAttraction({
          id: attractionId,
          publish,
          payload,
        });
        if (!isSilent) {
          restartAnimation();
        }

        if (currentEditQuestion) {
          setCurrentEditQuestion("");
        }

        return "data" in results;
      }

      return false;
    };
    return fn;
  };

  useEffect(() => {
    if (result && isGetSuccess && !isFetching) {
      setShowRevertModal(false);
      const v = result?.faqs_json as VenueFaqJson;

      // Parking
      const defaultFeeCharge = [
        {
          time: "All time",
          fee: 0,
        },
      ];
      setValue("parking", {
        extraNotes: v.parking?.extraNotes || "",
        facilities_parking: getBooleanOrNull(v?.parking?.facilities_parking),
        accessible: {
          value: getBooleanOrNull(v?.parking?.accessible?.value),
          note: v?.parking?.accessible?.note || "",
        },
        facilities_no_parking: getBooleanOrNull(
          v?.parking?.facilities_no_parking
        ),
        fees: {
          charge: Array.isArray(v?.parking?.fees?.charge)
            ? v?.parking?.fees?.charge?.map((c) => ({
                fee: c?.fee || 1,
                time: c?.time || "All time",
              })) || defaultFeeCharge
            : defaultFeeCharge,
          value: getBooleanOrNull(v?.parking?.fees?.value),
          note: v?.parking?.fees?.note || "",
        },
        location: v?.parking?.location || null,
        locationNote: v?.parking?.locationNote || "",
        prebooked: {
          value: getBooleanOrNull(v?.parking?.prebooked?.value),
          note: v?.parking?.prebooked?.note || "",
        },
        facilities_nearby_parking: v?.parking?.facilities_nearby_parking,
        facilities_onsite_parking: v?.parking?.facilities_onsite_parking,
      });

      // Toilets
      setValue("toilets", {
        extraNotes: v?.toilets?.extraNotes || "",
        facilities_toilets: getBooleanOrNull(v?.toilets?.facilities_toilets),
        facilities_accessible_toilets: getBooleanOrNull(
          v?.toilets?.facilities_accessible_toilets
        ),
        facilities_baby_changing: getBooleanOrNull(
          v?.toilets?.facilities_baby_changing
        ),
        facilities_baby_changing_note: v?.toilets?.facilities_baby_changing_note || "",

        easy_to_find: getBooleanOrNull(v?.toilets?.easy_to_find),
        maintained: getBooleanOrNull(v?.toilets?.maintained),
        accessibleNote: v?.toilets?.accessibleNote || "",
      });

      // Family Friendly
      setValue("babyFriendly", {
        facilities_baby_changing: getBooleanOrNull(
          v?.babyFriendly?.facilities_baby_changing
        ),
        extraNotes: v?.babyFriendly?.extraNotes || "",
      });
      setValue("pushchair", {
        facilities_pushchair_friendly: getBooleanOrNull(
          v?.pushchair?.facilities_pushchair_friendly
        ),
        extraNotes: v?.pushchair?.extraNotes || "",
      });
      setValue("dog", {
        pet_friendly: getBooleanOrNull(v?.dog?.pet_friendly),
        extraNotes: v?.dog?.extraNotes || "",
      });
      setValue("family", {
        babyFriendly: getBooleanOrNull(v?.family?.babyFriendly),
        prams: {
          allowed: getBooleanOrNull(v?.family?.prams?.allowed),
          forHire: {
            hire: getBooleanOrNull(v?.family?.prams?.forHire?.hire),
            price: v?.family?.prams?.forHire?.price?.map((p) => ({
              value: p?.value || 1,
              type: p?.type || ''
            })) || [{ value: 1, type: '' }],
          },
        },
        babyPlay: {
          value: getBooleanOrNull(v?.family?.babyPlay?.value),
          note: v?.family?.babyPlay?.note || "",
        },
        pushchair: {
          parking: getBooleanOrNull(v?.family?.pushchair?.parking),
          showArea: v?.family?.pushchair?.showArea || null,
        },
        breastfeedingRestroom: getBooleanOrNull(
          v?.family?.breastfeedingRestroom
        ),
        breastfeedingRestroomNote: v?.family?.breastfeedingRestroomNote || "",

        dog: {
          lead: getBooleanOrNull(v?.family?.dog?.lead),
          breed: getBooleanOrNull(v?.family?.dog?.breed),
          breedNote: v?.family?.dog?.breedNote ?? "",
          water: getBooleanOrNull(v?.family?.dog?.water),
          fee: getBooleanOrNull(v?.family?.dog?.fee),
          feePrice: v?.family?.dog?.feePrice || 1,
        },
      });

      // Food and Drinks
      setValue("bringFood", {
        facilities_picnics_allowed: getBooleanOrNull(
          v.bringFood?.facilities_picnics_allowed
        ),
        extraNotes: v.bringFood?.extraNotes || "",
      });
      setValue("buyFood", {
        facilities_food: getBooleanOrNull(v.buyFood?.facilities_food),
        facilities_onsite_restaurant: getBooleanOrNull(
          v.buyFood?.facilities_onsite_restaurant
        ),
        facilities_snacks_available: getBooleanOrNull(
          v.buyFood?.facilities_snacks_available
        ),
        facilities_no_food_available: getBooleanOrNull(
          v.buyFood?.facilities_no_food_available
        ),
        extraNotes: v.buyFood?.extraNotes || "",
      });
      setValue("foodAndDrink", {
        extraNotes: v.foodAndDrink?.extraNotes || v.food?.pricingPoints?.note || "",
      });
      setValue("food", {
        picnic: getBooleanOrNull(v.food?.picnic),
        warmUpPlace: getBooleanOrNull(v.food?.warmUpPlace),
        healthyOptions: getBooleanOrNull(v.food?.healthyOptions),
        kidsHighChairs: getBooleanOrNull(v.food?.kidsHighChairs),
        averageCost: v.food?.averageCost || null,
        averageCostNote: v.food?.averageCostNote || "",
        bringInFoodRestrictions: {
          value: getBooleanOrNull(v.food?.bringInFoodRestrictions?.value),
          note: v.food?.bringInFoodRestrictions?.note || "",
        },
        pricingPoints: {
          value: getBooleanOrNull(v.food?.pricingPoints?.value),
          note: v.food?.pricingPoints?.note || "",
        },
      });

      // A11y
      setValue("a11y", {
        rampLifts: getBooleanOrNull(v.a11y?.rampLifts),
        allAreas: getBooleanOrNull(v.a11y?.allAreas),
        wheelchairAssist: getBooleanOrNull(v.a11y?.wheelchairAssist),
        wheelchairAssistNote: v.a11y?.wheelchairAssistNote || "",
        wheelchairSeating: v.a11y?.wheelchairSeating || null,
        wheelchairRent: {
          value: getBooleanOrNull(v.a11y?.wheelchairRent?.value),
          price: v.a11y?.wheelchairRent?.price || 1,
          note: v?.a11y?.wheelchairRent?.note || ""
        },
      });
      setValue("wheelchair", {
        extraNotes: v.wheelchair?.extraNotes || "",
        facilities_wheelchair_friendly: getBooleanOrNull(v.wheelchair?.facilities_wheelchair_friendly)
      })

      // Booking
      setValue("tickets", {
        prebook_tickets: getBooleanOrNull(v.tickets?.prebook_tickets),
        sell_tickets: getBooleanOrNull(v?.tickets?.sell_tickets),
        discount: getBooleanOrNull(v?.tickets?.discount),
        extraNotes: v.tickets?.extraNotes || "",
      });

      // Private Hire
      setValue("privateHire", {
        private_hire: getBooleanOrNull(v.privateHire?.private_hire),
        extraNotes: v.privateHire?.extraNotes || "",

        packages: v.privateHire?.packages || "",
        privateRoom: {
          value: getBooleanOrNull(v.privateHire?.privateRoom?.value),
          note: v.privateHire?.privateRoom?.note || "",
        },
        catering: getBooleanOrNull(v.privateHire?.catering),
        cateringNote: v.privateHire?.cateringNote || "",
        maxGuests: v.privateHire?.maxGuests || null,
        maxGuestsNote: v.privateHire?.maxGuestsNote || "",
      });

      // Public transport
      setValue("publicTransportInformation", {
        extraNotes: v.publicTransportInformation?.extraNotes || v.publicTransportInformation?.available?.note || "",

        frequency: v.publicTransportInformation?.frequency || "",
        closestStop: v.publicTransportInformation?.closestStop || "",
        available: {
          none: getBooleanOrNull(v.publicTransportInformation?.available?.none),
          note: v.publicTransportInformation?.available?.note || "",
        },
      });

      // Other
      const extraFAQs = Array.isArray(v.extraFaqs)
        ? v.extraFaqs
            .filter((e) => e && e?.title)
            .map((e) => ({
              title: e?.title ?? "",
              extraNotes: e?.extraNotes ?? "",
              category: e?.category ?? "other",
            }))
        : null;
      setValue("extraFaqs", extraFAQs);

      // Config
      setValue("_completed", !!v._completed);
    }
  }, [result, isFetching]);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Attraction FAQs | Day Out With The Kids";
  }, []);

  const bypassQueue =
    user?.role === "ADMIN" ||
    ["ENHANCED", "PREMIUM"].includes(result?.listing_package ?? "");
  useEffect(() => {
    if (isUpdateSuccess) {
      reset({}, { keepValues: true });

      dispatch(
        globalActions.setToastNotifcation({
          ...toastNotification,
          type: "SUCCESS",
          message: isPublishing
            ? bypassQueue
              ? "Published"
              : "Submitted for approval"
            : "Your changes have been saved",
          attractionApprovalStatus: "",
          attractionImage: "",
          attractionName: "",
          attractionAddress: "",
        })
      );
    }
  }, [isUpdateSuccess]);

  useEffect(() => {
    isError &&
      dispatch(
        globalActions.setToastNotifcation({
          ...toastNotification,
          type: "ERROR",
          message: isPublishing
            ? "Cannot publish attraction"
            : "Cannot save changes",
          attractionApprovalStatus: "",
          attractionImage: "",
          attractionName: "",
          attractionAddress: "",
        })
      );
  }, [isError]);

  const navigate = useNavigate();
  useEffect(() => {
    if (
      attractionError &&
      (attractionError as unknown as { status: number })?.status === 403
    ) {
      navigate("/forbidden", { replace: true });
    }
  }, [attractionError]);

  type Views = "STEPPER" | "EDIT" | "SINGLE" | "EXTRA";
  const [showAddExtraFAQs, setShowAddExtraFAQs] = useState(false);
  const [showExtraFAQsQuestion, setShowExtraFAQsQuestion] = useState("");

  const stepperStep = useState(0);
  const isCompleted = !!result?.faqs_json?._completed;
  const view = useMemo<Views>(() => {
    if (showAddExtraFAQs) return "EXTRA";
    if (currentEditQuestion) return "SINGLE";
    if (
      isStepperActive &&
      (!isCompleted || stepperStep[0] === questions.length + 2 - 1) // Questions length + start step + middle step + last step - 1 for index
    )
      return "STEPPER";
    return "EDIT";
  }, [
    showAddExtraFAQs,
    currentEditQuestion,
    isCompleted,
    questions.length,
    stepperStep,
    isStepperActive,
  ]);

  useEffect(() => {
    const steps = getNewAttractionWhichStepLeftOver();
    if (steps.step && steps.allSteps) {
      if (steps.allSteps === stepsFields.length) {
        stepperStep[1](steps.step);
        setCurrentStepIndex(steps.step);
      }
      clearNewAttractionStepLeftOver();
    }
  }, []);

  useEffect(() => {
    dispatch(
      globalActions.setSaveCurrentTabStep({
        attractionId: attractionId ?? "",
        key: "ATTRACTION_FAQS",
        allSteps: stepsFields.length,
        step: currentStepIndex,
      })
    );
  }, [attractionId, currentStepIndex, stepsFields.length, dispatch]);

  async function onNextPage() {
    unlockNewAttractionTab(attractionId ?? "", "ATTRACTION_IMAGES");
    await new Promise((res) => setTimeout(res, 500));
    navigate(`/attractions/${attractionId}/images`);
  }

  function onBeforeSave(step: number, length: number) {
    if (step === length - 2) {
      // The second to last step
      setValue("_completed", true);
    }
  }

  const { data: getMetrics } =
  apiSlice.useGetVenueOptScoreMetricsQuery();
  const voScoreImprovements = useVoScoreNeedImprovementsFields({
    allMetrics: getMetrics?.result || [],
    completedMetrics: getAttraction?.result?.published_version?.optimisation_scores || [],
    results: result
  })['ATTRACTION_FAQS']


  return (
    <div className="flex flex-col flex-1 bg-[#F5F5FA] doodle-bg">
      <Header
        title="Edit Attraction"
        mobilePublishButtons={true}
        showPublishingButtons
        triggerValidation={undefined}
        saveFn={onSubmit}
        getPayload={getPayload}
        isDirty={isDirty}
      />

      <SingleAttractionTemplate
        result={result}
        attractionId={attractionId}
        isAttraction={true}
        doneWalkthrough={result ? result?.done_walkthrough ?? false : undefined}
        name={""}
        beforeContainer={
          <>
            {view === "SINGLE" && (
              <div className="py-5 xl:mx-[70px]">
                <button
                  type="button"
                  className="text-sm font-normal flex items-center gap-2.5"
                  onClick={() => setCurrentEditQuestion("")}
                >
                  <svg
                    width="8"
                    height="14"
                    viewBox="0 0 8 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.8125 1.79492L1.1875 7.41992L6.8125 13.0449"
                      stroke="#212121"
                      strokeWidth="1.875"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  Return to FAQs
                </button>
              </div>
            )}
          </>
        }
      >
        <form onSubmit={handleSubmit(onSubmit(false))}>
          {view === "SINGLE" && (
            <div>
              <div className="mb-2.5 flex justify-between font-normal text-sm">
                {!firstCurrentQuestion && (
                  <button
                    type="button"
                    className="flex items-center gap-[5px]"
                    onClick={() => {
                      setCurrentEditQuestion((current) => {
                        const index = questionsFlat.findIndex(
                          (q) => q.key === currentQuestion?.key
                        );
                        const prevQuestion = getPrevQuestion(index);
                        return prevQuestion.key;
                      });
                    }}
                  >
                    <svg
                      className=""
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fill="currentColor"
                        fill-rule="evenodd"
                        d="M17 10a.75.75 0 0 1-.75.75H5.612l4.158 3.96a.75.75 0 1 1-1.04 1.08l-5.5-5.25a.75.75 0 0 1 0-1.08l5.5-5.25a.75.75 0 1 1 1.04 1.08L5.612 9.25H16.25A.75.75 0 0 1 17 10"
                        clip-rule="evenodd"
                      />
                    </svg>
                    Previous Question
                  </button>
                )}
                {!lastCurrentQuestion && (
                  <button
                    type="button"
                    className="flex items-center gap-[5px] ml-auto"
                    onClick={() => {
                      setCurrentEditQuestion((current) => {
                        const index = questionsFlat.findIndex(
                          (q) => q.key === currentQuestion?.key
                        );

                        const nextQuestion = getNextQuestion(index);
                        return nextQuestion.key;
                      });
                    }}
                  >
                    Next Question
                    <svg
                      className="rotate-180"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fill="currentColor"
                        fill-rule="evenodd"
                        d="M17 10a.75.75 0 0 1-.75.75H5.612l4.158 3.96a.75.75 0 1 1-1.04 1.08l-5.5-5.25a.75.75 0 0 1 0-1.08l5.5-5.25a.75.75 0 1 1 1.04 1.08L5.612 9.25H16.25A.75.75 0 0 1 17 10"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </button>
                )}
              </div>
              <div key={currentQuestion?.key}>
                <h1 className="mb-3 text-[22px] font-extrabold text-black flex items-center gap-2">
                  {categoriesEmojies[currentQuestion!.category]}{" "}
                  {categoriesOptions.find(
                    (c) => c.value === currentQuestion!.category
                  )?.label ?? ""}
                </h1>
                <div>
                  <Label>
                    {currentQuestion?.question}{" "}
                    {currentQuestion?.lightbulb && (
                      <LightningIcon className="inline-block ml-2" />
                    )}
                  </Label>

                  <div>
                    {currentQuestion?.render({
                      control: formControl,
                    })}
                  </div>
                </div>
              </div>

              <div className="mt-5 border-t border-[#E3E3E3] flex justify-end">
                <div className="flex items-center gap-5 max-w-[350px] mt-[30px]">
                  <PrimaryButton
                    scale="sm"
                    type="button"
                    loading={isLoading}
                    className="px-[25px]"
                    onClick={async () => {
                      const validated = await triggerValidation([
                        currentQuestion!.key,
                        ...(currentQuestion?.extraKeys ?? []),
                      ]);
                      if (!validated) return;
                      // @ts-ignore
                      await onSubmit(false)();
                    }}
                  >
                    Save & Return to FAQs
                  </PrimaryButton>
                </div>
              </div>
            </div>
          )}
          {view === "STEPPER" && (
            <Stepper
              isActive={true}
              isLoading={isLoading}
              saveFn={onSubmit}
              onStepChange={setCurrentStepIndex}
              triggerValidation={async () => {
                return await triggerValidation(currentStep);
              }}
              onNextPage={onNextPage}
              title={""}
              nonStepperSubmit={
                <SaveButtonsEditAttraction isLoading={isLoading} />
              }
              controlledStepping={stepperStep}
              onBeforeSave={onBeforeSave}
              onBeforeFinishLater={() => {
                setNewAttractionLeftOverTab(
                  attractionId ?? "",
                  "ATTRACTION_FAQS",
                  currentStepIndex,
                  stepsFields.length
                );
              }}
              onGoPrevPage={() => {
                navigate(`/attractions/${attractionId}/prices`);
              }}
              customLastPageSubmitText={'Proceed To Image Upload'}
            >
              <Stepper.Step componentType="Step" hideFinishLater={true}>
                <h1 className="font-extrabold text-black text-[22px] flex items-center gap-2">
                  🙋‍♀️ Frequently Asked Questions (FAQs)
                </h1>
                <p className="text-[#5F646D] text-sm font-normal mt-5 md:w-[56%]">
                  We've got a bunch of pre-set FAQs, that are included on all
                  attractions. These are super handy for answering any questions
                  that potential visitors might have... plus, answering all
                  questions will give your attraction a boost in our website
                  rankings!
                </p>
              </Stepper.Step>

              {questions.map((questionGroup) => (
                <Stepper.Step
                  componentType="Step"
                  key={questionGroup.map((q) => q.key).join("-")}
                  skipStep={
                    (questionGroup as any).filter(
                      (q: any) => q.condition === false
                    ).length === questionGroup.length
                  }
                >
                  <h1 className="mb-5 text-[22px] font-extrabold text-black flex items-center gap-2">
                    {questionGroup[0]?.overrideTitle ? questionGroup[0].overrideTitle : <>
                      {categoriesEmojies[questionGroup[0].category]}{" "}
                      {categoriesOptions.find(
                        (c) => c.value === questionGroup[0].category
                      )?.label ?? ""}
                    </>
                    }

                  </h1>

                  {
                    // @ts-ignore
                    questionGroup.filter((q) => q.condition !== false).map((question, i) => (
                        <div key={question.key}>
                          <Label>{question?.question}</Label>

                          {question?.description && (
                            <div className="text-sm text-[#5F646D] font-normal mt-5">
                              {question?.description}
                            </div>
                          )}

                          <div>
                            {question?.render({ control: formControl })}
                          </div>

                          {
                            //@ts-ignore
                            questionGroup.filter((q) => q.condition !== false)[
                              i + 1
                            ] && (
                              <div className="h-[2px] bg-[#CFDBD5] my-5 max-w-[550px]"></div>
                            )
                          }
                        </div>
                      ))
                  }
                </Stepper.Step>
              ))}

              <Stepper.Step componentType="Step">
                <fieldset className="inline-flex flex-col flex-wrap w-full mb-4 md:w-3/5">
                  <h2 className="text-[22px] font-extrabold text-black flex items-center gap-2">
                    🎉 Woohoo, FAQ’s complete! 🥳
                  </h2>
                  <p className="text-[#5F646D] font-normal text-sm leading-5 mt-5">
                    Your responses to the pre-set questions have been saved.
                    <br />
                    <br />
                    If you want to increase your rankings on DOWTK, make sure
                    you add some additional FAQ’s that are tailored to your
                    attraction.
                    <br />
                    <br />
                    <span className="font-bold text-[#FF0000]">
                      It’s important to fill out your attraction information
                      ASAP to get your listing live, ranking and seen by our
                      audience.
                    </span>
                  </p>

                  <div className="relative mt-5">
                    <button
                      type="button"
                      className="border border-[#CFDBD5] py-2.5 px-5 rounded-full text-center text-[#212121] text-sm font-normal flex gap-2 items-center"
                      onClick={() => stepperStep[1](0)}
                    >
                      Edit Existing FAQs
                      <svg
                        width="6"
                        height="10"
                        viewBox="0 0 6 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.433593 8.86523L3.87891 5.41992L0.433593 1.97461L1.48828 0.919923L5.98828 5.41992L1.48828 9.91992L0.433593 8.86523Z"
                          fill="#212121"
                        />
                      </svg>
                    </button>

                    <button
                      type="button"
                      className="mt-2.5 border border-[#CFDBD5] py-2.5 px-5 rounded-full text-center text-[#212121] text-sm font-normal flex gap-2 items-center"
                      onClick={() => {
                        stepperStep[1](0);
                        setShowAddExtraFAQs(true);
                      }}
                    >
                      Add New FAQs
                      <svg
                        width="6"
                        height="10"
                        viewBox="0 0 6 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.433593 8.86523L3.87891 5.41992L0.433593 1.97461L1.48828 0.919923L5.98828 5.41992L1.48828 9.91992L0.433593 8.86523Z"
                          fill="#212121"
                        />
                      </svg>
                    </button>
                    <PointingArrowHintSm
                      className="left-[180px] top-[40px]"
                      boxClassName="w-[281px]"
                    >
                      Psst... FAQs tailored to your attraction can boost your
                      rankings!
                    </PointingArrowHintSm>
                  </div>
                </fieldset>
              </Stepper.Step>
            </Stepper>
          )}
          {view === "EDIT" && (
            <div>
              <div className="flex flex-col gap-y-2.5 md:w-1/ xl:w-[550px]">
                <h1 className="text-[#212121] text-[28px] font-extrabold">
                  {result?.name} {!!voScoreImprovements.faqs_json && <LightningIcon tooltip={getVoScoreTooltip(voScoreImprovements.faqs_json)} className="ml-1" />}
                </h1>

                <h2 className="font-extrabold text-[22px]">
                  🙋‍♀️ Frequently Asked Questions (FAQs)
                </h2>

                <p className="text-[#5F646D] text-sm font-normal">
                  The responses to your current FAQs can be found below. Simply
                  select the category and question you wish to edit or add a new
                  question by clicking ‘Add New FAQs’.
                </p>

                <div className="divide-y divide-[#D9D9D9] flex flex-col">
                  {categoriesOptions.map((category, index) => (
                    <QuestionsAccordion
                      category={category.value}
                      label={category.label}
                      index={index}
                      questions={questionsFlat}
                      extraFAQs={extraFaqFields}
                      onSelect={(key) => setCurrentEditQuestion(key)}
                      onSelectExtra={(key) => {
                        setShowAddExtraFAQs(true);
                        setShowExtraFAQsQuestion(key);
                      }}
                    />
                  ))}
                  {extraFaqFields.filter((e) => e.category === "other").length >
                    0 && (
                    <QuestionsAccordion
                      category={"other"}
                      label={"Other"}
                      index={categoriesOptions.length}
                      questions={questionsFlat}
                      extraFAQs={extraFaqFields}
                      onSelect={(key) => setCurrentEditQuestion(key)}
                      onSelectExtra={(key) => {
                        setShowAddExtraFAQs(true);
                        setShowExtraFAQsQuestion(key);
                      }}
                    />
                  )}
                </div>

                <div className="relative">
                  <button
                    type="button"
                    className="ml-auto border border-[#CFDBD5] py-2.5 px-5 rounded-full text-center text-[#212121] text-sm font-normal flex gap-2 items-center"
                    onClick={() => setShowAddExtraFAQs(true)}
                  >
                    Add New FAQs
                    <svg
                      width="6"
                      height="10"
                      viewBox="0 0 6 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.433593 8.86523L3.87891 5.41992L0.433593 1.97461L1.48828 0.919923L5.98828 5.41992L1.48828 9.91992L0.433593 8.86523Z"
                        fill="#212121"
                      />
                    </svg>
                  </button>
                </div>
              </div>

              {isStepperActive && isImageTabLocked(attractionId || "") && <div>
                <div className="h-[1px] bg-[#D9D9D9] w-full my-5"></div>
                  <div className="flex items-center gap-2.5 justify-end w-full">
                    <PrimaryButton
                      scale="sm"
                      type="button"
                      className={'px-4'}
                      loading={isLoading}
                      onClick={onNextPage}
                    >
                      Proceed To Image Upload
                    </PrimaryButton>
                  </div>
                </div>}
            </div>
          )}
          {view === "EXTRA" && (
            <ExtraFAQs
              show={showExtraFAQsQuestion || "new"}
              setShow={(newVal) => setShowExtraFAQsQuestion(newVal)}
              setShowAddExtraFAQs={setShowAddExtraFAQs}
              appendField={appendExtraFaq}
              control={formControl}
              errors={errors}
              fields={extraFaqFields}
              getValues={getValues}
              register={register}
              removeField={removeExtraFaq}
              setValue={setValue}
              saveFn={onSubmit}
              triggerValidation={triggerValidation}
            />
          )}
        </form>
        {isSubmitted && !isValid && Object.keys(errors).length !== 0 && (
          <ErrorMessage>
            You have some errors! Scroll up to view & fix
          </ErrorMessage>
        )}

        <RevertToSavedModal
          getShowRevertModal={getShowRevertModal}
          setShowRevertModal={setShowRevertModal}
          refetch={refetch}
        />
        <UnsavedModal
          isDirty={isDirty && JSON.stringify(dirtyFields) !== "{}"}
        />
      </SingleAttractionTemplate>

      <Footer />
    </div>
  );
}

function QuestionsAccordion({
  category,
  label,
  index,
  questions,
  onSelect,
  extraFAQs,
  onSelectExtra,
}: {
  category: Categories;
  label: string;
  index: number;
  questions: ReturnType<typeof createQuestion>[];
  onSelect: (key: string) => void;
  onSelectExtra: (key: string) => void;
  extraFAQs: (NonNullable<AttractionFaqsForm["extraFaqs"]>[number] & {
    id: string;
  })[];
}) {
  const categoryQuestions = questions
    .filter((q) => q.category === category)
    .filter((q) => q?.condition !== false);
  const [open, setOpen] = useState(false);
  const extra = extraFAQs?.filter((e) => e.category === category);
  return (
    <div className="w-full py-4 text-left">
      <button
        type="button"
        className="px-4 w-full text-lg font-extrabold flex items-center"
        onClick={() => setOpen((v) => !v)}
      >
        <span className="text-[#5F646D] text-sm font-light pl-4 pr-6">
          {index + 1}
        </span>{" "}
        <span>
          {label}{" "}
          {categoryQuestions.filter((q) => q.lightbulb).length > 0 && (
            <LightningIcon className="ml-2 inline-block" />
          )}
        </span>
        <svg
          className={`ml-auto ${open ? "rotate-180" : ""}`}
          width="10"
          height="6"
          viewBox="0 0 10 6"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.55469 0.353516L5 3.79883L8.44531 0.353516L9.5 1.4082L5 5.9082L0.5 1.4082L1.55469 0.353516Z"
            fill="#212121"
          />
        </svg>
      </button>
      {open && (
        <div className="px-14">
          {categoryQuestions.map((q) => (
            <button
              key={q.key}
              onClick={() => onSelect(q.key)}
              type="button"
              className="flex items-center w-full text-left mt-2.5 text-[#5F646D] text-sm font-bold hover:text-[#6836D1] transition-colors"
            >
              <div>
                {q.question}{" "}
                <svg
                  className="inline-block ml-1"
                  width="5"
                  height="7"
                  viewBox="0 0 5 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.646484 5.9082L3.13476 3.41992L0.646483 0.93164L1.4082 0.169921L4.6582 3.41992L1.4082 6.66992L0.646484 5.9082Z"
                    fill="#5F646D"
                  />
                </svg>
                {q.lightbulb && <LightBulb className="inline-block ml-1" />}
              </div>
            </button>
          ))}
          {extra?.map((e) => (
            <button
              key={JSON.stringify(e)}
              onClick={() => onSelectExtra(e.id)}
              type="button"
              className="w-full text-left mt-2.5 text-[#5F646D] text-sm font-bold hover:text-[#6836D1] transition-colors"
            >
              {e.title}{" "}
              <svg
                className="inline-block ml-1"
                width="5"
                height="7"
                viewBox="0 0 5 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.646484 5.9082L3.13476 3.41992L0.646483 0.93164L1.4082 0.169921L4.6582 3.41992L1.4082 6.66992L0.646484 5.9082Z"
                  fill="#5F646D"
                />
              </svg>
            </button>
          ))}
        </div>
      )}
    </div>
  );
}

export function YesNoRadio({
  value,
  id,
  onChange,
  afterYes,
  error,
  extraNote,
}: {
  id: string;
  value: boolean | null;
  onChange: (newVal: boolean) => void;
  afterYes?: ReactNode;
  error: FieldError;
  extraNote?: string | ReactNode
}) {
  function onInput(e: React.ChangeEvent<HTMLInputElement>) {
    onChange(e.target.id.endsWith("yes"));
  }
  return (
    <>
      <fieldset className="my-5">
        <div className="mb-3 radio-item">
          <input
            type="radio"
            id={`${id}_yes`}
            checked={value === true}
            onChange={onInput}
          />
          <label htmlFor={`${id}_yes`}>Yes</label>
        </div>
        {value === true && afterYes}

        <div className="radio-item">
          <input
            type="radio"
            id={`${id}_no`}
            checked={value === false}
            onChange={onInput}
          />
          <label htmlFor={`${id}_no`}>No</label>
        </div>
        {extraNote}
      </fieldset>
      {error && <ErrorMessage>{error.message}</ErrorMessage>}
    </>
  );
}

function ExtraNoteContainer({ value, children }: { value: string, children: ReactNode }) {
  const [extraNoteCheckbox, setExtraNoteCheckbox] = useState(value?.length > 0)
  return (
    <div className="pl-2.5 max-w-[550px]">
    <label className="checkbox-item primary-checkmark mb-3 mt-5 items-center flex h-4 text-sm text-cs-gray font-normal w-28 cursor-pointer pb-0.5">
      Add notes
      <input
        className="!w-28 !h-5"
        type="checkbox"
        checked={extraNoteCheckbox}
        onChange={(val) => {
          setExtraNoteCheckbox(val.target.checked)
        }}
        readOnly
      />
      <span className="checkmark border-cs-bright-lt-gray"></span>
    </label>

    {extraNoteCheckbox && children}
    </div>
  )
}

function MultiRadio<T extends string | null>({
  value,
  id,
  onChange,
  options,
  error,
}: {
  id: string;
  value: T | null;
  options: {
    label: string;
    value: T;
  }[];
  onChange: (newVal: string) => void;
  error: FieldError;
}) {
  return (
    <>
      <fieldset className="my-5">
        {options.map((option) => (
          <div key={option.value} className="mb-3 radio-item">
            <input
              type="radio"
              id={`${id}_${option.value}`}
              checked={value === option.value}
              onChange={() => {
                if (option.value) {
                  onChange(option.value);
                }
              }}
            />
            <label htmlFor={`${id}_${option.value}`}>{option.label}</label>
          </div>
        ))}
      </fieldset>
      {error && <ErrorMessage>{error.message}</ErrorMessage>}
    </>
  );
}

function ExtraFAQs({
  show,
  setShow,
  setShowAddExtraFAQs,
  fields,
  register,
  control,
  removeField,
  appendField,
  getValues,
  setValue,
  errors,
  saveFn,
  triggerValidation,
}: {
  show: string;
  setShowAddExtraFAQs: (val: boolean) => void;
  setShow: (val: string) => void;
  fields: FieldArrayWithId<AttractionFaqsForm, "extraFaqs", "id">[];
  removeField: UseFieldArrayRemove;
  appendField: UseFieldArrayAppend<AttractionFaqsForm, "extraFaqs">;
  register: UseFormRegister<AttractionFaqsForm>;
  control: Control<AttractionFaqsForm, any>;
  getValues: UseFormGetValues<AttractionFaqsForm>;
  setValue: UseFormSetValue<AttractionFaqsForm>;
  errors: FieldErrors<AttractionFaqsForm>;
  saveFn: (publish?: boolean, isSilent?: boolean) => Function;
  triggerValidation: UseFormTrigger<AttractionFaqsForm>;
}) {
  const [newIndex, setNewIndex] = useState(0);
  const showNew = show === "new";
  async function addAnother(insert = false) {
    if (insert) {
      appendField({
        extraNotes: getValues("extraFaqsNew.extraNotes") ?? "",
        title: getValues("extraFaqsNew.title"),
        category: getValues("extraFaqsNew.category"),
      });
      setValue("extraFaqsNew.extraNotes", "");
      setValue("extraFaqsNew.title", "");
      setValue("extraFaqsNew.category", "parking");
    }

    setShow("new");
    setNewIndex((v) => v + 1);
  }

  async function goback() {
    setShowAddExtraFAQs(false);
    await saveFn()();
  }

  const buttons = (doValidation = false) => {
    return (
      <>
        <div className="h-[1px] bg-[#D9D9D9] w-full my-5"></div>

        <div className="flex items-center gap-2.5 justify-end w-full">
          <OutlineButton
            className="h-9 text-sm px-2.5 leading-[16px] w-[145px]"
            scale="none"
            type="button"
            onClick={async () => {
              addAnother(doValidation);
              await saveFn()();
              setShowAddExtraFAQs(false);
            }}
          >
            Save
          </OutlineButton>
          <PrimaryButton
            scale="sm"
            type="button"
            onClick={async () => {
              if (doValidation) {
                const validated = await triggerValidation("extraFaqsNew");

                if (!validated) return;
              }
              addAnother(doValidation);
            }}
          >
            Add another
          </PrimaryButton>
        </div>
      </>
    );
  };
  return (
    <>
      <header className="flex gap-4 items-center mb-5">
        <button type="button" onClick={() => goback()}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 16 16"
          >
            <path
              fill="currentColor"
              fillRule="evenodd"
              d="M14 8a.75.75 0 0 1-.75.75H4.56l3.22 3.22a.75.75 0 1 1-1.06 1.06l-4.5-4.5a.75.75 0 0 1 0-1.06l4.5-4.5a.75.75 0 0 1 1.06 1.06L4.56 7.25h8.69A.75.75 0 0 1 14 8"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>
      </header>

      <div>
        <h2 className="text-[22px] font-extrabold text-black flex items-center gap-2">
          ❓Additional FAQ’s
        </h2>

        {fields.map(
          (field, i) =>
            show === field.id && (
              <div>
                <AddExtraFAQ
                  control={control}
                  errors={errors}
                  getValues={getValues}
                  index={i}
                  register={register}
                  setValue={setValue}
                />
                {buttons(false)}
              </div>
            )
        )}

        {(showNew || !fields.find((f) => f.id === show)) && (
          <div>
            <AddExtraFAQ
              key={newIndex}
              control={control}
              errors={errors}
              getValues={getValues}
              index={"new"}
              register={register}
              setValue={setValue}
            />
            {buttons(true)}
          </div>
        )}
      </div>
    </>
  );
}

function AddExtraFAQ({
  register,
  index,
  control,
  getValues,
  setValue,
  errors,
}: {
  register: UseFormRegister<AttractionFaqsForm>;
  index: number | "new";
  control: Control<AttractionFaqsForm, any>;
  getValues: UseFormGetValues<AttractionFaqsForm>;
  setValue: UseFormSetValue<AttractionFaqsForm>;
  errors: FieldErrors<AttractionFaqsForm>;
}) {
  const extraNodeError =
    typeof index === "number"
      ? errors[`extraFaqs`]?.[index]?.["extraNotes"]
      : errors[`extraFaqsNew`]?.["extraNotes"];
  const fieldName = (
    typeof index === "number" ? `extraFaqs.${index}` : `extraFaqsNew`
  ) as `extraFaqs.${number}`;

  const catOptions = [
    ...categoriesOptions,
    {
      value: "other",
      label: "Other",
    },
  ] as const;
  return (
    <>
      <fieldset className="relative flex flex-col gap-y-2.5 my-3 w-full md:w-3/5">
        <TextInput
          inputProps={{
            ...register(`${fieldName}.title`),
          }}
          className="w-full mt-1 group"
          labelClassName="text-sm font-bold !text-[#5F646D]"
          inputClassName="placeholder:text-[#CFDBD5] border pl-5 border-[#CFDBD5] w-[150px] text-cs-gray rounded-lg py-3 px-2 flex flex-col w-full focus:outline-cs-pink h-[41px]"
          placeholder={"Eg. Are there lockers available for personal items?"}
          control={control}
          name={`${fieldName}.title`}
          label="Frequently Asked Question"
        />
        <Controller
          control={control}
          name={`${fieldName}.extraNotes`}
          render={({
            field: { ref, value, onChange },
            fieldState: { error },
          }) => (
            <>
              <RichTextEditor
                initialValue={getValues(`${fieldName}.extraNotes`) ?? ""}
                onChange={(value: string) =>
                  setValue(`${fieldName}.extraNotes`, value)
                }
                onDirty={() =>
                  setTimeout(() => {
                    setValue(
                      `${fieldName}.extraNotes`,
                      getValues(`${fieldName}.extraNotes`),
                      { shouldDirty: true }
                    );
                  }, 100)
                }
                placeholder="Make sure you give as much detail as possible to increase your rankings."
                label="Answer"
              />
              {extraNodeError?.message && (
                <ErrorMessage>{extraNodeError?.message}</ErrorMessage>
              )}
            </>
          )}
        />
        <div className="relative mb-3">
          <Label>Category</Label>

          <Controller
            control={control}
            name={`${fieldName}.category`}
            render={({
              field: { ref, value, onChange },
              fieldState: { error },
            }) => (
              <Select
                value={
                  value
                    ? catOptions.find((c) => c.value === value)
                    : catOptions[0]
                }
                options={catOptions}
                components={{
                  Option: SingleSelectOption,
                  DropdownIndicator: () => (
                    <div className="px-2.5">
                      <svg
                        width="10"
                        height="6"
                        viewBox="0 0 10 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.55469 0.353516L5 3.79883L8.44531 0.353516L9.5 1.4082L5 5.9082L0.5 1.4082L1.55469 0.353516Z"
                          fill="black"
                        />
                      </svg>
                    </div>
                  ),
                  IndicatorSeparator: () => null,
                }}
                styles={{
                  menu: (styles) => ({
                    ...styles,
                    margin: 0,
                    border: "none",
                  }),
                  menuList: (base, isSelected) => ({
                    ...base,
                    paddingTop: 0,
                    paddingBottom: 0,
                    background: "white",

                    "::-webkit-scrollbar": {
                      width: 14,
                    },
                    "::-webkit-scrollbar-thumb": {
                      background: "#c2c2c2",
                      borderRadius: 10,
                      border: "4px solid white",
                    },
                  }),
                  option: (
                    base,
                    { data, isDisabled, isFocused, isSelected }
                  ) => {
                    return {
                      ...base,
                      background: "white",
                      border: "1px solid #c2c2c2",
                      color: isSelected ? "white" : "#555",
                      "&:hover": {
                        backgroundColor: "#F1F1F1",
                        color: isSelected || isFocused ? "#000" : "#555",
                        border: isFocused ? "1px solid white" : "",
                      },
                      "&:active": {
                        backgroundColor: "#6836D1",
                        color: "#000",
                      },
                    };
                  },
                  singleValue: (base) => ({
                    ...base,
                    color: "#555",
                    fontSize: 14,
                  }),
                  control: (styles, state) => ({
                    ...styles,
                    cursor: "pointer",
                    outline: "none",
                    border: state.isFocused
                      ? "1px solid #CED3CF"
                      : `1px solid ${error ? "red" : "#CED3CF"}`,
                    "&:hover": {
                      border: state.isFocused
                        ? "1px solid #CED3CF"
                        : "1px solid #CED3CF",
                    },
                    boxShadow: "none",
                    paddingTop: 3,
                    paddingBottom: 3,
                    margin: 0,
                    minHeight: 40,
                    height: 40,
                    width: "100%",
                    borderRadius: 8,
                  }),
                  valueContainer: (styles) => ({
                    ...styles,
                    paddingRight: 20,
                    paddingLeft: 20,
                    fontSize: 14,
                  }),
                }}
                onChange={(v) => {
                  if (!v || !v?.value) return;
                  onChange(v?.value);
                }}
              />
            )}
          />

          <PointingArrowHintSm
            className="top-0 -right-[330px]"
            boxClassName="w-[256px]"
          >
            <div>
              If none of the preset categories apply to your question, please
              select ‘Other’.
            </div>
          </PointingArrowHintSm>
        </div>
      </fieldset>
    </>
  );
}

export function Label({
  className = "",
  children,
}: {
  className?: string;
  children: ReactNode;
}) {
  return (
    <label className={`text-sm font-bold text-[#5F646D] ${className}`}>
      {children}
    </label>
  );
}

function CharacterCount({
  control,
  name,
  max,
  className = "",
}: {
  control: Control<AttractionFaqsForm, any>;
  name: FieldPath<AttractionFaqsForm>;
  max: number;
  className?: string;
}) {
  const watchedValue = useWatch({
    control,
    name,
  }) as string;
  return (
    <p
      className={`${
        className ? className : "w-full md:w-3/4 xl:w-1/2"
      } mt-2 text-sm text-right text-cs-gray`}
    >
      {`${(
        watchedValue?.trim()?.split(" ")?.length ?? 0
      ).toString()} / ${max} words`}
    </p>
  );
}

function ParkingFeeRepeater({
  control,
}: {
  control: Control<AttractionFaqsForm, any>;
}) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "parking.fees.charge",
  });
  return (
    <>
      {fields.map((f, i) => (
        <div
          key={JSON.stringify(f) + i.toString()}
          className="relative inline-flex gap-10 mb-5"
        >
          <div className="w-[135px]">
            <TextInput
              className={`group`}
              labelClassName="group-focus-within:text-cs-pink text-sm font-bold !text-[#5F646D]"
              inputClassName="border-cs-1 border-cs-bright-lt-gray text-[#212121] rounded-lg py-3 pr-4 pl-2.5 flex flex-col w-full focus:outline-cs-pink h-10 text-sm font-normal"
              control={control}
              name={`parking.fees.charge.${i}.time`}
              label={"Time"}
              inputProps={{
                ...control.register(`parking.fees.charge.${i}.time`),
                placeholder: "",
                type: "string",
              }}
              hideLabelMargin={true}
            />
          </div>
          <div className="relative w-[135px]">
            <div className="absolute z-[1] text-base font-normal text-[#CFDBD5] left-4 top-[32px]">
              £
            </div>
            <TextInput
              className={`group`}
              labelClassName="group-focus-within:text-cs-pink text-sm font-bold !text-[#5F646D]"
              inputClassName="border-cs-1 border-cs-bright-lt-gray text-[#212121] rounded-lg py-3 pr-4 pl-8 flex flex-col w-full focus:outline-cs-pink h-10 text-sm font-normal"
              control={control}
              name={`parking.fees.charge.${i}.fee`}
              label={"Charge"}
              inputProps={{
                ...control.register(`parking.fees.charge.${i}.fee`, {
                  setValueAs: (v) => {
                    const newVal =
                      v === null || Number.isNaN(parseFloat(v))
                        ? null
                        : Number.isInteger(v)
                        ? v
                        : +parseFloat(v).toFixed(0);

                    return newVal;
                  },
                  min: 0,
                  max: 9000,
                }),
                placeholder: "",
                type: "number",
              }}
              hideLabelMargin={true}
            />
          </div>

          {i === 0 && (
            <button
              type="button"
              className="flex-grow self-center flex items-center justify-start"
              onClick={() =>
                append({
                  fee: 1,
                  time: "All time",
                })
              }
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                className="mr-2 mt-6"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M11 19v-6H5v-2h6V5h2v6h6v2h-6v6h-2Z"
                />
              </svg>
            </button>
          )}

          {i !== 0 && (
            <button
              type="button"
              className="flex-grow self-center flex items-center justify-start"
              onClick={() => remove(i)}
            >
              <svg
                className="mr-2 mt-6"
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                viewBox="0 0 16 16"
              >
                <path
                  fill="currentColor"
                  d="M3.75 7.25a.75.75 0 0 0 0 1.5h8.5a.75.75 0 0 0 0-1.5z"
                ></path>
              </svg>
            </button>
          )}
        </div>
      ))}
    </>
  );
}

function PramsPricesRepeater({
  control,
}: {
  control: Control<AttractionFaqsForm, any>;
}) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "family.prams.forHire.price",
  });
  return (
    <div className="flex flex-col">
      {fields.map((f, i) => (
        <div
          key={JSON.stringify(f) + i.toString()}
          className="relative inline-flex gap-10 mb-5"
        >
          <div className="flex gap-2.5 items-center">
            <TextInput
              className={`group w-[160px]`}
              labelClassName="group-focus-within:text-cs-pink text-sm font-normal !text-[#5F646D] mb-2 block"
              inputClassName="border-cs-1 border-cs-bright-lt-gray text-[#212121] rounded-lg py-3 px-4 flex flex-col w-full focus:outline-cs-pink h-10 text-sm font-normal"
              control={control}
              name={`family.prams.forHire.price.${i}.type`}
              label={"Pram Type"}
              placeholder="Eg. Single"
              inputProps={{
                ...control.register(`family.prams.forHire.price.${i}.type`),
              }}
              hideLabelMargin={true}
            />
            <div className="relative w-[118px]">
              <div className="absolute z-[1] text-base font-normal text-[#212121] left-4 top-[35px]">
                £
              </div>
              <TextInput
                className={`group`}
                labelClassName="group-focus-within:text-cs-pink text-sm font-normal !text-[#5F646D] mb-2 block"
                inputClassName="border-cs-1 border-cs-bright-lt-gray text-[#212121] rounded-lg py-3 pr-4 pl-8 flex flex-col w-full focus:outline-cs-pink h-10 text-sm font-normal"
                control={control}
                name={`family.prams.forHire.price.${i}.value`}
                label={"Price"}
                placeholder="Enter Price"
                inputProps={{
                  ...control.register(`family.prams.forHire.price.${i}.value`, {
                    setValueAs: (v) =>
                      v === null || Number.isNaN(parseFloat(v))
                        ? null
                        : Number.isInteger(v)
                        ? v
                        : +parseFloat(v).toFixed(0),
                    min: 0,
                    max: 9000,
                  }),
                  placeholder: "",
                  type: "number",
                }}
                hideLabelMargin={true}
              />
            </div>
          </div>

          {i !== 0 && (
            <button
              type="button"
              className="flex-grow self-center flex items-center justify-start"
              onClick={() => remove(i)}
            >
              <svg
                className="mr-2 mt-6"
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                viewBox="0 0 16 16"
              >
                <path
                  fill="currentColor"
                  d="M3.75 7.25a.75.75 0 0 0 0 1.5h8.5a.75.75 0 0 0 0-1.5z"
                ></path>
              </svg>
            </button>
          )}
        </div>
      ))}

      <Button
        color="outlineGray"
        className="!w-[145px] !px-6 mt-2.5 whitespace-nowrap my-5"
        scale="md"
        type="button"
        onClick={() =>
          append({
            value: 1,
          })
        }
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          className="mr-2"
          viewBox="0 0 24 24"
        >
          <path fill="currentColor" d="M11 19v-6H5v-2h6V5h2v6h6v2h-6v6h-2Z" />
        </svg>
        Add a price
      </Button>
    </div>
  );
}

export const getBooleanOrNull = (val: boolean | null | undefined) => {
  return typeof val === "boolean" ? val : null;
};
