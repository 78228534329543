import { type ReactNode } from "react";

export function ErrorMessage({ children, className = '' }: { children: ReactNode, className?: string }) {
  return (
    <div className={`flex items-center text-cs-red ${className}`}>
      <span className="w-5 h-5 bg-cs-red rounded-full mr-3 text-white before:relative before:left-2 before:-top-0.5 before:content-['!']"></span>
      <span className="flex-1">
        {children}
      </span>
    </div>
  );
}
