import Header from "../../components/global/amp/Header";
import Footer from "../../components/global/amp/Footer";
import { z } from "zod";
import { useEffect, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm, Controller } from "react-hook-form";
import TextInput from "../../components/form/TextInput";
import {
  useSearchParams,
  useNavigate,
  Link,
  createSearchParams,
} from "react-router-dom";
import Select from "react-select";
import MultiSelectOptions from "../../lib/react-select/MultiSelectOptions";
import ClearIndicator from "../../lib/react-select/ClearIndicator";
import { apiSlice } from "../../store/apiSlice";
import { globalActions } from "../../store/globalSlice";
import { useAppDispatch, useAppSelector } from "../../store";
import {
  restartAnimation,
} from "../../components/global/amp/ToastNotification";
import SingleSelectOption from "../../lib/react-select/SingleSelectOption";
import ModalWrapper from "../../components/global/ModalWrapper";
import { SingleAttractionTemplate } from "../../components/global/SingleAttractionTemplate";
import { PrimaryButton } from "../../components/global/PrimaryButton";
import { ErrorMessage } from "../../components/global/ErrorMessage";
import { Button } from "../../components/global/Button";
import { DropDownIndicator } from "./attractions/AttractionOpeningTimes";
import AsyncSelect from "react-select/async";
import { debounce } from "../../helpers/debounce";

interface EditUserForm {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  options: string;
  status: string;
  attractions: { value: string; label: string }[] | undefined;
  operators: { value: number; label: string } | undefined;
  usertypes: { value: string; label: string } | undefined;
}

interface ForgottenPasswordForm {
  email: string;
}

const EditUser: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { user, toastNotification } = useAppSelector((state) => state.global);
  const [getFetchingNewOperatorId, setFetchingNewOperatorId] = useState<
    number | null
  >();
  const [getCopyDataOperator, setCopyDataOperaor] = useState<{
    value: 0;
    label: "";
  }>();

  const email = searchParams.get("email") ?? "";
  const { data: getUserByEmail, error: userError } = apiSlice.useGetUserByEmailQuery(email);
  const [lazyGetOperators] = apiSlice.useLazyGetOperatorsQuery();
  const { data: getOperators, isFetching: isFetchingOperators } = apiSlice.useGetOperatorsQuery();
  const {
    data: getUserAttractions,
    isFetching,
    isLoading: isLoadingAttractions,
  } = apiSlice.useGetUserAttractionsQuery(
    {
      onlyBasicInformation: true,
      showUnapproved: true,
      operatorId: getFetchingNewOperatorId || getUserByEmail?.operator?.id,
    },
    {
      skip: !getUserByEmail?.operator?.id,
    }
  );

  const [getShowRevertModal, setShowRevertModal] = useState<boolean>(false);
  const [editingAttractions, setEditingAttractions] = useState<boolean>(false)
  const [getSelectedOperatorOptions, setSelectedOperatorOptions] =
    useState<any[]>();

  const dispatch = useAppDispatch();

  const schema = z.object({
    firstName: z.string().min(1, "First name is required"),
    lastName: z.string().min(1, "Last name is required"),
    phoneNumber: z
      .string()
      .min(10, "Phone number is required")
      .refine(
        (val) =>
          new RegExp(
            /^(\+44\s?|\(0\d{4}\)\s?|\(0\d{3}\)\s?|\(0\d{2}\)\s?|\d{4}\s?|\d{3}\s?|\d{2}\s?)\d{3}\s?\d{4}(\s?\#\d{3,4})?$/
          ).test(val),
        "Invalid phone number. It should be at least 10 digits with no spaces"
      ),
  });

  const {
    register,
    handleSubmit,
    formState: { isValid, isSubmitted, errors },
    control,
    getValues,
    setValue,
    resetField,
  } = useForm<EditUserForm>({
    resolver: async (data, context, options) => {
      return zodResolver(schema)(data, context, options);
    },
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      attractions: [],
      operators: { value: 0, label: "" },
    },
  });

  const [
    updateUser,
    { isLoading, isError, isSuccess },
  ] = apiSlice.useUpdateUserMutation();

  const [requestPasswordReset, requestPasswordResetQueryData] =
    apiSlice.usePasswordResetRequestMutation();
  const {
    data,
    isError: isErrorResetPassword,
    isSuccess: isSuccessResetPassword,
    isLoading: isLoadingResetPassword,
  } = requestPasswordResetQueryData;

  const onSubmit: SubmitHandler<EditUserForm> = async (e) => {
    if (!getUserByEmail?.auth0_id) {
      return
    }
    const payload = {
      first_name: getValues("firstName"),
      last_name: getValues("lastName"),
      email: getValues("email"),
      phone: getValues("phoneNumber"),
      operator_id:
        getValues("operators")?.value == 0
          ? null
          : getValues("operators")?.value,
      attraction_ids:
        getValues("attractions")?.length == 0 ||
          getValues("attractions") == undefined
          ? []
          : getValues("attractions")?.map((attraction) =>
            attraction.value.toString()
          ),
      role: getValues("usertypes")?.value,
      access_restricted: getValues("status") == "active" ? false : true,
    }

    await updateUser({
      id: getUserByEmail?.auth0_id,
      payload
    });
    restartAnimation();
  };

  const onSubmitResetPassword: SubmitHandler<ForgottenPasswordForm> = async (
    e
  ) => {
    if (getUserByEmail?.email) {
      await requestPasswordReset(getUserByEmail?.email);
      restartAnimation();
    }
  };
  const userTypes: { value: string; label: string }[] = [
    { value: "OPERATOR_OWNER", label: "Owner" },
    { value: "ATTRACTION_MANAGER", label: "Manager" },
  ];

  const handleChange = (value: any, data: any, meta: any, action: any) => {
    setFetchingNewOperatorId(value.value);
    setCopyDataOperaor({ value: value.value, label: value.label });
    setShowRevertModal(true);
  };

  const fetchNewAttractionsToOperator = () => {
    setValue("operators", getCopyDataOperator);
    setShowRevertModal(false);
    resetField("attractions");
  };

  useEffect(() => {
    document.title = "Edit User | Day Out With The Kids";
  }, []);

  useEffect(() => {
    isSuccess &&
      dispatch(
        globalActions.setToastNotifcation({
          ...toastNotification,
          type: "SUCCESS",
          message: "Your changes have been saved",
          attractionApprovalStatus: "",
          attractionImage: "",
          attractionName: "",
          attractionAddress: "",
        })
      );
  }, [isSuccess]);

  useEffect(() => {
    isError &&
      dispatch(
        globalActions.setToastNotifcation({
          ...toastNotification,
          type: "ERROR",
          message: "Cannot save changes",
          attractionApprovalStatus: "",
          attractionImage: "",
          attractionName: "",
          attractionAddress: "",
        })
      );
  }, [isError]);

  useEffect(() => {
    isSuccessResetPassword &&
      dispatch(
        globalActions.setToastNotifcation({
          ...toastNotification,
          type: "SUCCESS",
          message: data.message,
          attractionApprovalStatus: "",
          attractionImage: "",
          attractionName: "",
          attractionAddress: "",
        })
      );
  }, [isSuccessResetPassword]);

  useEffect(() => {
    isErrorResetPassword &&
      dispatch(
        globalActions.setToastNotifcation({
          ...toastNotification,
          type: "ERROR",
          message: "Cannot save changes",
          attractionApprovalStatus: "",
          attractionImage: "",
          attractionName: "",
          attractionAddress: "",
        })
      );
  }, [isErrorResetPassword]);

  useEffect(() => {
    if (getUserByEmail) {
      setValue("firstName", getUserByEmail?.first_name ?? "");
      setValue("lastName", getUserByEmail?.last_name ?? "");
      setValue("email", getUserByEmail?.email ?? "");
      setValue("phoneNumber", getUserByEmail?.phone ?? "");
      setValue(
        "attractions",
        (getUserByEmail?.role === 'ATTRACTION_MANAGER' ? getUserByEmail?.attractions : getUserByEmail?.attractions_operator)?.map((option: any) => ({
          value: option.id,
          label: option.name,
        })) ?? [{ value: "", label: "" }]
      );
      setValue(
        "operators",
        getUserByEmail?.operator
          ? {
            value: getUserByEmail?.operator.id,
            label: getUserByEmail?.operator.title,
          }
          : { value: 0, label: "unassigned" }
      );
      setValue(
        "usertypes",
        getUserByEmail?.role
          ? {
            value: getUserByEmail?.role,
            label: getUserByEmail?.role
              ? getUserByEmail?.role === "OPERATOR_OWNER"
                ? "Owner"
                : getUserByEmail?.role === "ATTRACTION_MANAGER"
                  ? "Manager"
                  : ""
              : "",
          }
          : { value: "OPERATOR_OWNER", label: "Owner" }
      );
      setValue(
        "status",
        getUserByEmail?.access_restricted ? "suspended" : "active"
      );
    }
  }, [getUserByEmail]);

  useEffect(() => {
    setSelectedOperatorOptions(
      getOperators?.results
        .concat({ id: 0, title: "unassigned" })
        .sort((a, b) => (a.id > b.id ? 1 : b.id > a.id ? -1 : 0))
        .map((operator) => ({ value: operator.id, label: operator.title }))
    );
  }, [getOperators]);

  useEffect(() => {
    if (
      userError &&
      (userError as unknown as { status: number })?.status === 403
    ) {
      navigate("/forbidden");
    }
  }, [userError]);

  const loadOperators = (
    inputValue: string,
    callback: (
      options: {
        value: string;
        label: string;
      }[]) => void
  ) => {
    lazyGetOperators({
      query: inputValue,
    })
      .unwrap()
      .then((data) => {
        const options = data?.results.map((a) => ({
          value: a.id as string,
          label: a.title ?? "",
        }));

        callback(
          options
        );
      });
  }

  const loadOperatorsDebounced = debounce((inputValue: string, callback: (options: any) => void) => {
    loadOperators(inputValue, callback);
  }, 500)


  return (
    <div className="flex flex-col flex-1 bg-[#F5F5FA] doodle-bg">
      <Header title="Edit User" showBack />

      <SingleAttractionTemplate name={"User Details"}>
        <form className="pb-10" onSubmit={handleSubmit(onSubmit)}>
          <fieldset className="flex flex-wrap w-full mb-4 md:w-3/4 xl:w-2/5">
            <div className="w-full grid grid-cols-2 gap-2">
              <TextInput
                className="group"
                labelClassName="group-focus-within:text-cs-pink font-bold"
                inputClassName="border-cs-1 border-cs-bright-lt-gray text-cs-gray rounded-md py-3 px-4 flex flex-col w-full focus:outline-cs-pink"
                control={control}
                name={"firstName"}
                label="First name"
                required
                inputProps={{ ...register("firstName") }}
              />
              <TextInput
                className="group"
                labelClassName="group-focus-within:text-cs-pink font-bold"
                inputClassName="border-cs-1 border-cs-bright-lt-gray text-cs-gray rounded-md py-3 px-4 flex flex-col w-full focus:outline-cs-pink"
                control={control}
                name={"lastName"}
                label="Last name"
                required
                inputProps={{ ...register("lastName") }}
              />
            </div>
            <TextInput
              className="w-full mt-1 mb-3 group md:w-full"
              labelClassName="group-focus-within:text-cs-pink font-bold"
              inputClassName="border-cs-1 border-cs-bright-lt-gray text-cs-gray rounded-md py-3 px-4 flex flex-col w-full focus:outline-cs-pink"
              control={control}
              name={"email"}
              label="Email address"
              required
              readonly={user?.role != "ADMIN" ? true : false}
              inputProps={{ ...register("email") }}
            />
            <TextInput
              className="w-full mt-1 group"
              labelClassName="group-focus-within:text-cs-pink font-bold"
              inputClassName="border-cs-1 border-cs-bright-lt-gray text-cs-gray rounded-md py-3 px-4 flex flex-col w-full focus:outline-cs-pink"
              control={control}
              name={"phoneNumber"}
              label="Phone number"
              required
              inputProps={{ ...register("phoneNumber") }}
            />
            {user?.role === "ADMIN" && (
              <fieldset className="w-full mt-2.5">
                <label className="block mb-2 text-sm font-bold text-black">
                  Operator
                </label>
                <Controller
                  control={control}
                  name={"operators"}
                  render={({
                    field: { ref, value, onChange },
                    fieldState: { error },
                  }) => (
                    <AsyncSelect
                      className="w-full"
                      value={getValues("operators")}
                      placeholder="Select an operator"
                      loadOptions={loadOperatorsDebounced}
                      defaultOptions={getSelectedOperatorOptions}
                      components={{
                        Option: SingleSelectOption,
                        DropdownIndicator: DropDownIndicator,
                        IndicatorSeparator: () => null,
                      }}
                      onChange={(value, meta) =>
                        handleChange(
                          value,
                          getSelectedOperatorOptions,
                          meta,
                          setSelectedOperatorOptions
                        )
                      }
                      options={getSelectedOperatorOptions}
                      isSearchable={true}
                      noOptionsMessage={() =>
                        isFetchingOperators ? "...Loading" : "No Options"
                      }
                      styles={{
                        menu: (styles) => ({
                          ...styles,
                          margin: 0,
                          border: "none",
                        }),
                        menuList: (base, isSelected) => ({
                          ...base,
                          paddingTop: 0,
                          paddingBottom: 0,
                          background: "white",

                          "::-webkit-scrollbar": {
                            width: 14,
                          },
                          "::-webkit-scrollbar-thumb": {
                            background: "#c2c2c2",
                            borderRadius: 10,
                            border: "4px solid white",
                          },
                        }),
                        option: (
                          base,
                          { data, isDisabled, isFocused, isSelected }
                        ) => {
                          return {
                            ...base,
                            background: "white",
                            border: "1px solid #c2c2c2",
                            color: isSelected ? "white" : "#555",
                            "&:hover": {
                              backgroundColor: "#6836D1",
                              color: isSelected || isFocused ? "white" : "#555",
                              border: isFocused ? "1px solid white" : "",
                            },
                            "&:active": {
                              backgroundColor: "#6836D1",
                              color: "white",
                            },
                          };
                        },
                        noOptionsMessage: (base) => ({
                          ...base,
                          color: "#555",
                        }),
                        singleValue: (base) => ({
                          ...base,
                          color: "#555",
                          fontSize: 14,
                        }),
                        control: (styles, state) => ({
                          ...styles,
                          cursor: "pointer",
                          outline: "none",
                          border: state.isFocused
                            ? "1px solid #CED3CF"
                            : "1px solid #CED3CF",
                          "&:hover": {
                            border: state.isFocused
                              ? "1px solid #CED3CF"
                              : "1px solid #CED3CF",
                          },
                          boxShadow: "none",
                          paddingTop: 3,
                          paddingBottom: 3,
                          margin: 0,
                          minHeight: 50,
                          borderRadius: 8
                        }),
                      }}
                    />
                  )}
                />
              </fieldset>
            )}
          </fieldset>

          <fieldset className="w-full mt-[30px] mb-4 md:w-3/4 xl:w-2/5">
            <legend className="text-[22px] font-extrabold text-black">
              Attractions
            </legend>

            <p className="mt-2.5 mb-3 font-normal text-[#5F646D] text-sm">
              A list of attractions this user can manage
            </p>
            <>{
              getUserAttractions?.results && getValues('attractions') ?
                getValues('attractions')?.map(attr => {
                  const attraction = getUserAttractions?.results?.find((a: any) => a.id == attr.value)
                  if (!attraction) return null
                  return (
                    <div className="flex flex-row justify-between w-full mb-2">
                      <p className="text-sm text-black">{attr.label}</p>
                      <Link
                        to={`/attractions/${attraction?.short_id}`}
                        className="text-sm text-cs-pink hover:underline"
                        state={{
                          from: `/user/${getUserByEmail?.id}?${createSearchParams({
                            email: getUserByEmail?.email as string,
                          }).toString()}`
                        }}
                      >
                        View attraction
                      </Link>
                    </div>
                  )
                }) : null
            }

            </>
            <label onClick={() => { setEditingAttractions(!editingAttractions) }} className="block mt-4 mb-2 text-sm font-light underline text-black">
              {editingAttractions ? "Hide" : "Click to change attractions"}
            </label>
            {editingAttractions && <Controller
              control={control}
              name="attractions"
              render={({ field: { onChange, value } }) => (
                <Select
                  value={getValues("attractions")}
                  onChange={onChange}
                  isMulti
                  isClearable={false}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  options={getUserAttractions?.results ? getUserAttractions?.results
                    .map((option: any) => ({
                      value: option.id,
                      label: option.name,
                    }))
                    .sort((a: { label: string }, b: { label: string }) =>
                      a.label.localeCompare(b.label)
                    )
                    : getValues('attractions')}
                  components={{
                    Option: MultiSelectOptions,
                    ClearIndicator: ClearIndicator,
                    DropdownIndicator: DropDownIndicator,
                    IndicatorSeparator: () => null
                  }}
                  placeholder={""}
                  isSearchable={false}
                  noOptionsMessage={() =>
                    isFetching ? "Loading..." : "No Options"
                  }
                  styles={{
                    multiValue: (styles) => ({
                      ...styles,
                      backgroundColor: "#F1F1F1",
                      borderRadius: 8,
                      height: 31,
                      display: "flex",
                      alignItems: "center",
                    }),
                    multiValueRemove: () => ({
                      display: "none",
                    }),
                    multiValueLabel: (styles) => ({
                      ...styles,
                      color: "#000",
                      fontSize: 14,
                      fontWeight: 400,
                      display: "block",
                      paddingLeft: 10,
                      paddingRight: 10,
                    }),
                    menu: (styles) => ({
                      ...styles,
                      margin: 0,
                      paddingLeft: 5,
                      border: "1px solid #6836D1",
                    }),
                    menuList: (base) => ({
                      ...base,
                      marginTop: 0,
                      marginBottom: 0,

                      "::-webkit-scrollbar": {
                        width: 14,
                      },
                      "::-webkit-scrollbar-thumb": {
                        background: "#c2c2c2",
                        borderRadius: 10,
                        border: "4px solid white",
                      },
                    }),
                    noOptionsMessage: (base) => ({
                      ...base,
                      color: "#555",
                    }),
                    control: (styles, state) => ({
                      ...styles,
                      cursor: "pointer",
                      outline: "none",
                      border: state.isFocused
                        ? "1px solid #CED3CF"
                        : "1px solid #CED3CF",
                      "&:hover": {
                        border: state.isFocused
                          ? "1px solid #CED3CF"
                          : "1px solid #CED3CF",
                      },
                      boxShadow: "none",
                      paddingTop: 0,
                      paddingRight: 8,
                      paddingLeft: 5,
                      paddingBottom: 0,
                      minHeight: 50,
                      borderRadius: 8
                    }),
                  }}
                  theme={(theme) => ({
                    ...theme,
                    background: "none",
                    colors: {
                      ...theme.colors,
                      neutral80: "#555",
                    },
                  })}
                />
              )}
            />
            }
          </fieldset>

          <fieldset className="w-full mt-10 mb-4 md:w-3/4 xl:w-2/5">
            <legend className="text-[22px] font-extrabold text-black">
              User Status
            </legend>

            {user?.role == "ADMIN" && (
              <fieldset className="mt-4 mb-6">
                <label className="block mb-2 text-sm font-bold text-black">
                  User type
                </label>
                <Controller
                  control={control}
                  name={"usertypes"}
                  render={({
                    field: { ref, value, onChange },
                    fieldState: { error },
                  }) => (
                    <Select
                      className="w-full"
                      value={getValues("usertypes")}
                      components={{
                        Option: SingleSelectOption,
                        DropdownIndicator: DropDownIndicator,
                        IndicatorSeparator: () => null,
                      }}
                      onChange={onChange}
                      options={userTypes}
                      isSearchable={false}
                      noOptionsMessage={() =>
                        isFetchingOperators ? "...Loading" : "No Options"
                      }
                      styles={{
                        menu: (styles) => ({
                          ...styles,
                          margin: 0,
                          border: "none",
                        }),
                        menuList: (base, isSelected) => ({
                          ...base,
                          paddingTop: 0,
                          paddingBottom: 0,
                          background: "white",

                          "::-webkit-scrollbar": {
                            width: 14,
                          },
                          "::-webkit-scrollbar-thumb": {
                            background: "#c2c2c2",
                            borderRadius: 10,
                            border: "4px solid white",
                          },
                        }),
                        option: (
                          base,
                          { data, isDisabled, isFocused, isSelected }
                        ) => {
                          return {
                            ...base,
                            background: "white",
                            border: "1px solid #c2c2c2",
                            color: isSelected ? "white" : "#555",
                            "&:hover": {
                              backgroundColor: "#6836D1",
                              color: isSelected || isFocused ? "white" : "#555",
                              border: isFocused ? "1px solid white" : "",
                            },
                            "&:active": {
                              backgroundColor: "#6836D1",
                              color: "white",
                            },
                          };
                        },
                        noOptionsMessage: (base) => ({
                          ...base,
                          color: "#555",
                        }),
                        singleValue: (base) => ({
                          ...base,
                          color: "#555",
                          fontSize: 14,
                        }),
                        control: (styles, state) => ({
                          ...styles,
                          cursor: "pointer",
                          outline: "none",
                          border: state.isFocused
                            ? "1px solid #CED3CF"
                            : "1px solid #CED3CF",
                          "&:hover": {
                            border: state.isFocused
                              ? "1px solid #CED3CF"
                              : "1px solid #CED3CF",
                          },
                          boxShadow: "none",
                          paddingTop: 3,
                          paddingBottom: 3,
                          margin: 0,
                          minHeight: 50,
                          borderRadius: 8
                        }),
                      }}
                    />
                  )}
                />
              </fieldset>
            )}

            <p className="mt-2 mb-2 font-normal text-[#5F646D] text-sm">
              Select a status for this user
            </p>

            <div className="mb-3 radio-item">
              <input
                {...register("status")}
                type="radio"
                value="active"
                id="fieldActive"
              />
              <label htmlFor="fieldActive">Active</label>
            </div>

            <div className="radio-item">
              <input
                {...register("status")}
                type="radio"
                value="suspended"
                id="fieldAccessSuspended"
              />
              <label htmlFor="fieldAccessSuspended">Access suspended</label>
            </div>
          </fieldset>

          <div>
            <PrimaryButton
              className="w-[145px]"
              scale="sm"
              type="submit"
              loading={isLoading}
            >
              Save
            </PrimaryButton>
          </div>

          <ModalWrapper
            className="w-[500px]"
            open={getShowRevertModal}
            setOpen={setShowRevertModal}
          >
            <p className="mb-4 text-base font-normal text-cs-gray">
              This will clear the list of attraction this user can manage.{" "}
            </p>
            <h4 className="text-lg font-bold text-cs-gray">Are you sure?</h4>

            <div className="flex flex-row mt-10">
              <button
                type="button"
                className="px-6 py-2 mx-auto text-sm text-white border-2 h-cs-55 rounded-2xl bg-cs-pink border-cs-pink hover:bg-white hover:text-cs-pink"
                onClick={() => fetchNewAttractionsToOperator()}
              >
                Yes
              </button>

              <button
                type="button"
                className="ml-20 text-sm text-cs-gray hover:text-cs-pink hover:underline"
                onClick={() => {
                  setShowRevertModal(false);
                }}
              >
                No
              </button>
            </div>
          </ModalWrapper>

          {isSubmitted && !isValid && Object.keys(errors).length !== 0 && (
            <ErrorMessage>
              Please fix the errors indicated above to continue
            </ErrorMessage>
          )}
        </form>

        <form
          className="pt-[30px] border-t border-[#D9D9D9] xl:w-2/5 md:w-3/4 w-full"
          onSubmit={handleSubmit(onSubmitResetPassword)}
        >
          <h3 className="text-[22px] font-extrabold text-black">Security</h3>
          <p className="mt-2 mb-4 font-light text-[#5F646D] text-sm">
            {isSuccessResetPassword ? (
              <span>
                A password reset has been sent to{" "}
                <span className="font-bold text-cs-pink">
                  {getUserByEmail?.email}
                </span>
                , didn't send? try again below
              </span>
            ) : (
              "Send this user emailed instructions to reset their password"
            )}
          </p>

          <div className="w-[200px]">
            <Button
              color={"outlineGray"}
              scale="md"
              type="submit"
              className="px-[18px]"
              disabled={isLoadingResetPassword}
            >
              {isSuccessResetPassword ? "Resend Password" : "Reset Password"}
            </Button>
          </div>
        </form>
      </SingleAttractionTemplate>

      <Footer />
    </div>
  );
};

export default EditUser;
