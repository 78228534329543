import { useState } from 'react';
import { SubmitHandler, set, useForm } from 'react-hook-form';
import { apiSlice } from '../../../../store/apiSlice';
import { QrScanner } from '../../../../lib/qr-scanner';
import DashboardHeaderContainer from '../../../../components/amp/DashboardHeaderContainer';
import DashboardContainer from '../../../../components/amp/DashboardContainer';
import { Button } from '../../../../components/global/Button';

type TicketReservation = {
    id: string;
    booking_id: string;
    ticket_id: string;
    pricing_option_id: string;
    price: string;
    quantity: number;
    expires_at: string;
    status: string;
    created_at: string;
    updated_at: string;
    reservation_reference: string;
};
const scanDelay = 1500

const ScanTicket: React.FC = () => {
    const { register, handleSubmit } = useForm();
    const [qrCodeValue, setQrCodeValue] = useState<string>('');
    const [ticketValue, setTicketValue] = useState<TicketReservation | null>(null);
    const [noReservationFound, setNoReservationFound] = useState<boolean>(false);
    const [recentlyScanned, setRecentlyScanned] = useState<boolean>(false);
    const [showReferenceForm, setShowReferenceForm] = useState<boolean>(false);


    const [getReservationByQRMutation] = apiSlice.useGetReservationByQRMutation();
    const [getReservationByReference] = apiSlice.useGetReservationByReferenceMutation();
    const [checkInReservation] = apiSlice.useCheckInReservationMutation();
    const [checkedIn, setCheckedIn] = useState<boolean>(false);

    async function scan(qrCode: string) {
        setTicketValue(null);
        setRecentlyScanned(true);
        setNoReservationFound(false);
        let ticket: any = await getReservationByQRMutation(qrCode);
        ticket = ticket.data as any;
        if (ticket) {
            setTicketValue(ticket);
        } else {
            setNoReservationFound(true);
        }

        setTimeout(() => {
            setRecentlyScanned(false);
        }, scanDelay)
    }

    async function checkIn(reservationId: string) {
        if (!reservationId) return;
        const response: any = await checkInReservation(reservationId);
        console.log(response, 'res');
        if (response.data) {

            setCheckedIn(true);
            const ticketValueCopy = { ...ticketValue };
            ticketValueCopy.status = 'checked_in';
            setTicketValue(ticketValueCopy as any);
            setTimeout(() => {
                setTicketValue(null);
                setCheckedIn(false);
            }, 2000);
        }
    }

    const onTicketReferenceSubmit: SubmitHandler<any> = async (formValues) => {
        setTicketValue(null);
        setNoReservationFound(false);
        if (!formValues.ticketReference) return;
        let ticket: any = await getReservationByReference(formValues.ticketReference);
        ticket = ticket.data;
        setTicketValue(ticket);
        if (!ticket) setNoReservationFound(true);
    };
    return (
        <>
            <div className="flex flex-col flex-1 bg-cs-off-white">
                <DashboardHeaderContainer>
                    <h1 className="font-extrabold text-xl text-black font-roboto-flex xl:pl-[300px]">Ticketing</h1>
                </DashboardHeaderContainer>
                <DashboardContainer>
                    <div className="px-[10px] mx-auto">
                        <div className="md:w-3/12 sm:w-4/5">
                            <label>Scan QR Code</label>
                            <QrScanner scanDelay={scanDelay} onError={(e) => console.log('Qr Sacn error')} onResult={(r) => {
                                if (recentlyScanned) return;
                                console.log('result', r);
                                navigator.vibrate(200);
                                setQrCodeValue(r.getText());
                                scan(r.getText());
                            }} />
                        </div>
                        <div className='mt-2'>
                               <a href='#' className='underline' onClick={(e) => {
                                e.preventDefault();
                                setShowReferenceForm(!showReferenceForm)
                               }}><label>
                                { !showReferenceForm ? <>Or tap to enter the ticket reference <small>(e.g. 1704390246)</small></> : "Hide refence input"} </label></a>
                            { showReferenceForm && <form onSubmit={handleSubmit(onTicketReferenceSubmit)} className="">
                                <div className="flex items-center">
                                    <input
                                        {...register('ticketReference')}
                                        type="text"
                                        className="mt-1 mr-2 p-2 block  border rounded-md bg-gray-100"
                                    />
                                    <Button
                                        type="submit">
                                        Submit
                                    </Button>
                                </div>
                            </form>}
                            
                            </div>
                        {noReservationFound && <div className="text-red-500 mt-2">No reservation found</div>}
                        {ticketValue && <div className='mt-2'>
                            <label className="text-lg">Reservation Details</label>
                            <p>Ticket Status: {ticketValue?.status}</p>
                            <p>Ticket Reference: {ticketValue?.reservation_reference}</p>
                            <p>Ticket Price: £{ticketValue?.price}</p>
                            <p>Ticket Quantity: {ticketValue?.quantity}</p>
                            <p>Ticket Created At: {ticketValue?.created_at}</p>

                            <Button
                                disabled={ticketValue?.status === 'checked_in'}
                                onClick={() => checkIn(ticketValue.id)}>
                                {ticketValue?.status === 'checked_in' ? 'Checked In' : 'Check In'}
                            </Button>
                            {checkedIn && <div className="text-green-500 mt-2">Checked In</div>}
                        </div>}

                    </div>
                </DashboardContainer>
            </div>
        </>
    );
};

export default ScanTicket;
