import { useFormContext } from 'react-hook-form';
import TextInput from '../../../../components/form/TextInput';

export function TicketAge() {
  const { control, register } = useFormContext()
  return (
    <>
      <div className="flex">
        <div className="relative mr-2">
          <TextInput
            name={"min_age"}
            className="w-full mt-2 mb-4 border border-cs-gray rounded-md px-3 py-2 text-sm text-cs-gray"
            labelClassName="group-focus-within:text-cs-pink text-sm font-normal"
            inputClassName="border-cs-1 border-cs-bright-lt-gray text-cs-gray rounded-md py-3 pr-4 pl-4 flex flex-col w-full focus:outline-cs-pink"
            label="Minimum age"
            control={control}
            required
            inputProps={{
              ...register('min_age'),
              type: "number",
            }} />
        </div>
        <div className="relative ml-2">
          <TextInput
            name={"max_age"}
            className="w-full mt-2 mb-4 border border-cs-gray rounded-md px-3 py-2 text-sm text-cs-gray"
            labelClassName="group-focus-within:text-cs-pink text-sm font-normal"
            inputClassName="border-cs-1 border-cs-bright-lt-gray text-cs-gray rounded-md py-3 pr-4 pl-4 flex flex-col w-full focus:outline-cs-pink"
            label="Maximum age"
            control={control}
            required
            inputProps={{
              ...register('max_age'),
              type: "number",
            }} />
        </div>
      </div>
    </>);
}