import { Controller, useFormContext } from 'react-hook-form';
import Select from "react-select";

export function TicketCategory() {
  const { control } = useFormContext()
  const categories = [
    { value: 'adult', label: 'Adult' },
    { value: 'child', label: 'Child' },
    { value: 'infant', label: 'Infant' },
    { value: 'senior', label: 'Senior' },
    { value: 'student', label: 'Student' },
    { value: 'military', label: 'Military' },
    { value: 'family', label: 'Family' },
    { value: 'group', label: 'Group' },
  ]
  return (
    <>
      <label htmlFor='category' className="block font-medium text-gray-700  rounded-md">
        Ticket Category
      </label>

      <Controller
        name="category"
        control={control}
        render={({ field }) => (
          <div className='border border-cs-gray rounded-md p-2'>
            <Select
              required={true}
              {...field}
              value={categories.find((option: any) => option.value === field.value)}
              className="w-full mt-2 mb-2 text-sm text-cs-gray"
              options={categories}
              onChange={(v) => {
                field.onChange((v as any)?.value)
              }}
            />
          </div>
        )} />
    </>);

}
