import Header from "../../../components/global/amp/Header";
import Footer from "../../../components/global/amp/Footer";
import { ReactNode, useEffect } from "react";
import { apiSlice } from "../../../store/apiSlice";
import { useNavigate, useParams } from "react-router-dom";
import { SubmitHandler } from "react-hook-form";
import DashboardContainer from "../../../components/amp/DashboardContainer";
import { VenueOptScoreProgressCircular } from "../../../components/amp/VenueOptScoreProgressCircular";
import { useAppSelector } from "../../../store";

interface IAttractionDetails {
  showPublishingButtons: boolean;
}

export type VenueOptTask = {
  key: string;
  display: string;
  description: string;
  score: number;
  prerequisite: string[] | null;
  replaces_prerequisite: boolean;
  completed?: boolean;
};

const AttractionVenueOptScore: React.FC<IAttractionDetails> = () => {
  const { attractionId } = useParams();
  const { data: getAttraction, error: attractionError } =
    apiSlice.useGetAttractionQuery(attractionId ?? "");
  const { data: getMetrics } = apiSlice.useGetVenueOptScoreMetricsQuery();
  const attractionScores =
    getAttraction?.result?.published_version?.optimisation_scores;
  const completedTasks = getMetrics?.result
    ?.filter(
      (metric) =>
        !!attractionScores?.find((s) => s.metric_key === metric.key)?.fulfilled
    )
    .map((s) => ({ ...s, completed: true }));
  const incompleteTasks = getMetrics?.result?.filter(
    (metric) =>
      !attractionScores?.find((s) => s.metric_key === metric.key)?.fulfilled
  );
  const { result } = getAttraction ?? {};

  useEffect(() => {
    document.title =
      "Attraction Venue Optimisation Score | Day Out With The Kids";
  }, []);

  const navigate = useNavigate();
  const { user } = useAppSelector((state) => state.global);
  useEffect(() => {
    if (user?.role === "ADMIN") return;
    if (
      attractionError &&
      (attractionError as unknown as { status: number })?.status === 403
    ) {
      navigate("/forbidden", { replace: true });
    }
    if (
      getAttraction?.result?.listing_package &&
      !["STANDARD", "ENHANCED", "PREMIUM"].includes(
        getAttraction?.result?.listing_package
      )
    ) {
      navigate("/forbidden", { replace: true });
    }
  }, [attractionError, navigate, getAttraction, user?.role]);

  const onSubmit = (publish: boolean = false, isSilent: boolean = false) => {
    const fn: SubmitHandler<{}> = async (e) => {
      return true;
    };

    return fn;
  };
  return (
    <div className="flex flex-col flex-1 bg-[#F5F5FA] doodle-bg">
      <Header
        title="Edit Attraction"
        mobilePublishButtons={true}
        showPublishingButtons
        getPayload={() => ({})}
        saveFn={onSubmit}
      />

      <div className="h-0 xl:h-[109px]"></div>
      <Container>
        <h2 className="text-[22px] font-extrabold text-[#212121]">
          ⚡️ Venue Optimisation Score (VO Score)
        </h2>

        <p className="pl-6 font-normal text-sm text-[#212121] mt-5 leading-[21px] w-2/3">
          Elevate your attraction's profile with the brand new Venue
          Optimisation Score! Harness the power of ‘VOS’ to boost your online
          presence, be seen by more of our 35 million annual visitors, and turn
          clicks into crowds. Every attraction has a score out of 100. Get ahead
          in the UK's bustling family days out scene – Enhance, engage, excel
          with ‘VOS’.
        </p>
      </Container>

      <Container>
        <div className="w-full md:w-1/2">
          <div className="flex gap-5 items-center">
            <VenueOptScoreProgressCircular
              score={result?.published_version?.total_vo_score || 0}
              maxScore={result?.published_version?.max_possible_vo_score || 100}
              size={85}
              stroke={9}
            >
              <div className="flex flex-col items-center text-lg font-bold">
                <div>{result?.published_version?.total_vo_score || 0}</div>
                <div className="h-[1px] bg-black w-8"></div>
                <div>
                  {result?.published_version?.max_possible_vo_score || 100}
                </div>
              </div>
            </VenueOptScoreProgressCircular>

            <h1 className="text-[22px] font-bold">{result?.name}</h1>
          </div>

          <div className="mt-5 flex items-center gap-[5px]">
            <div className="text-[22px] font-bold">
              {completedTasks?.length || 0}/{getMetrics?.result?.length || 0}
            </div>
            <div className="text-sm font-normal">Optimisations Completed</div>
          </div>

          <div className="mt-5">
            <ProgressBar
              percent={
                ((completedTasks?.length || 0) * 100) /
                (getMetrics?.result?.length || 1)
              }
            />
          </div>

          <p className="font-normal text-sm text-[#5F646D] mt-5 leading-[21px]">
            The more optimisations you complete, the higher your VO Score, which
            will lead to more prominence and exposure across the DOWTK website.
          </p>
        </div>
      </Container>

      {(completedTasks?.length ?? 0) > 0 && (
        <TasksContainer tasks={completedTasks || []} />
      )}
      {(incompleteTasks?.length ?? 0) > 0 && (
        <TasksContainer tasks={incompleteTasks || []} />
      )}

      <div className="h-12"></div>

      <Footer />
    </div>
  );
};

export default AttractionVenueOptScore;

function Container({ children }: { children: ReactNode }) {
  return (
    <DashboardContainer>
      <section
        className={`flex flex-col  xl:mx-[70px] p-5 bg-white xl:rounded-lg`}
      >
        {children}
      </section>
    </DashboardContainer>
  );
}

export function ProgressBar({ percent }: { percent: number }) {
  return (
    <div className="h-[9px] w-full bg-[#D9D9D9] rounded-full relative">
      <div
        className="h-[9px] rounded-full absolute left-0 top-0 bg-[#6836D1]"
        style={{
          width: `${percent > 100 ? 100 : percent}%`,
        }}
      ></div>
    </div>
  );
}

const result = Object.entries({
  overview: ["attraction_contact_details"],
  details: [
    "basic_details",
    "attraction_long_description",
    "attraction_highlights"
  ],
  location: ["attraction_contact_details"],
  "opening-times": ["opening_times_and_pricing"],
  prices: ["opening_times_and_pricing", "vip_pass_discount", "tickets_bookable"],
  faqs: ["faqs"],
  images: ["attraction_images"],
});
function TasksContainer({ tasks }: { tasks: VenueOptTask[] }) {
  const completed = tasks.filter((t) => t.completed).length === tasks.length;
  const navigate = useNavigate();
  const { attractionId } = useParams();
  return (
    <Container>
      <div className="w-full md:w-1/2">
        <span
          className={`inline-block rounded-full py-[5px] px-2.5 h-[27px] text-sm font-normal leading-4 ${
            !completed
              ? "text-[#212121] bg-[#F3F3F3]"
              : "text-[#009763] bg-[#ECFBF6]"
          }`}
        >
          {completed ? "Completed" : "Incomplete"}
        </span>

        <div className="flex flex-col">
          {tasks.map((task, i) => {
            const pageToGo = result.find((result) =>
              result[1].includes(task.key)
            )?.[0];
            return (
              <div
                className={`flex items-center gap-2.5 py-5 pl-2.5 ${
                  i !== tasks.length - 1 ? "border-b border-[#D9D9D9]" : ""
                } ${pageToGo ? "cursor-pointer" : ""}`}
                onClick={() =>
                  pageToGo && navigate(`/attractions/${attractionId}/${pageToGo}`)
                }
              >
                <div
                  className={`w-4 h-4 flex-shrink-0 rounded-full flex justify-center items-center ${
                    task.completed ? "bg-[#6836D1]" : "bg-[#D9D9D9]"
                  }`}
                >
                  {task.completed && (
                    <svg
                      width="10"
                      height="9"
                      viewBox="0 0 10 9"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.692383 5.03533L3.15392 7.49686L9.30777 1.34302"
                        fill="#6836D1"
                      />
                      <path
                        d="M0.692383 5.03533L3.15392 7.49686L9.30777 1.34302"
                        stroke="white"
                        strokeWidth="1.23077"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  )}
                </div>

                <div>
                  <div className="text-sm font-bold text-[#212121] flex items-center gap-2.5">
                    {task.display}

                    <span className="text-xs font-normal">
                      (+{task.score}pt{task.score === 1 ? "" : "s"})
                    </span>
                  </div>
                  <div className="text-sm font-normal text-[#212121]">
                    {task.description}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Container>
  );
}
