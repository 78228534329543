import Header from "../../components/global/amp/Header";
import Footer from "../../components/global/amp/Footer";
import { isDirty, z } from "zod";
import { useEffect, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  SubmitHandler,
  useForm,
  Controller,
  useFieldArray,
  UseFieldArrayReturn,
  UseFormGetValues,
  useWatch,
} from "react-hook-form";
import TextInput from "../../components/form/TextInput";
import { apiSlice } from "../../store/apiSlice";
import { globalActions } from "../../store/globalSlice";
import { useAppDispatch, useAppSelector } from "../../store";
import type { AmpUserDetail } from "../../types/user";
import { useNavigate, useParams } from "react-router-dom";
import { SingleAttractionTemplate } from "../../components/global/SingleAttractionTemplate";
import { PrimaryButton } from "../../components/global/PrimaryButton";
import { ErrorMessage } from "../../components/global/ErrorMessage";
import CountiesSelect from "../../components/form/CountiesSelect";
import { ColorInput } from "../../components/form/ColorInput";
import ModalWrapper from "../../components/global/ModalWrapper";
import {
  mediaSchema,
  useMediaUpload,
  ImageCropper,
} from "../../hooks/useMediaUpload";
import { AttractionSearch } from "../../components/form/AttractionsSearch";
import { isSuccessResponse } from "../../helpers/rtkHelpers";
import { restartAnimation } from "../../components/global/amp/ToastNotification";

export interface CreateAdPlacementPayload {
  id?: string;
  venue_id: string | null;
  title: string;
  url: string;
  position: number;
  valid_from: string;
  valid_to: string;
  county_1_id: string | null;
  county_2_id: string | null;
  county_3_id: string | null;
  county_4_id: string | null;
  county_5_id: string | null;
  county_6_id: string | null;
  county_7_id: string | null;
  county_8_id: string | null;
  county_9_id: string | null;
  county_10_id: string | null;
  county_11_id: string | null;
  county_12_id: string | null;

  media_1_id: string | null;
  media_2_id: string | null;
  media_3_id: string | null;
  media_4_id: string | null;

  subtitle: string;
  display_name: string
}
const AddAdPlacement: React.FC<{ isEdit?: boolean }> = ({ isEdit = false }) => {
  const { user, toastNotification } = useAppSelector((state) => state.global);
  const dispatch = useAppDispatch();

  const { id } = useParams();

  const {
    data: getQuery,
    isSuccess: isQuerySuccess,
    isFetching,
    refetch,
  } = apiSlice.useGetPlacementQuery({ id: id ?? "" }, { skip: !isEdit || !id });

  const [uploadMedia] = apiSlice.useUploadImageToAdPlacementsMutation();
  const [addAdPlacement, { isLoading, isError, isSuccess, error }] =
    apiSlice.useAddPlacementMutation();

  const schema = z.object({
    venue: z.string().nullable(),
    title: z.string().min(1, "Required"),
    url: z.string(),
    position: z.number(),
    validFrom: z.any(),
    validTo: z.any(),
    county_1: z.string().min(1, "Required"),
    county_2: z.string().nullable(),
    county_3: z.string().nullable(),
    county_4: z.string().nullable(),
    county_5: z.string().nullable(),
    county_6: z.string().nullable(),
    county_7: z.string().nullable(),
    county_8: z.string().nullable(),
    county_9: z.string().nullable(),
    county_10: z.string().nullable(),
    county_11: z.string().nullable(),
    county_12: z.string().nullable(),
    subtitle: z.string().min(1, "Required"),
    // enhanced_background_hex_colour: z.string(),
    media: mediaSchema,
  });

  type AddAdPlacementForm = z.infer<typeof schema>;

  const {
    register,
    handleSubmit,
    formState: { isValid, isSubmitted, errors },
    watch,
    control,
    getValues,
    setValue,
    reset,
  } = useForm<AddAdPlacementForm>({
    resolver: zodResolver(schema),
    defaultValues: {
      venue: null,
      position: 1,
      county_1: undefined,
      county_2: null,
      county_3: null,
      county_4: null,
      county_5: null,
      county_6: null,
      county_7: null,
      county_8: null,
      county_9: null,
      county_10: null,
      county_11: null,
      county_12: null,
      validFrom: new Date().toISOString().split("T")?.[0]!,
      validTo: new Date().toISOString().split("T")?.[0]!,
    },
  });

  useEffect(() => {
    document.title = "Add Ad Placement | Day Out With The Kids";
  }, []);

  useEffect(() => {
    if (isSuccess) {
      dispatch(
        globalActions.setToastNotifcation({
          ...toastNotification,
          type: "SUCCESS",
          message: "Successful",
          attractionApprovalStatus: "",
          attractionImage: "",
          attractionName: "",
          attractionAddress: "",
        })
      );
    }
  }, [isSuccess]);

  useEffect(() => {
    isError &&
      dispatch(
        globalActions.setToastNotifcation({
          ...toastNotification,
          type: "ERROR",
          message: "Cannot add changes",
          attractionApprovalStatus: "",
          attractionImage: "",
          attractionName: "",
          attractionAddress: "",
        })
      );
  }, [isError]);

  const navigate = useNavigate();
  useEffect(() => {
    if (
      !(user as AmpUserDetail)?.email_verified &&
      (user as AmpUserDetail).role !== "ADMIN"
    ) {
      navigate("/dashboard");
    }
  }, []);

  const [getShowDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [croppingImage, setCroppingImage] = useState<number>();
  const [getShowCropModal, setShowCropModal] = useState<boolean>(false);
  const [getShowGalleryModal, setShowGalleryModal] = useState<boolean>(false);

  const mediaControls = useFieldArray({
    control,
    name: "media",
    rules: {
      minLength: 0,
    },
  });
  const { fields, append } = mediaControls;
  const {
    createMedia,
    removeSingle,
    selectedImages,
    togglePrimary,
    toggleSelect,
    cancelCrop,
    cropImage,
  } = useMediaUpload({
    getValues: getValues as unknown as UseFormGetValues<{
      media: z.infer<typeof mediaSchema>;
    }>,
    controls: mediaControls as unknown as UseFieldArrayReturn<
      {
        media: z.infer<typeof mediaSchema>;
      },
      "media",
      "id"
    >,
    croppingImage,
    setCroppingImage,
    setShowCropModal,
    setShowDeleteModal,
  });

  const [galleryMedia, setGalleryMedia] = useState<
    { url: string; id: string }[]
  >([]);
  const [galleryAttraction, setGalleryAttraction] = useState("");

  function selectGalleryImage(media: { id: string; url: string }) {
    append({
      createdAt: new Date(),
      filename: media.url,
      isPrimary: false,
      persisted: true,
      size: 0,
      url: media.url,
      image_db_id: media.id,
    });
    setShowGalleryModal(false);
  }

  const [uploadingMedia, setUploadingMedia] = useState(false);
  async function onSubmit() {
    const payload: CreateAdPlacementPayload = {
      id: isEdit ? id : undefined,
      venue_id: getValues("venue") ?? "",
      title: getValues("title"),
      url: getValues("url"),
      position: +getValues("position") ?? 0,
      valid_from: getValues("validFrom")
        ? new Date(getValues("validFrom")).toISOString()
        : new Date().toISOString(),
      valid_to: getValues("validTo")
        ? new Date(getValues("validTo")).toISOString()
        : new Date().toISOString(),
      county_1_id: getValues("county_1") ?? "",
      county_2_id: getValues("county_2") ?? "",
      county_3_id: getValues("county_3") ?? "",
      county_4_id: getValues("county_4") ?? "",
      county_5_id: getValues("county_5") ?? "",
      county_6_id: getValues("county_6") ?? "",
      county_7_id: getValues("county_7") ?? "",
      county_8_id: getValues("county_8") ?? "",
      county_9_id: getValues("county_9") ?? "",
      county_10_id: getValues("county_10") ?? "",
      county_11_id: getValues("county_11") ?? "",
      county_12_id: getValues("county_12") ?? "",
      media_1_id: "",
      media_2_id: "",
      media_3_id: "",
      media_4_id: "",
      subtitle: getValues("subtitle"),
      display_name: ""
    };

    for (let i = 0; i < getValues("media").length; i++) {
      const media = getValues("media")[i];
      const index = (i + 1) as 1 | 2 | 3 | 4;

      if (media.image_db_id) {
        payload[`media_${index}_id`] = media.image_db_id;
      } else {
        setUploadingMedia(true);
        const uploadedMedia = await uploadMedia({
          file: media.url,
        });
        if (isSuccessResponse(uploadedMedia)) {
          payload[`media_${index}_id`] = uploadedMedia.data.id;
        } else {
          console.log("ERROR Uploading Media", uploadedMedia.error);
        }
        setUploadingMedia(false);
      }
    }

    const results = await addAdPlacement(payload);

    if ("data" in results && !isEdit) {
      navigate(`/ad-placements/${results.data.id}`);
    }

    await refetch();
    restartAnimation();
  }

  useEffect(() => {
    if (getQuery && isQuerySuccess && !isFetching) {
      const media: z.infer<typeof mediaSchema> = [];

      ([1, 2, 3, 4] as (1 | 2 | 3 | 4)[]).forEach((i) => {
        if (getQuery[`media_${i}`]) {
          media.push({
            createdAt: new Date(),
            filename: "",
            isPrimary: false,
            persisted: true,
            size: 0,
            url: getQuery[`media_${i}`]?.url ?? "",
            image_db_id: getQuery[`media_${i}`]?.id ?? "",
          });
        }
      });

      reset(
        {
          venue: getQuery.venue_id,
          title: getQuery.title,
          url: getQuery.url,
          validFrom: new Date(getQuery.valid_from).toISOString().split("T")?.[0],
          validTo: new Date(getQuery.valid_to).toISOString().split("T")?.[0],
          county_1: getQuery.county_1_id || undefined,
          county_2: getQuery.county_2_id,
          county_3: getQuery.county_3_id,
          county_4: getQuery.county_4_id,
          county_5: getQuery.county_5_id,
          county_6: getQuery.county_6_id,
          county_7: getQuery.county_7_id,
          county_8: getQuery.county_8_id,
          county_9: getQuery.county_9_id,
          county_10: getQuery.county_10_id,
          county_11: getQuery.county_11_id,
          county_12: getQuery.county_12_id,
          subtitle: getQuery.subtitle,
          position: getQuery.position,
          media: media,
        },
        {
          keepDirty: false,
        }
      );
    }
  }, [getQuery, isFetching]);

  const watchedValidFrom = useWatch({ control, name: "validFrom" });
  const watchedValidTo = useWatch({ control, name: "validTo" });

  function getCountyOption(county: { id: string; name: string } | undefined) {
    if (!county) {
      return {
        label: "",
        value: "",
      };
    }
    return {
      label: county.name,
      value: county.id,
    };
  }

  const defaultAttractions = {
    label: getQuery?.venue?.name ?? "",
    value: getQuery?.venue?.short_id ?? "",
    attraction: {
      id: (getQuery?.venue?.id as string) ?? "",
      image: "",
      image_alt: "",
      location: "",
      title: getQuery?.venue?.name ?? "",
    },
    media: getQuery?.venue?.media,
  };

  const [inGalleryMedia, setInGalleryMedia] = useState(getQuery?.gallery_media ?? [])

  return (
    <div className="flex flex-col flex-1 bg-[#F5F5FA]">
      <Header
        title={!isEdit ? "Add Ad Placement" : "Edit Ad Placement"}
        showBack
      />

      <SingleAttractionTemplate name={"Ad Placement"}>
        <form className="pb-10" onSubmit={handleSubmit(onSubmit)}>
          <fieldset className="flex flex-col w-full mb-4 md:w-3/4 xl:w-2/5 gap-y-5">
            <div>
              <label className="text-black text-sm font-bold">Venue</label>
              <AttractionSearch
                value={watch("venue") ?? ""}
                defaultOptions={defaultAttractions}
                onChange={(value) => setValue("venue", value)}
              />
              {errors["venue"]?.message && (
                <ErrorMessage>{errors["venue"]?.message}</ErrorMessage>
              )}
            </div>
            <TextInput
              className="group"
              labelClassName="group-focus-within:text-cs-pink font-bold"
              inputClassName="border-cs-1 border-cs-bright-lt-gray text-cs-gray rounded-md py-3 px-4 flex flex-col w-full focus:outline-cs-pink"
              control={control}
              name={"title"}
              label="Title"
              required
              inputProps={{ ...register("title") }}
            />
             <TextInput
              className="group"
              labelClassName="group-focus-within:text-cs-pink font-bold"
              inputClassName="border-cs-1 border-cs-bright-lt-gray text-cs-gray rounded-md py-3 px-4 flex flex-col w-full focus:outline-cs-pink"
              control={control}
              name={"subtitle"}
              label="Ribbon Text"
              required
              inputProps={{ ...register("subtitle") }}
            />
            <TextInput
              className="group"
              labelClassName="group-focus-within:text-cs-pink font-bold"
              inputClassName="border-cs-1 border-cs-bright-lt-gray text-cs-gray rounded-md py-3 px-4 flex flex-col w-full focus:outline-cs-pink"
              control={control}
              name={"url"}
              label="URL"
              inputProps={{ ...register("url") }}
            />
            <TextInput
              className="group"
              labelClassName="group-focus-within:text-cs-pink"
              inputClassName="border-2 text-cs-gray rounded-md py-3 px-2 flex w-full focus:outline-cs-pink"
              control={control}
              name={"validFrom"}
              label="Valid from"
              required
              inputProps={{
                ...register("validFrom", {
                  setValueAs(value) {
                    if (value.length > 0) {
                      return new Date(value);
                    }
                    return "";
                  },
                }),
                value:
                  (watchedValidFrom instanceof Date
                    ? (watchedValidFrom as Date)?.toISOString()
                    : watchedValidFrom
                  )?.split("T")?.[0] ?? "",
                type: "date",
              }}
            />
            <TextInput
              className="group"
              labelClassName="group-focus-within:text-cs-pink"
              inputClassName="border-2 text-cs-gray rounded-md py-3 px-2 flex w-full focus:outline-cs-pink"
              control={control}
              name={"validTo"}
              label="Valid to"
              required
              inputProps={{
                ...register("validTo", {
                  setValueAs(value) {
                    if (value.length > 0) {
                      return new Date(value);
                    }
                    return "";
                  },
                }),
                value:
                  (watchedValidTo instanceof Date
                    ? (watchedValidTo as Date)?.toISOString()
                    : watchedValidTo
                  )?.split("T")?.[0] ?? "",
                type: "date",
              }}
            />
           
            <div>
              <label className="text-black text-sm font-bold">County 1 <span className="text-cs-pink"> *</span></label>

              <Controller
                control={control}
                name={`county_1`}
                render={({
                  field: { ref, value, onChange },
                  fieldState: { error },
                }) => (
                  <CountiesSelect
                    defaultValue={value ?? ""}
                    defaultOptions={getCountyOption(getQuery?.county_1)}
                    onChange={onChange}
                  />
                )}
              />
              {errors["county_1"]?.message && (
                <ErrorMessage>{errors["county_1"]?.message}</ErrorMessage>
              )}
            </div>
            <div>
              <label className="text-black text-sm font-bold">County 2</label>

              <Controller
                control={control}
                name={`county_2`}
                render={({
                  field: { ref, value, onChange },
                  fieldState: { error },
                }) => (
                  <CountiesSelect
                    defaultValue={value ?? ""}
                    defaultOptions={getCountyOption(getQuery?.county_2)}
                    onChange={onChange}
                  />
                )}
              />
              {errors["county_2"]?.message && (
                <ErrorMessage>{errors["county_2"]?.message}</ErrorMessage>
              )}
            </div>
            <div>
              <label className="text-black text-sm font-bold">County 3</label>

              <Controller
                control={control}
                name={`county_3`}
                render={({
                  field: { ref, value, onChange },
                  fieldState: { error },
                }) => (
                  <CountiesSelect
                    defaultValue={value ?? ""}
                    defaultOptions={getCountyOption(getQuery?.county_3)}
                    onChange={onChange}
                  />
                )}
              />
              {errors["county_3"]?.message && (
                <ErrorMessage>{errors["county_3"]?.message}</ErrorMessage>
              )}
            </div>
            <div>
              <label className="text-black text-sm font-bold">County 4</label>

              <Controller
                control={control}
                name={`county_4`}
                render={({
                  field: { ref, value, onChange },
                  fieldState: { error },
                }) => (
                  <CountiesSelect
                    defaultValue={value ?? ""}
                    defaultOptions={getCountyOption(getQuery?.county_4)}
                    onChange={onChange}
                  />
                )}
              />
              {errors["county_4"]?.message && (
                <ErrorMessage>{errors["county_4"]?.message}</ErrorMessage>
              )}
            </div>
            <div>
              <label className="text-black text-sm font-bold">County 5</label>

              <Controller
                control={control}
                name={`county_5`}
                render={({
                  field: { ref, value, onChange },
                  fieldState: { error },
                }) => (
                  <CountiesSelect
                    defaultValue={value ?? ""}
                    defaultOptions={getCountyOption(getQuery?.county_5)}
                    onChange={onChange}
                  />
                )}
              />
              {errors["county_5"]?.message && (
                <ErrorMessage>{errors["county_5"]?.message}</ErrorMessage>
              )}
            </div>
            <div>
              <label className="text-black text-sm font-bold">County 6</label>

              <Controller
                control={control}
                name={`county_6`}
                render={({
                  field: { ref, value, onChange },
                  fieldState: { error },
                }) => (
                  <CountiesSelect
                    defaultValue={value ?? ""}
                    defaultOptions={getCountyOption(getQuery?.county_6)}
                    onChange={onChange}
                  />
                )}
              />
              {errors["county_6"]?.message && (
                <ErrorMessage>{errors["county_6"]?.message}</ErrorMessage>
              )}
            </div>
            <div>
              <label className="text-black text-sm font-bold">County 7</label>

              <Controller
                control={control}
                name={`county_7`}
                render={({
                  field: { ref, value, onChange },
                  fieldState: { error },
                }) => (
                  <CountiesSelect
                    defaultValue={value ?? ""}
                    defaultOptions={getCountyOption(getQuery?.county_7)}
                    onChange={onChange}
                  />
                )}
              />
              {errors["county_7"]?.message && (
                <ErrorMessage>{errors["county_7"]?.message}</ErrorMessage>
              )}
            </div>
            <div>
              <label className="text-black text-sm font-bold">County 8</label>

              <Controller
                control={control}
                name={`county_8`}
                render={({
                  field: { ref, value, onChange },
                  fieldState: { error },
                }) => (
                  <CountiesSelect
                    defaultValue={value ?? ""}
                    defaultOptions={getCountyOption(getQuery?.county_8)}
                    onChange={onChange}
                  />
                )}
              />
              {errors["county_8"]?.message && (
                <ErrorMessage>{errors["county_8"]?.message}</ErrorMessage>
              )}
            </div>
            <div>
              <label className="text-black text-sm font-bold">County 9</label>

              <Controller
                control={control}
                name={`county_9`}
                render={({
                  field: { ref, value, onChange },
                  fieldState: { error },
                }) => (
                  <CountiesSelect
                    defaultValue={value ?? ""}
                    defaultOptions={getCountyOption(getQuery?.county_9)}
                    onChange={onChange}
                  />
                )}
              />
              {errors["county_9"]?.message && (
                <ErrorMessage>{errors["county_9"]?.message}</ErrorMessage>
              )}
            </div>
            <div>
              <label className="text-black text-sm font-bold">County 10</label>

              <Controller
                control={control}
                name={`county_10`}
                render={({
                  field: { ref, value, onChange },
                  fieldState: { error },
                }) => (
                  <CountiesSelect
                    defaultValue={value ?? ""}
                    defaultOptions={getCountyOption(getQuery?.county_10)}
                    onChange={onChange}
                  />
                )}
              />
              {errors["county_10"]?.message && (
                <ErrorMessage>{errors["county_10"]?.message}</ErrorMessage>
              )}
            </div>
            <div>
              <label className="text-black text-sm font-bold">County 11</label>

              <Controller
                control={control}
                name={`county_11`}
                render={({
                  field: { ref, value, onChange },
                  fieldState: { error },
                }) => (
                  <CountiesSelect
                    defaultValue={value ?? ""}
                    defaultOptions={getCountyOption(getQuery?.county_11)}
                    onChange={onChange}
                  />
                )}
              />
              {errors["county_11"]?.message && (
                <ErrorMessage>{errors["county_11"]?.message}</ErrorMessage>
              )}
            </div>
            <div>
              <label className="text-black text-sm font-bold">County 12</label>

              <Controller
                control={control}
                name={`county_12`}
                render={({
                  field: { ref, value, onChange },
                  fieldState: { error },
                }) => (
                  <CountiesSelect
                    defaultValue={value ?? ""}
                    defaultOptions={getCountyOption(getQuery?.county_12)}
                    onChange={onChange}
                  />
                )}
              />
              {errors["county_12"]?.message && (
                <ErrorMessage>{errors["county_12"]?.message}</ErrorMessage>
              )}
            </div>
          </fieldset>
          <fieldset className="mt-5">
            <legend className="mb-4 text-[22px] font-bold text-black xl:whitespace-nowrap">
              Media
            </legend>

            <div className="flex gap-5 mb-10">
              {fields.map((field, index) => (
                <Media
                  className="w-full sm:w-[calc(50%-1.5rem)] md:w-[calc(25%-1.5rem)] [@media(min-width:2400px)]:w-[calc(16.666%-1.5rem)]"
                  key={field.id}
                  index={index}
                  size={field.size}
                  createdAt={field.createdAt}
                  url={field.url}
                  isPrimary={
                    (index === 0 &&
                      !watch("media")?.find((m) => m.isPrimary)) ||
                    field.isPrimary
                  }
                  filename={field.filename}
                  checked={selectedImages.includes(index)}
                  togglePrimary={togglePrimary}
                  remove={removeSingle}
                  toggleSelect={toggleSelect}
                  inGallery={inGalleryMedia.includes(
                    field.image_db_id ?? ""
                  )}
                />
              ))}

              {Array.apply(
                null,
                Array(4 - fields.length < 0 ? 0 : 4 - fields.length)
              ).map((x, i) => (
                <>
                  <div className="flex flex-col w-full sm:w-[calc(50%-1.5rem)] md:w-[calc(25%-1.5rem)] [@media(min-width:2400px)]:w-[calc(16.666%-1.5rem)]">
                    <div className="relative flex flex-col mt-2">
                      <div
                        className={`flex justify-center items-center text-center px-10 w-full aspect-[16/4.5] border-2 border-dotted border-[#CFDBD5] cursor-pointer relative ${
                          i !== 0 ? "opacity-50 pointer-events-none" : ""
                        }`}
                      >
                        {i === 0 ? (
                          <span className="text-cs-gray text-xs leading-[18px]">
                            <p>Drop image here</p>
                            <p>Upload image</p>
                          </span>
                        ) : (
                          <span>
                            <svg
                              width="34"
                              height="34"
                              viewBox="0 0 34 34"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g id="camera-01">
                                <g id="Icon">
                                  <path
                                    d="M3.19141 11.963C3.19141 11.4864 3.19141 11.2482 3.21129 11.0475C3.40307 9.11187 4.93431 7.58063 6.86993 7.38885C7.07062 7.36897 7.32175 7.36897 7.824 7.36897C8.01753 7.36897 8.1143 7.36897 8.19645 7.36399C9.24555 7.30046 10.1642 6.63832 10.5562 5.66316C10.5869 5.58679 10.6156 5.5007 10.673 5.32853C10.7304 5.15635 10.7591 5.07026 10.7898 4.9939C11.1818 4.01873 12.1005 3.3566 13.1496 3.29306C13.2318 3.28809 13.3225 3.28809 13.504 3.28809H20.0847C20.2662 3.28809 20.3569 3.28809 20.4391 3.29306C21.4882 3.3566 22.4069 4.01873 22.7989 4.9939C22.8296 5.07026 22.8583 5.15635 22.9157 5.32853C22.9731 5.5007 23.0018 5.58679 23.0325 5.66316C23.4245 6.63832 24.3431 7.30046 25.3922 7.36399C25.4744 7.36897 25.5712 7.36897 25.7647 7.36897C26.2669 7.36897 26.5181 7.36897 26.7188 7.38885C28.6544 7.58063 30.1856 9.11187 30.3774 11.0475C30.3973 11.2482 30.3973 11.4864 30.3973 11.963V22.6043C30.3973 24.8898 30.3973 26.0325 29.9525 26.9055C29.5613 27.6733 28.937 28.2976 28.1691 28.6889C27.2961 29.1337 26.1534 29.1337 23.8679 29.1337H9.72082C7.43531 29.1337 6.29256 29.1337 5.41961 28.6889C4.65174 28.2976 4.02744 27.6733 3.6362 26.9055C3.19141 26.0325 3.19141 24.8898 3.19141 22.6043V11.963Z"
                                    stroke="#CFDBD5"
                                    strokeWidth="2.59653"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M16.7943 23.0123C19.7994 23.0123 22.2355 20.5763 22.2355 17.5712C22.2355 14.5661 19.7994 12.13 16.7943 12.13C13.7893 12.13 11.3532 14.5661 11.3532 17.5712C11.3532 20.5763 13.7893 23.0123 16.7943 23.0123Z"
                                    stroke="#CFDBD5"
                                    strokeWidth="2.59653"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </g>
                              </g>
                            </svg>
                          </span>
                        )}

                        <input
                          className="absolute inset-0 w-full h-full opacity-0 appearance-none cursor-pointer"
                          type="file"
                          accept=".jpg,.jpeg,.png"
                          onChange={createMedia}
                        />
                      </div>
                      <div
                        className={`relative flex justify-center items-center text-center px-5 w-full aspect-[16/4.5] border-x-2 border-b-2 border-dotted border-[#CFDBD5] ${
                          i !== 0 ? "opacity-50 pointer-events-none" : ""
                        }`}
                      >
                        <span className="text-cs-gray text-xs leading-[18px]">
                          Or Choose from gallery
                        </span>

                        <button
                          className={`absolute inset-0 w-full h-full opacity-0 appearance-none cursor-pointer`}
                          type="button"
                          onClick={() => setShowGalleryModal(true)}
                        />
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </fieldset>

          {getShowCropModal && (
            <ModalWrapper
              closeOnOverlay={false}
              className="w-[616px] min-h-cs-394 px-0 sm:px-10 xl:px-0"
              open={getShowCropModal}
              setOpen={setShowCropModal}
              onClose={cancelCrop}
            >
              <h3 className="mb-3 text-2xl font-bold text-cs-gray">
                Crop Image
              </h3>

              <ImageCropper
                file={fields[croppingImage ?? 0]?.url ?? ""}
                stencilAspectRatio={16 / 9}
                onAccept={cropImage}
                onCancel={cancelCrop}
              />
            </ModalWrapper>
          )}

          {getShowGalleryModal}
          {getShowGalleryModal && (
            <ModalWrapper
              className="w-[616px] min-h-cs-394 px-0 sm:px-10 xl:px-0"
              open={getShowGalleryModal}
              setOpen={setShowGalleryModal}
              onClose={() => {
                setGalleryAttraction("");
                setGalleryMedia([]);
              }}
            >
              <h3 className="mb-3 text-2xl font-bold text-cs-gray">Gallery</h3>

              <div className="w-full">
                <div>
                  <label className="text-black text-sm font-bold">
                    Search attractions
                  </label>
                  <AttractionSearch
                    value={""}
                    defaultOptions={defaultAttractions}
                    onChange={(value) => setGalleryAttraction(value)}
                    onMediaChange={(value) => setGalleryMedia(value)}
                  />
                </div>

                <div>
                  {!galleryAttraction ? (
                    <div className="text-cs-gray text-sm text-center py-10">
                      Please select an attraction first
                    </div>
                  ) : (
                    <>
                      {galleryMedia.length === 0 ? (
                        <div className="text-cs-gray text-sm text-center py-10">
                          No media found
                        </div>
                      ) : (
                        <div className="grid grid-cols-3 gap-5 py-5">
                          {galleryMedia.map((media) => (
                            <button
                              type="button"
                              className="border-cs-gray border rounded-lg"
                              onClick={() => {
                                setInGalleryMedia(v => [...v, media.id])
                                selectGalleryImage(media)
                              }}
                            >
                              <img
                                src={media.url}
                                alt=""
                                className="rounded-lg min-h-[90px] bg-slate-100"
                              />
                            </button>
                          ))}
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </ModalWrapper>
          )}

          <PrimaryButton
            type="submit"
            loading={isLoading || uploadingMedia}
            scale="sm"
          >
            {isLoading || uploadingMedia ? (
              <>
                <span className="flex justify-center">
                  <svg
                    className="w-5 h-5 mr-3 animate-spin text-black"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  Uploading
                </span>
              </>
            ) : !isEdit ? (
              "Add Ad Placement"
            ) : (
              "Save Changes"
            )}
          </PrimaryButton>
          {isSubmitted && !isValid && (
            <ErrorMessage>
              You have some errors! Scroll up to view & fix
            </ErrorMessage>
          )}

          {error && "data" in error && (
            <ErrorMessage>
              {error?.data?.message?.replaceAll('"', "") ?? ""}
            </ErrorMessage>
          )}
        </form>
      </SingleAttractionTemplate>

      <Footer />
    </div>
  );
};

export default AddAdPlacement;

const Media: React.FC<{
  className?: string;
  index: number;
  url: string;
  filename: string;
  size: number;
  createdAt: Date;
  isPrimary: boolean;
  checked: boolean;
  togglePrimary: (index: number) => void;
  remove: (index: number) => void;
  toggleSelect: (index: number) => void;
  inGallery: boolean;
}> = ({ className, index, url, filename, checked, remove, inGallery }) => {
  const [deleteModal, setDeleteModal] = useState<boolean>(false);

  return (
    <>
      <div className={`flex flex-col ${className ?? ""}`}>
        <div className="relative flex mt-2">
          <div className="absolute flex justify-between w-full p-4">
            {/* <input
              type="checkbox"
              checked={checked}
              onChange={() => toggleSelect(index)}
            /> */}

            <span className="text-sm">
              <button
                className="text-red-700"
                type="button"
                onClick={() => setDeleteModal(true)}
              >
                <svg
                  width="20"
                  height="23"
                  viewBox="0 0 20 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.90625 4.20508V2.14258H13.0938V4.20508H6.90625ZM4.84375 4.20508V1.45508C4.84375 1.09041 4.98862 0.740669 5.24648 0.482806C5.50434 0.224944 5.85408 0.0800781 6.21875 0.0800781L13.7812 0.0800781C14.1459 0.0800781 14.4957 0.224944 14.7535 0.482806C15.0114 0.740669 15.1562 1.09041 15.1562 1.45508V4.20508H18.5938C18.8673 4.20508 19.1296 4.31373 19.323 4.50712C19.5163 4.70052 19.625 4.96282 19.625 5.23633C19.625 5.50983 19.5163 5.77214 19.323 5.96553C19.1296 6.15893 18.8673 6.26758 18.5938 6.26758H18.0919L17.0702 19.5405C17.0172 20.2313 16.7054 20.8767 16.1971 21.3476C15.6887 21.8184 15.0214 22.08 14.3285 22.0801H5.6715C4.97861 22.08 4.31127 21.8184 3.80294 21.3476C3.29461 20.8767 2.98276 20.2313 2.92975 19.5405L1.90813 6.26758H1.40625C1.13275 6.26758 0.870443 6.15893 0.677046 5.96553C0.483649 5.77214 0.375 5.50983 0.375 5.23633C0.375 4.96282 0.483649 4.70052 0.677046 4.50712C0.870443 4.31373 1.13275 4.20508 1.40625 4.20508H4.84375ZM3.9775 6.26758H16.0225L15.0133 19.3823C15.0001 19.555 14.9223 19.7163 14.7953 19.8341C14.6684 19.9519 14.5017 20.0174 14.3285 20.0176H5.6715C5.49834 20.0174 5.33163 19.9519 5.20468 19.8341C5.07774 19.7163 4.99991 19.555 4.98675 19.3823L3.9775 6.26758Z"
                    fill="currentColor"
                  />
                </svg>
              </button>
            </span>
          </div>

          <img
            className="w-full aspect-[16/9] object-cover rounded-sm"
            src={url}
            alt={filename}
          />
        </div>
      </div>

      <ModalWrapper
        className="w-[500px] px-10 xl:px-0"
        open={deleteModal}
        setOpen={setDeleteModal}
      >
        <h3 className="text-2xl font-bold text-cs-gray">
          {inGallery ? "Remove Image" : "Delete Image"}
        </h3>
        {!inGallery ? (
          <>
            <p className="text-base font-normal my-7 text-cs-gray">
              You are about to delete a image from your gallery.
            </p>
            <h4 className="text-lg font-bold text-cs-gray">Are you sure?</h4>
          </>
        ) : (
          <p className="text-base font-normal my-7 text-cs-gray">
            Are you sure?
          </p>
        )}

        <div className="flex flex-row mt-10">
          <button
            className="px-6 py-2 mx-auto text-sm text-white border-2 h-cs-55 rounded-2xl bg-cs-pink border-cs-pink hover:bg-white hover:text-cs-pink"
            onClick={() => remove(index)}
          >
            {inGallery ? "Yes, Remove it" : "Yes, Delete it"}
          </button>

          <button
            className="ml-20 text-sm text-cs-gray hover:text-cs-pink hover:underline"
            onClick={() => {
              setDeleteModal(false);
            }}
          >
            {inGallery ? "No, Don't remove it" : "No, Don't delete it"}
          </button>
        </div>
      </ModalWrapper>
    </>
  );
};
