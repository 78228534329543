import React, { useState, useEffect, useRef, useCallback } from "react";
import Select, { ActionMeta, MultiValue, components } from "react-select";
import AsyncSelect from "react-select/async";
import singleSelectedOptionWithMultiSelect from "../../lib/react-select/singleSelectedOptionWithMultiSelect";
import { useWindowResize } from "../../hooks/useWindowResize";
import { apiSlice } from "../../store/apiSlice";
import { useAppSelector } from "../../store";
import { debounce } from "../../helpers/debounce";

interface IAttractionsDropdown {
  value: any;
  onChange: (newValue: MultiValue<any>, actionMeta: ActionMeta<any>) => void;
  getUserAttractions: any;
  isFetching: boolean;
  isLoadingAttractions: any;
  triggerOptions: any;
}

const AttractionsDropdown: React.FC<IAttractionsDropdown> = ({
  onChange,
  value,
  getUserAttractions,
  isFetching,
  isLoadingAttractions,
  triggerOptions,
}) => {
  const windowSize = useWindowResize();
  const { user } = useAppSelector((state) => state.global);

  const [searchedValue, setSearchedValue] = useState("");
  const [page, setPage] = useState(1);
  const [trigger] = apiSlice.useLazyGetUserAttractionsQuery();
  const [allOptions, setOptions] = useState<
    {
      value: string;
      label: string;
    }[]
  >([]);

  const appendUnique = (
    toAppend: AttractionDetails[],
    all: {
      value: string;
      label: string;
    }[]
  ) => {
    return (Array.isArray(toAppend) ? toAppend : [])
      .map((a) => ({ value: a.id as string, label: a.name ?? "" }))
      .filter((a) => !all.map((v) => v.value).includes(a.value as string));
  };

  const loadOptions = (
    inputValue: string,
    callback: (
      options: {
        value: string;
        label: string;
      }[]
    ) => void
  ) => {
    trigger({
      ...triggerOptions,
      query: inputValue,
    })
      .unwrap()
      .then((data) => {
        const options = [
          ...allOptions,
          ...appendUnique(data?.results ?? [], allOptions),
        ].sort((a, b) => a.label.localeCompare(b.label));
        setOptions(options);
        callback(
          (data?.results ?? []).map((a) => ({
            value: a.id as string,
            label: a.name ?? "",
          }))
        );
      });
  };

  const loadOptionsDebounced = useCallback(
    debounce((inputValue: string, callback: (options: any) => void) => {
      loadOptions(inputValue, callback);
    }, 1000),
    []
  );

  const [loadingPage, setLoadingPage] = useState(false);
  const loadAnotherPage = () => {
    if (
      !getUserAttractions ||
      getUserAttractions.results.length >= getUserAttractions.total ||
      allOptions >= getUserAttractions.total ||
      !!searchedValue
    ) {
      return;
    }
    setLoadingPage(true);
    trigger({
      ...triggerOptions,
      page: page + 1,
    })
      .unwrap()
      .then((data) => {
        const options = [
          ...allOptions,
          ...appendUnique(data?.results ?? [], allOptions),
        ];
        setOptions(options);
        setPage(page + 1);
      })
      .finally(() => {
        setLoadingPage(false);
      });
  };

  useEffect(() => {
    if (getUserAttractions) {
      setOptions((current) => [
        ...current,
        ...appendUnique(getUserAttractions?.results ?? [], current),
      ]);
    }
  }, [getUserAttractions, setOptions]);
  return (
    <>
      {user?.role === "ADMIN" ? (
        <AsyncSelect
          isLoading={loadingPage}
          loadOptions={loadOptionsDebounced as typeof loadOptions}
          cacheOptions
          value={value}
          onInputChange={setSearchedValue}
          onChange={onChange}
          isMulti
          isClearable={undefined}
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          components={{
            Option: singleSelectedOptionWithMultiSelect,
            ClearIndicator: ClearIndicator,
            DropdownIndicator: () => (
              <div className="px-2.5">
                <svg
                  width="10"
                  height="6"
                  viewBox="0 0 10 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.55469 0.353516L5 3.79883L8.44531 0.353516L9.5 1.4082L5 5.9082L0.5 1.4082L1.55469 0.353516Z"
                    fill="black"
                  />
                </svg>
              </div>
            ),
            LoadingIndicator: () => <LoadingIndicator />,
            IndicatorSeparator: () => null,
          }}
          placeholder={`Search...`}
          isSearchable={true}
          noOptionsMessage={() => (isFetching ? "Loading..." : "No Options")}
          defaultOptions={allOptions}
          // controlShouldRenderValue={false}
          onMenuScrollToBottom={loadAnotherPage}
          styles={{
            multiValue: (styles) => ({
              ...styles,
              backgroundColor: "#F1F1F1",
              borderRadius: 8,
              height: isLoadingAttractions ? 0 : 31,
              display: isLoadingAttractions ? "none" : "flex",
              alignItems: "center",
            }),
            multiValueRemove: () => ({
              display: "none",
            }),
            multiValueLabel: (styles) => ({
              ...styles,
              color: "#000",
              fontSize: 14,
              fontWeight: 400,
              display: isLoadingAttractions ? "none" : "block",
              paddingLeft: isLoadingAttractions ? 0 : 10,
              paddingRight: isLoadingAttractions ? 0 : 10,
            }),
            menu: (styles) => ({
              ...styles,
              margin: 0,
              paddingLeft: 0,
              border: "1px solid #CFDBD5",
              boxShadow: "none",
            }),
            menuList: (base) => ({
              ...base,
              marginTop: 0,
              marginBottom: 0,

              "::-webkit-scrollbar": {
                width: 14,
              },
              "::-webkit-scrollbar-thumb": {
                background: "#c2c2c2",
                borderRadius: 10,
                border: "4px solid white",
              },
            }),
            noOptionsMessage: (base) => ({
              ...base,
              color: "#555",
            }),
            placeholder: (base) => ({
              ...base,
              fontSize: 14,
              paddingLeft: 0,
              fontWeight: 400,
              color: "#555",
            }),
            control: (styles, state) => ({
              ...styles,
              position: "relative",
              cursor: "pointer",
              outline: "none",
              border: state.isFocused
                ? "1px solid #CFDBD5"
                : "1px solid #CFDBD5",
              "&:hover": {
                border: state.isFocused
                  ? "1px solid #CFDBD5"
                  : "1px solid #CFDBD5",
              },
              boxShadow: "none",
              paddingTop: 0,
              paddingRight: 8,
              paddingLeft: 5,
              paddingBottom: 0,
              minHeight: 36,
              borderRadius: 8,
              width: windowSize?.width! >= 1280 ? 186 : "100%",
            }),
            input: (styles, state) => ({
              ...styles,
              position: "absolute",
              top: 0,
              left: 0,
              height: "100%",
            }),
          }}
          theme={(theme) => ({
            ...theme,
            background: "none",
            colors: {
              ...theme.colors,
              neutral80: "#555",
            },
          })}
        />
      ) : (
        <Select
          value={value}
          onChange={onChange}
          isMulti
          isClearable={undefined}
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          options={getUserAttractions?.results
            .map((option: any) => ({ value: option.id, label: option.name }))
            .sort((a: { label: string }, b: { label: string }) =>
              a.label.localeCompare(b.label)
            )}
          components={{
            Option: singleSelectedOptionWithMultiSelect,
            ClearIndicator: ClearIndicator,
            DropdownIndicator: () => (
              <div className="px-2.5">
                <svg
                  width="10"
                  height="6"
                  viewBox="0 0 10 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.55469 0.353516L5 3.79883L8.44531 0.353516L9.5 1.4082L5 5.9082L0.5 1.4082L1.55469 0.353516Z"
                    fill="black"
                  />
                </svg>
              </div>
            ),
            MultiValue: () => null,
            LoadingIndicator: () => <LoadingIndicator />,
          }}
          placeholder={`Search...`}
          isSearchable={true}
          noOptionsMessage={() => (isFetching ? "...Loading" : "No Options")}
          controlShouldRenderValue={false}
          styles={{
            multiValue: (styles) => ({
              ...styles,
              backgroundColor: "",
              borderRadius: 20,
              height: isLoadingAttractions ? 0 : 40,
              display: isLoadingAttractions ? "none" : "flex",
              alignItems: "center",
            }),

            multiValueRemove: () => ({
              display: "none",
            }),
            multiValueLabel: (styles) => ({
              ...styles,
              color: "#555",
              fontSize: 14,
              fontWeight: 400,
              display: isLoadingAttractions ? "none" : "block",
              paddingLeft: isLoadingAttractions ? 0 : 0,
              paddingRight: isLoadingAttractions ? 0 : 0,
            }),
            menu: (styles) => ({
              ...styles,
              margin: 0,
              paddingLeft: 0,
              border: "1px solid #CFDBD5",
              boxShadow: "none",
            }),
            menuList: (base) => ({
              ...base,
              marginTop: 0,
              marginBottom: 0,

              "::-webkit-scrollbar": {
                width: 14,
              },
              "::-webkit-scrollbar-thumb": {
                background: "#c2c2c2",
                borderRadius: 10,
                border: "4px solid white",
              },
            }),
            noOptionsMessage: (base) => ({
              ...base,
              color: "#555",
            }),
            placeholder: (base) => ({
              ...base,
              fontSize: 14,
              paddingLeft: 0,
              fontWeight: 400,
              color: "#555",
            }),
            control: (styles, state) => ({
              ...styles,
              cursor: "pointer",
              outline: "none",
              border: state.isFocused
                ? "1px solid #CFDBD5"
                : "1px solid #CFDBD5",
              "&:hover": {
                border: state.isFocused
                  ? "1px solid #CFDBD5"
                  : "1px solid #CFDBD5",
              },
              boxShadow: "none",
              paddingTop: 0,
              paddingRight: 8,
              paddingLeft: 5,
              paddingBottom: 0,
              height: 36,
              width: windowSize?.width! >= 1280 ? 300 : "100%",
              borderRadius: 8,
            }),
            valueContainer: (styles, state) => ({
              ...styles,
              height: 36,
            }),
          }}
          theme={(theme) => ({
            ...theme,
            background: "none",
            colors: {
              ...theme.colors,
              neutral80: "#6836D1",
            },
          })}
        />
      )}
    </>
  );
};

const MultiValueComponent = (props: any) => {
  const { getValue } = props;
  const values = getValue();
  const options = props.selectProps.options;
  let valueLabel = [];

  if (values.length === 1) {
    valueLabel = values.map((value: string) =>
      props.selectProps.getOptionLabel(value)
    );
  } else if (values.length === options.length) {
    valueLabel = ["All selected"];
  } else {
    valueLabel = [values.length + " Selected"];
  }

  if (values.length === 0) {
    valueLabel = [];
  }

  return props.index === 0 ? (
    <components.MultiValue {...props} children={valueLabel} />
  ) : (
    <></>
  );
};

export default AttractionsDropdown;

const LoadingIndicator: React.FC = () => {
  return (
    <svg
      className="animate-spin -ml-1 mr-3 h-5 w-5 text-cs-pink"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      ></circle>
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  );
};

const ClearIndicator: React.FC = (props: any) => {
  const { selectProps } = props;
  return (
    <components.ClearIndicator {...props}>
      <span
        className={`text-cs-gray pr-2 text-lg ${
          selectProps.menuIsOpen ? "filter-to-pink-focused" : ""
        } hover:text-cs-pink`}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className="w-4 h-4"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </span>
    </components.ClearIndicator>
  );
};
