import Header from "../../../components/global/amp/Header";
import Footer from "../../../components/global/amp/Footer";
import { z } from "zod";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm, Controller, useWatch } from "react-hook-form";
import TextInput from "../../../components/form/TextInput";
import {
  Link,
  createSearchParams,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import AsyncSelect from "react-select/async";
import SingleSelectOption from "../../../lib/react-select/SingleSelectOption";
import { apiSlice } from "../../../store/apiSlice";
import { useAppDispatch, useAppSelector } from "../../../store";
import { restartAnimation } from "../../../components/global/amp/ToastNotification";
import { globalActions } from "../../../store/globalSlice";
import { UnsavedModal } from "../../../components/global/amp/UnsavedModal";
import { isValidURL } from "../../../helpers/isValidUrl";
import { type AmpUserDetail } from "../../../types/user";
import { type iPendingClaims } from "../../../components/global/ClaimModal";
import moment from "moment";
import { ErrorMessage } from "../../../components/global/ErrorMessage";
import { SingleAttractionTemplate } from "../../../components/global/SingleAttractionTemplate";
import BooleanSelect from "../../../components/form/BooleanSelect";
import { unlockNewAttractionTab } from "../../../helpers/newAttractionsTabPermission";
import Stepper from "../../../components/amp/Stepper";
import { filterPayload } from "../../../helpers/filterPayload";
import { RevertToSavedModal } from "../../../components/global/RevertToSavedModal";
import { SaveButtonsEditAttraction } from "../../../components/amp/SaveButtonsEditAttraction";
import { SubscriptionSection } from "./AttractionSubscription";
import Select from "react-select";
import {
  LightBulb,
  LightBulbTooltip,
  LightningIcon,
} from "../../../components/amp/LightBulbTooltip";
import { walkthroughActions } from "../../../store/walkthroughSlice";
import {
  clearNewAttractionStepLeftOver,
  getNewAttractionWhichStepLeftOver,
  setNewAttractionLeftOverTab,
} from "../../../helpers/newAttractionsFinishLater";
import { DropDownIndicator } from "./AttractionOpeningTimes";
import { OutlineButton } from "../../../components/global/OutlineButton";
import { PrimaryButton } from "../../../components/global/PrimaryButton";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../../components/global/Tooltip";
import {
  getVoScoreTooltip,
  useVoScoreNeedImprovementsFields,
} from "../../../hooks/useVoScoreNeedImprovementsFields";
interface AttractionGeneralForm {
  displayName: string;
  pageTitle: string;
  phoneNumber: string;
  noPhoneNumber: boolean;
  websiteAddress: string;
  listingPackage: AttractionDetails["listing_package"];
  operator: number | string | null;
  listingType: "attraction" | "event" | "club" | "festival";

  bookCta: string;
  bookCtaLink: boolean;
  hideTicketing: boolean;
  show_in_description_tickets_cta: boolean;
  meta_description: string;
}

const packagesOptions: {
  value: AttractionDetails["listing_package"];
  label: string;
}[] = [
  { value: "BASIC", label: "Basic" },
  { value: "STANDARD", label: "Standard" },
  { value: "ENHANCED", label: "Enhanced" },
  { value: "PREMIUM", label: "Premium" },
];

const AttractionGeneral: React.FC = () => {
  const { user } = useAppSelector((state) => state.global);
  const canEditListing = ["ADMIN"].includes(user?.role ?? "");
  const canEditOperator = ["ADMIN"].includes(user?.role ?? "");

  const { toastNotification } = useAppSelector((state) => state.global);
  const dispatch = useAppDispatch();

  const [getShowRevertModal, setShowRevertModal] = useState<boolean>(false);
  const { attractionId } = useParams();
  const {
    data: getAttraction,
    isSuccess: isGetSuccess,
    isFetching,
    refetch,
    error: attractionError,
  } = apiSlice.useGetAttractionQuery(attractionId ?? "");
  const { result: attractionDetails } = getAttraction ?? {};
  const { data: operatorOptionsData } = apiSlice.useGetOperatorsQuery();
  const [trigger] = apiSlice.useLazyGetOperatorsQuery();

  const [updateAttraction, { isLoading, isError, isSuccess: isUpdateSuccess }] =
    apiSlice.useUpdateAttractionDraftMutation();

  const schema = z
    .object({
      displayName: z
        .string()
        .min(1, "Display name is required")
        .max(255, "Display name is too long"),
      pageTitle: z.string().nullable(),
      meta_description: z.string().nullable(),
      bookCta: z.string().nullable(),
      bookCtaLink: z.boolean(),
      hideTicketing: z.boolean(),
      show_in_description_tickets_cta: z.boolean(),
      websiteAddress: z
        .string()
        .min(1, "Website address is required")
        .max(255, "Website is too long")
        .superRefine((value, ctx) => {
          if (!isValidURL(value)) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: `Please enter the url with correct format (e.g. example.com)`,
            });
          }
        }),
      phoneNumber: z.string(),
      noPhoneNumber: z.boolean(),
      listingPackage: z.enum(["BASIC", "ENHANCED", "PREMIUM", "STANDARD"]),
      operator: z.string().nullable(),
      listingType: z.enum(["attraction", "event", "club", "festival"]),
    })
    .superRefine((value, ctx) => {
      if (!value.noPhoneNumber) {
        if (value.phoneNumber.length < 10) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Phone number is required",
            path: ["phoneNumber"],
          });
        }
        if (
          !new RegExp(
            /^(\+44\s?|\(0\d{4}\)\s?|\(0\d{3}\)\s?|\(0\d{2}\)\s?|\d{4}\s?|\d{3}\s?|\d{2}\s?)\d{3}\s?\d{4}(\s?\#\d{3,4})?$/
          ).test(value.phoneNumber)
        ) {
          ctx.addIssue({
            message:
              "Invalid phone number. It should be at least 10 digits with no spaces",
            code: z.ZodIssueCode.custom,
            path: ["phoneNumber"],
          });
        }
      }
    });

  const {
    register,
    handleSubmit,
    formState: { isValid, isSubmitted, errors, isDirty, dirtyFields },
    control,
    setValue,
    reset,
    getValues,
    trigger: triggerValidation,
  } = useForm<AttractionGeneralForm>({
    resolver: zodResolver(schema),
  });
  const [isPublishing, setIsPublishing] = useState(false);
  const [stepsFields] = useState<(keyof AttractionGeneralForm)[][]>([
    ["displayName", "phoneNumber", "noPhoneNumber", "websiteAddress"],
    ["listingType", "listingPackage"],
  ]);
  const [stepsPostFields] = useState<string[][]>([
    ["name", "phone", "no_phone_number", "website"],
    ["listing_type", "listing_package"],
  ]);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const currentStep = stepsFields[currentStepIndex] ?? [];
  const currentPostStep = stepsPostFields[currentStepIndex] ?? [];
  const isStepperActive =
    user?.role !== "ADMIN" && !attractionDetails?.finished_onboarding;

  const canChangeDisplayName =
    (attractionDetails?.creation_claim?.status === "NEW" &&
      !attractionDetails?.published_at &&
      !attractionDetails?.approved) ||
    user?.role === "ADMIN";

  const getPayload = () => {
    const payload: Record<string, string | number | boolean> = {
      website: getValues("websiteAddress"),
      no_phone_number: getValues("noPhoneNumber"),
    };
    if (!getValues("noPhoneNumber")) {
      payload.phone = getValues("phoneNumber");
    }

    if (user?.role === "ADMIN") {
      payload.title = getValues("pageTitle") ?? "";
      payload.meta_description = getValues("meta_description") ?? "";
      payload.book_cta_text = getValues("bookCta") ?? "";
      payload.book_cta_link_website = !!getValues("bookCtaLink") ?? false;
      payload.hide_ticketing_widget = !!getValues("hideTicketing") ?? false;
      payload.show_in_description_tickets_cta =
        !!getValues("show_in_description_tickets_cta") ?? false;
      payload.listing_type = getValues("listingType");
    }

    if (canChangeDisplayName) {
      payload.name = getValues("displayName").trim();
    }

    if (canEditListing && getValues("listingPackage")) {
      payload.listing_package = getValues("listingPackage");
    }

    if (canEditOperator && getValues("operator")) {
      payload.operator_id = getValues("operator") as number;
    }
    return payload;
  };

  const onSubmit = (publish: boolean = false, isSilent: boolean = false) => {
    const fn: SubmitHandler<AttractionGeneralForm> = async (e) => {
      setIsPublishing(publish);

      const payload = filterPayload(
        getPayload(),
        currentPostStep,
        isStepperActive
      );

      const results = await updateAttraction({
        id: attractionId,
        publish,
        payload: payload,
      });
      if (!isSilent) {
        restartAnimation();
      }

      return "data" in results;
    };

    return fn;
  };

  const filterSelectedOperators = (
    defaultOptions: { label: string; value: number }[]
  ): { label: string; value: number }[] => {
    return defaultOptions.sort((a, b) => a.label.localeCompare(b.label));
  };

  const loadOptions = (
    inputValue: string,
    callback: (options: { value: number; label: string }[]) => void
  ) => {
    trigger({ query: inputValue })
      .unwrap()
      .then((data) => {
        callback(
          filterSelectedOperators(
            data?.results.map((d) => ({
              value: d.id ?? -1,
              label: d.title,
            })) ?? []
          )
        );
      });
  };

  const operatorOptions: { value: number; label: string }[] =
    operatorOptionsData
      ? operatorOptionsData.results.map((o) => {
          return {
            value: o.id ?? -1,
            label: o.title,
          };
        })
      : [];

  const selectedOperator: { value: number; label: string } | null =
    attractionDetails?.operator
      ? {
          value: attractionDetails?.operator.id,
          label: attractionDetails?.operator.title,
        }
      : null;

  const formatDate = (date: string | Date | null | undefined) => {
    if (!date) return "-";
    return moment(new Date(date)).format("DD/MM/YYYY HH:mm:ss");
  };
  const getFullName = (
    value:
      | { first_name: string; last_name: string; email: string }
      | null
      | undefined
  ) => {
    if (!value) return "-";

    if (!value.first_name && !value.last_name) return value.email || "-";
    return `${value.first_name} ${value.last_name}`;
  };

  useEffect(() => {
    document.title = "Attraction Overview | Day Out With The Kids";
  }, []);

  useEffect(() => {
    if (attractionDetails && isGetSuccess && !isFetching) {
      setValue("displayName", (attractionDetails?.name ?? "").trim());
      setValue("pageTitle", attractionDetails?.title ?? "");
      setValue("meta_description", attractionDetails?.meta_description ?? "");
      setValue("bookCta", attractionDetails?.book_cta_text ?? "");
      setValue(
        "bookCtaLink",
        !!attractionDetails?.book_cta_link_website ?? false
      );
      setValue(
        "hideTicketing",
        !!attractionDetails?.hide_ticketing_widget ?? false
      );
      setValue(
        "show_in_description_tickets_cta",
        !!attractionDetails.show_in_description_tickets_cta ?? false
      );
      setValue("websiteAddress", attractionDetails?.website ?? "");
      setValue("phoneNumber", attractionDetails?.phone ?? "");
      setValue("noPhoneNumber", attractionDetails?.no_phone_number || false);
      setValue("listingPackage", attractionDetails?.listing_package ?? "BASIC");
      setValue("operator", attractionDetails?.operator_id ?? null);
      setValue("listingType", attractionDetails?.listing_type ?? "attraction");

      setShowRevertModal(false);

      reset(
        {
          ...getValues(),
        },
        {
          keepDirty: false,
        }
      );
    }
  }, [attractionDetails, isFetching]);

  const bypassQueue =
    user?.role === "ADMIN" ||
    ["ENHANCED", "PREMIUM"].includes(attractionDetails?.listing_package ?? "");
  useEffect(() => {
    if (isUpdateSuccess) {
      reset({}, { keepValues: true });
      dispatch(
        globalActions.setToastNotifcation({
          ...toastNotification,
          type: "SUCCESS",
          message: isPublishing
            ? bypassQueue
              ? "Published"
              : "Submitted for approval"
            : "Your changes have been saved",
          attractionApprovalStatus: "",
          attractionImage: "",
          attractionName: "",
          attractionAddress: "",
        })
      );
    }
  }, [isUpdateSuccess]);

  useEffect(() => {
    isError &&
      dispatch(
        globalActions.setToastNotifcation({
          ...toastNotification,
          type: "ERROR",
          message: isPublishing
            ? "Cannot publish attraction"
            : "Cannot save changes",
          attractionApprovalStatus: "",
          attractionImage: "",
          attractionName: "",
          attractionAddress: "",
        })
      );
  }, [isError]);

  const navigate = useNavigate();
  useEffect(() => {
    if (
      attractionError &&
      (attractionError as unknown as { status: number })?.status === 403
    ) {
      navigate("/forbidden", { replace: true });
    }
  }, [attractionError]);

  // We show the creation_claim details if attraction was created by a user and they are also still the current owner
  const showCreationClaim =
    attractionDetails?.creation_claim &&
    attractionDetails.ownership_claim &&
    attractionDetails.creation_claim.created_by ==
      attractionDetails.ownership_claim.created_by;

  const location = useLocation();
  const claimData = (location?.state?.claim ?? undefined) as
    | {
        created_by_user?: AmpUserDetail;
        id: number;
        claimed: boolean;
        operator: {
          title: string;
          id: number;
        };
        created_at: string;
        new: string;
      }
    | undefined;

  const [getShowClaimModal, setShowClaimModal] = useState<
    iPendingClaims | undefined
  >(undefined);
  const [showDetails, setShowDetails] = useState(false);

  function revealClaimModal() {
    setShowClaimModal({
      alreadyClaimed: true,
      operatorTitle: attractionDetails?.operator.title,
      owners: (attractionDetails?.operator as any).owners.filter(
        (owner: any) => owner.role === "OPERATOR_OWNER"
      ),
      managedByUsers: (attractionDetails as any)?.managed_by_users?.filter(
        (owner: any) => owner.role === "ATTRACTION_MANAGER"
      ),
      show: true,
      id: claimData?.id,
      primaryImage:
        attractionDetails?.display_image?.cloudinary_url ??
        attractionDetails?.display_image?.url ??
        "/assets/amp/dowtk-mono.png",
      name: attractionDetails?.name,
      address: attractionDetails?.address,
    });
  }

  function urlify(url: string) {
    if (!url) return "No Website URL";
    const prefix =
      url.startsWith("http://") || url.startsWith("https://") ? "" : "https://";
    return new URL(`${prefix}${url}`).toString();
  }

  const { walkthroughStatus, walkthroughType } = useAppSelector(
    (state) => state.walkthrough
  );
  const openLightBulbExample =
    walkthroughType === "editAttraction" && walkthroughStatus === "tooltips";

  useEffect(() => {
    if (
      user?.role !== "ADMIN" &&
      attractionDetails?.done_walkthrough &&
      !attractionDetails?.edit_done_walkthrough &&
      !isStepperActive
    ) {
      // window.scrollTo(0, 0);
      // dispatch(walkthroughActions.setWalkthroughType("editAttraction"));
      // dispatch(walkthroughActions.setWalkthroughStatus("startModal"));
    }

    return () => {
      // dispatch(walkthroughActions.setWalkthroughStatus("none"));
    };
  }, [attractionDetails, dispatch, isStepperActive]);
  useEffect(() => {
    return () => {
      dispatch(walkthroughActions.setWalkthroughStatus("none"));
    };
  }, []);

  const stepperStep = useState(0);
  useEffect(() => {
    const steps = getNewAttractionWhichStepLeftOver();
    if (steps.step && steps.allSteps) {
      if (steps.allSteps === stepsFields.length) {
        stepperStep[1](steps.step);
        setCurrentStepIndex(steps.step);
      }
      clearNewAttractionStepLeftOver();
    }
  }, []);

  const watchNoPhoneNumber = useWatch({
    control,
    name: "noPhoneNumber",
  });

  const { selectedPlan: globalSelectedPlan, isSelectedPlanCurrent } =
    useAppSelector((state) => state.global);

  const [subscriptionCheckout] = apiSlice.useSubscriptionCheckoutMutation();
  const createSubscriptionCheckout = async (selectedPlanId: string) => {
    if (!selectedPlanId) return;
    const checkoutPage = await subscriptionCheckout({
      venueId: attractionDetails?.published_version?.id || "",
      planId: selectedPlanId,
    });

    if ("data" in checkoutPage) {
      const paymentUrl = checkoutPage?.data?.sessionUrl;
      if (!paymentUrl) return;
      window.location.href = new URL(paymentUrl).toString();
    }
  };

  async function onNextPage() {
    unlockNewAttractionTab(attractionId ?? "", "ATTRACTION_DETAILS");

    if (!isSelectedPlanCurrent && globalSelectedPlan) {
      return createSubscriptionCheckout(globalSelectedPlan?.id);
    }

    await new Promise((res) => setTimeout(res, 500));
    navigate(`/attractions/${attractionId}/details`);
  }

  useEffect(() => {
    dispatch(
      globalActions.setSaveCurrentTabStep({
        attractionId: attractionId ?? "",
        key: "ATTRACTION_GENERAL",
        allSteps: stepsFields.length,
        step: currentStepIndex,
      })
    );
  }, [attractionId, currentStepIndex, stepsFields.length, dispatch]);

  const watchedWebsite = useWatch({
    control,
    name: "websiteAddress",
  });
  const watchedPhone = useWatch({
    control,
    name: "phoneNumber",
  });
  const watchedMetaDescription = useWatch({
    control,
    name: "meta_description",
  });
  const watchedMetaDescriptionCharCount = watchedMetaDescription?.length ?? 0;
  const watchedPageTitle = useWatch({
    control,
    name: "pageTitle",
  });
  const watchedPageTitleCharCount = watchedPageTitle?.length ?? 0;
  const { data: getMetrics } = apiSlice.useGetVenueOptScoreMetricsQuery();
  const voScoreImprovements = useVoScoreNeedImprovementsFields({
    allMetrics: getMetrics?.result || [],
    completedMetrics:
      getAttraction?.result?.published_version?.optimisation_scores || [],
    watched: {
      websiteAddress: watchedWebsite,
      phoneNumber: watchedPhone,
    },
    dirtyFields,
    schema: schema,
    results: attractionDetails,
  })["ATTRACTION_GENERAL"];

  const isClaim =
    attractionDetails?.ownership_claim?.created_by_user?.email !==
    attractionDetails?.creation_claim?.created_by_user?.email;

  return (
    <div className="flex flex-col flex-1 bg-[#F5F5FA] doodle-bg">
      <Header
        title="Edit Attraction"
        mobilePublishButtons={true}
        showPublishingButtons
        triggerValidation={handleSubmit}
        saveFn={onSubmit}
        getPayload={getPayload}
        isDirty={isDirty}
        claimModalState={[getShowClaimModal, setShowClaimModal]}
      />

      <SingleAttractionTemplate
        result={attractionDetails}
        attractionId={attractionId}
        isAttraction={true}
        doneWalkthrough={
          attractionDetails
            ? attractionDetails?.done_walkthrough ?? false
            : undefined
        }
        name={isStepperActive ? attractionDetails?.name ?? "" : ""}
        unstyled={!isStepperActive}
      >
        <form onSubmit={handleSubmit(onSubmit(false))}>
          <Stepper
            controlledStepping={stepperStep}
            isActive={isStepperActive}
            isLoading={isLoading}
            saveFn={onSubmit}
            onStepChange={setCurrentStepIndex}
            triggerValidation={async () => {
              return await triggerValidation(currentStep);
            }}
            onNextPage={onNextPage}
            title={
              <>
                {!isStepperActive && (
                  <h2 className="mb-4 text-[22px] font-extrabold text-black">
                    {attractionDetails?.name}
                  </h2>
                )}
                <h1 className="mb-3 text-[22px] font-extrabold text-black flex items-center gap-2">
                  📄 Overview{" "}
                  {user?.role === "ADMIN" && (
                    <div className="flex gap-2 items-center">
                      <button
                        type="button"
                        onClick={() => setShowDetails((c) => !c)}
                        className="flex items-center gap-[5px] text-[10px] font-normal p-[5px] border border-[#CFDBD5] rounded-lg h-[22px]"
                      >
                        View Details
                        <svg
                          width="8"
                          height="3"
                          viewBox="0 0 8 3"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1.77802 1.41951C1.778 1.53626 1.75497 1.65185 1.71027 1.7597C1.66556 1.86755 1.60005 1.96554 1.51748 2.04807C1.43491 2.1306 1.33689 2.19606 1.22902 2.24071C1.12115 2.28536 1.00554 2.30833 0.88879 2.3083C0.772043 2.30827 0.656446 2.28525 0.548597 2.24054C0.440748 2.19584 0.34276 2.13033 0.260229 2.04776C0.177697 1.96518 0.112237 1.86716 0.067587 1.75929C0.022937 1.65142 -2.91534e-05 1.53581 2.77736e-08 1.41906C5.89617e-05 1.18328 0.093779 0.957183 0.260543 0.790502C0.427307 0.623822 0.653454 0.530215 0.889235 0.530273C1.12502 0.530332 1.35112 0.624052 1.5178 0.790816C1.68448 0.95758 1.77808 1.18373 1.77802 1.41951ZM7.11077 0.530718C6.99402 0.530747 6.87842 0.553771 6.77057 0.598475C6.66272 0.643179 6.56474 0.708688 6.4822 0.791261C6.39967 0.873834 6.33421 0.971854 6.28956 1.07973C6.24491 1.1876 6.22195 1.30321 6.22198 1.41995C6.222 1.5367 6.24503 1.6523 6.28973 1.76015C6.33444 1.86799 6.39995 1.96598 6.48252 2.04851C6.56509 2.13105 6.66311 2.19651 6.77098 2.24116C6.87885 2.28581 6.99446 2.30877 7.11121 2.30874C7.34699 2.30868 7.57309 2.21496 7.73977 2.0482C7.90645 1.88144 8.00006 1.65529 8 1.41951C7.99994 1.18373 7.90622 0.957627 7.73946 0.790946C7.57269 0.624266 7.34655 0.530659 7.11077 0.530718ZM4 0.530718C3.88325 0.530747 3.76766 0.553771 3.65981 0.598475C3.55196 0.643179 3.45397 0.708688 3.37144 0.791261C3.28891 0.873834 3.22345 0.971854 3.1788 1.07973C3.13415 1.1876 3.11118 1.30321 3.11121 1.41995C3.11124 1.5367 3.13426 1.6523 3.17897 1.76015C3.22367 1.86799 3.28918 1.96598 3.37175 2.04851C3.45433 2.13105 3.55235 2.19651 3.66022 2.24116C3.76809 2.28581 3.8837 2.30877 4.00044 2.30874C4.23623 2.30868 4.46233 2.21496 4.62901 2.0482C4.79569 1.88144 4.88929 1.65529 4.88924 1.41951C4.88918 1.18373 4.79546 0.957627 4.62869 0.790946C4.46193 0.624266 4.23578 0.530659 4 0.530718Z"
                            fill="black"
                          />
                        </svg>
                      </button>
                      {attractionDetails?.ownership_claim?.operator && (
                        <button
                          type="button"
                          onClick={() =>
                            navigate({
                              pathname: `/user/${attractionDetails?.ownership_claim?.operator?.id}`,
                              search: createSearchParams({
                                email:
                                  attractionDetails?.ownership_claim?.operator
                                    ?.email || "",
                              }).toString(),
                            })
                          }
                          className="flex items-center gap-[5px] text-[10px] font-normal p-[5px] border border-[#CFDBD5] rounded-lg h-[22px]"
                        >
                          View Owner
                        </button>
                      )}
                      <a
                        href={`https://www.dayoutwiththekids.co.uk/attractions/${attractionDetails?.short_id}`}
                        className="flex items-center gap-[5px] text-[10px] font-normal p-[5px] border border-[#CFDBD5] rounded-lg h-[22px]"
                        target="_blank"
                        rel="noreferrer"
                      >
                        View on DOWTK
                      </a>
                    </div>
                  )}
                </h1>
                {showDetails && user?.role === "ADMIN" && (
                  <fieldset className="inline-flex flex-col flex-wrap w-full md:w-3/4 xl:w-1/2">
                    <div className="bg-[#F1F1F1] p-5 rounded-lg">
                      <Detail title="Added to DOWTK">
                        {formatDate(attractionDetails?.created_at)}
                      </Detail>

                      {showCreationClaim ? (
                        <>
                          <Detail title="Attraction Submitted">
                            {formatDate(
                              attractionDetails?.creation_claim?.created_at
                            )}
                          </Detail>

                          {attractionDetails.creation_claim?.status ===
                            "APPROVED_NEW" && (
                            <Detail title="Attraction approved on">
                              {formatDate(
                                attractionDetails?.creation_claim?.resolved_at
                              )}
                            </Detail>
                          )}
                          <Detail title="Attraction approved by">
                            <Link
                              to={"#"}
                              className={`font-normal cursor-auto text-sm hover:text-cs-pink ${
                                attractionDetails?.creation_claim
                                  ?.resolved_by_user?.id
                                  ? "underline"
                                  : ""
                              }`}
                            >
                              {getFullName(
                                attractionDetails?.creation_claim
                                  ?.resolved_by_user
                              )}
                            </Link>
                          </Detail>
                        </>
                      ) : (
                        <>
                          <Detail title="Claim Submitted">
                            {formatDate(
                              claimData?.created_at ||
                                attractionDetails?.ownership_claim?.created_at
                            )}
                          </Detail>
                          <Detail title="Claim approved on">
                            {formatDate(
                              attractionDetails?.ownership_claim?.resolved_at
                            )}
                          </Detail>

                          <Detail title="Claim approved by">
                            <Link
                              to={"#"}
                              className={`font-normal cursor-auto text-sm hover:text-cs-pink ${
                                attractionDetails?.ownership_claim
                                  ?.resolved_by_user?.id
                                  ? "underline"
                                  : ""
                              }`}
                            >
                              {getFullName(
                                attractionDetails?.ownership_claim
                                  ?.resolved_by_user
                              )}
                            </Link>
                          </Detail>
                        </>
                      )}
                      {!!claimData && (
                        <>
                          <Detail title="Email Verified">
                            {claimData.created_by_user?.email_verified
                              ? "Yes"
                              : "No"}
                          </Detail>

                          {!claimData.new && (
                            <Detail title="Already claimed">
                              {claimData.claimed ? (
                                <button
                                  type="button"
                                  onClick={() => revealClaimModal()}
                                  className="h-8 px-3 text-white bg-cs-red rounded-xl hover:bg-cs-pink"
                                >
                                  Yes
                                </button>
                              ) : (
                                "No"
                              )}
                            </Detail>
                          )}

                          <Detail title="Web / Email match">
                            <div className="relative flex flex-wrap items-center gap-4 lg:flex-nowrap lg:whitespace-nowrap">
                              <a
                                href={
                                  attractionDetails?.website
                                    ? urlify(attractionDetails?.website ?? "")
                                    : "#"
                                }
                                target="_blank"
                                rel="noreferrer"
                                className="block w-10/12 text-sm font-normal leading-none underline text-cs-pink"
                              >
                                {urlify(attractionDetails?.website ?? "")}
                              </a>
                              <span className="block w-10/12 text-sm font-normal text-cs-pink">
                                {claimData?.created_by_user?.email}
                              </span>
                            </div>
                          </Detail>
                        </>
                      )}

                      <Detail title="Listing Info">
                        {isClaim ? "Existing Claim" : "New Attraction"}
                      </Detail>
                      <Detail title="Last Publish Date">
                        {formatDate(attractionDetails?.published_at)}
                      </Detail>
                      <Detail title="Listing Fee Status">
                        {attractionDetails?.published_version
                          ?.has_paid_to_publish
                          ? `Paid - ${formatDate(
                              attractionDetails?.published_version
                                ?.subscriptions?.[0]?.created_at
                            )}`
                          : "-"}
                      </Detail>
                      <Detail title="Last Update Date">
                        {formatDate(attractionDetails?.updated_at)}
                      </Detail>
                      <Detail title="Current VO Score">
                        {attractionDetails?.total_vo_score || 0}/
                        {attractionDetails?.max_possible_vo_score || 100}
                      </Detail>
                    </div>
                  </fieldset>
                )}
              </>
            }
            nonStepperSubmit={
              <SaveButtonsEditAttraction isLoading={isLoading} />
            }
            onBeforeFinishLater={() => {
              setNewAttractionLeftOverTab(
                attractionId ?? "",
                "ATTRACTION_GENERAL",
                currentStepIndex,
                stepsFields.length
              );
            }}
          >
            <Stepper.Step componentType="Step">
              <fieldset
                id="editAttractionFieldsExample"
                className="w-full flex flex-col"
              >
                <div className="w-full md:w-3/4 xl:w-1/2 inline-flex flex-col flex-wrap">
                  <TextInput
                    className="w-full mt-4 group"
                    labelClassName="group-focus-within:text-cs-pink font-bold"
                    inputClassName="border-cs-1 text-cs-gray rounded-md py-2.5 px-5 text-sm flex flex-col w-full focus:outline-cs-pink h-10"
                    control={control}
                    name={"displayName"}
                    label={
                      isStepperActive
                        ? "Attraction Name"
                        : "Attraction Display Name"
                    }
                    readonly={!canChangeDisplayName}
                    required
                    inputProps={{
                      ...register("displayName", {
                        setValueAs: (v) => v.trim(),
                      }),
                    }}
                    requirements=""
                  />
                  {user?.role === "ADMIN" && (
                    <>
                      <div>
                        <h4 className="text-black font-bold text-sm mt-5 flex items-center justify-between gap-1">
                          Page Title{" "}
                          <span
                            className={`text-sm font-bold ${
                              watchedPageTitleCharCount > 65
                                ? "text-red-500"
                                : "text-cs-gray"
                            }`}
                          >
                            {watchedPageTitleCharCount}/ Max 65
                          </span>
                        </h4>
                      </div>
                      <TextInput
                        className="w-full mt-4 group"
                        labelClassName="group-focus-within:text-cs-pink font-bold"
                        inputClassName="border-cs-1 text-cs-gray rounded-md py-2.5 px-5 text-sm flex flex-col w-full focus:outline-cs-pink h-10"
                        control={control}
                        name={"pageTitle"}
                        label=""
                        inputProps={{
                          ...register("pageTitle"),
                        }}
                      />

                      <div>
                        <h4 className="text-black font-bold text-sm mt-5 flex items-center justify-between gap-1">
                          Meta description{" "}
                          <span
                            className={`text-sm font-bold ${
                              watchedMetaDescriptionCharCount > 165
                                ? "text-red-500"
                                : "text-cs-gray"
                            }`}
                          >
                            {watchedMetaDescriptionCharCount}/ Max 165
                          </span>
                        </h4>
                      </div>
                      <TextInput
                        className="w-full mt-4 group"
                        labelClassName="group-focus-within:text-cs-pink font-bold"
                        inputClassName="border-cs-1 text-cs-gray rounded-md py-2.5 px-5 text-sm flex flex-col w-full focus:outline-cs-pink h-10"
                        control={control}
                        name={"meta_description"}
                        label=""
                        inputProps={{
                          ...register("meta_description"),
                        }}
                      />
                    </>
                  )}

                  <label className="mt-4 mb-2 font-bold text-black">
                    Operator
                  </label>
                  <Controller
                    control={control}
                    name={"operator"}
                    render={({
                      field: { ref, value, onChange },
                      fieldState: { error },
                    }) => (
                      <AsyncSelect
                        cacheOptions
                        loadOptions={loadOptions}
                        value={
                          filterSelectedOperators(operatorOptions).find(
                            (o) => o.value === value
                          ) ??
                          selectedOperator ?? {
                            value: null,
                            label: "unassigned",
                          }
                        }
                        defaultOptions={filterSelectedOperators(
                          operatorOptions
                        )}
                        components={{
                          Option: SingleSelectOption,
                          DropdownIndicator: () => null,
                          IndicatorSeparator: () => null,
                          LoadingIndicator: () => <LoadingIndicator />,
                        }}
                        isSearchable={true}
                        isDisabled={!canEditOperator}
                        styles={{
                          menu: (styles) => ({
                            ...styles,
                            margin: 0,
                            border: "none",
                          }),
                          menuList: (base, isSelected) => ({
                            ...base,
                            paddingTop: 0,
                            paddingBottom: 0,
                            background: "white",

                            "::-webkit-scrollbar": {
                              width: 14,
                            },
                            "::-webkit-scrollbar-thumb": {
                              background: "#c2c2c2",
                              borderRadius: 10,
                              border: "4px solid white",
                            },
                          }),
                          option: (
                            base,
                            { data, isDisabled, isFocused, isSelected }
                          ) => {
                            return {
                              ...base,
                              background: "white",
                              border: "1px solid #c2c2c2",
                              color: isSelected ? "white" : "#555",
                              "&:hover": {
                                backgroundColor: "#6836D1",
                                color:
                                  isSelected || isFocused ? "white" : "#555",
                                border: isFocused ? "1px solid white" : "",
                              },
                              "&:active": {
                                backgroundColor: "#6836D1",
                                color: "white",
                              },
                            };
                          },
                          singleValue: (base) => ({
                            ...base,
                            color: "#555",
                            fontSize: 14,
                          }),
                          control: (styles, state) => ({
                            ...styles,
                            cursor: "pointer",
                            outline: "none",
                            border: state.isFocused
                              ? "1px solid #CFDBD5"
                              : "1px solid #CFDBD5",
                            "&:hover": {
                              border: state.isFocused
                                ? "1px solid #CFDBD5"
                                : "1px solid #CFDBD5",
                            },
                            boxShadow: "none",
                            paddingTop: 3,
                            paddingBottom: 3,
                            margin: 0,
                            minHeight: 40,
                            borderRadius: 8,
                          }),
                          valueContainer: (styles) => ({
                            ...styles,
                            paddingRight: 20,
                            paddingLeft: 20,
                            fontSize: 14,
                          }),
                        }}
                        onChange={(value) => onChange(value?.value)}
                      />
                    )}
                  />
                </div>

                {!isStepperActive &&
                  (user?.role === "ADMIN" ? (
                    <div className="w-full md:w-3/4 xl:w-1/2 inline-flex flex-col flex-wrap">
                      <label className="flex items-center mt-6 mb-2 font-bold text-black md:whitespace-nowrap">
                        Listing Plan
                        <Tooltip placement="bottom-start">
                          <TooltipTrigger>
                            <svg
                              className="ml-1"
                              width="12"
                              height="16"
                              viewBox="0 0 12 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M11.7298 7.32871C11.7017 7.2086 11.6444 7.09727 11.563 7.0046C11.4815 6.91193 11.3785 6.84079 11.263 6.79746L7.86047 5.52184L8.73547 1.14684C8.76746 0.986831 8.74639 0.820755 8.67546 0.673803C8.60453 0.52685 8.48761 0.407039 8.34243 0.332543C8.19725 0.258046 8.03174 0.232928 7.871 0.260999C7.71026 0.28907 7.56306 0.368798 7.45172 0.48809L0.451721 7.98809C0.367502 8.07823 0.30682 8.18776 0.275051 8.30696C0.243283 8.42616 0.241408 8.55136 0.269595 8.67146C0.297781 8.79156 0.355157 8.90286 0.436639 8.99548C0.518121 9.0881 0.621193 9.1592 0.736721 9.20247L4.13922 10.4781L3.26422 14.8531C3.23223 15.0131 3.2533 15.1792 3.32423 15.3261C3.39516 15.4731 3.51208 15.5929 3.65726 15.6674C3.80244 15.7419 3.96795 15.767 4.12869 15.7389C4.28943 15.7109 4.43664 15.6311 4.54797 15.5118L11.548 8.01184C11.6321 7.9217 11.6927 7.8122 11.7244 7.69305C11.7562 7.57389 11.758 7.44876 11.7298 7.32871ZM5.25297 12.5581L5.73547 10.1468C5.77037 9.97222 5.742 9.79087 5.65543 9.63526C5.56886 9.47964 5.42974 9.3599 5.26297 9.29746L2.31235 8.19184L6.74547 3.44184L6.26297 5.85309C6.22807 6.02771 6.25644 6.20906 6.34301 6.36467C6.42958 6.52029 6.5687 6.64003 6.73547 6.70246L9.68735 7.80809L5.25297 12.5581Z"
                                fill="#6836D1"
                              />
                            </svg>
                          </TooltipTrigger>
                          <TooltipContent className="Tooltip">
                            <div className="w-[276px]">
                              <div className="bg-[#EDE8F8] py-2.5 px-5 rounded-lg text-sm font-normal font-fredoka leading-[21px] text-[#6836D1]">
                                Improve this to increase your VO score ranking
                              </div>
                            </div>
                          </TooltipContent>
                        </Tooltip>
                        {attractionDetails?.listing_package === "BASIC" &&
                          attractionDetails?.published_version
                            ?.show_subscription_options && (
                            <div
                              className={`inline-block pl-1 text-sm leading-[21px] text-[#5F646D] font-normal`}
                            >
                              {
                                <>
                                  Get up to 700% more clicks.{" "}
                                  <button
                                    type="button"
                                    className="underline text-[#6836D1]"
                                    onClick={() =>
                                      navigate(
                                        `/attractions/${
                                          attractionDetails?.short_id ??
                                          attractionId ??
                                          ""
                                        }/subscription`
                                      )
                                    }
                                  >
                                    Upgrade Now
                                  </button>
                                </>
                              }
                            </div>
                          )}
                      </label>
                      <Controller
                        control={control}
                        name={"listingPackage"}
                        render={({
                          field: { ref, value, onChange },
                          fieldState: { error },
                        }) => (
                          <Select
                            value={packagesOptions.find(
                              (o) => o.value === value
                            )}
                            components={{
                              Option: SingleSelectOption,
                              DropdownIndicator: DropDownIndicator,
                              IndicatorSeparator: () => null,
                            }}
                            options={packagesOptions}
                            isSearchable={false}
                            styles={{
                              menu: (styles) => ({
                                ...styles,
                                margin: 0,
                                border: "none",
                              }),
                              menuList: (base, isSelected) => ({
                                ...base,
                                paddingTop: 0,
                                paddingBottom: 0,
                                background: "white",

                                "::-webkit-scrollbar": {
                                  width: 14,
                                },
                                "::-webkit-scrollbar-thumb": {
                                  background: "#c2c2c2",
                                  borderRadius: 10,
                                  border: "4px solid white",
                                },
                              }),
                              option: (
                                base,
                                { data, isDisabled, isFocused, isSelected }
                              ) => {
                                return {
                                  ...base,
                                  background: "white",
                                  border: "1px solid #c2c2c2",
                                  color: isSelected ? "white" : "#555",
                                  "&:hover": {
                                    backgroundColor: "#6836D1",
                                    color:
                                      isSelected || isFocused
                                        ? "white"
                                        : "#555",
                                    border: isFocused ? "1px solid white" : "",
                                  },
                                  "&:active": {
                                    backgroundColor: "#6836D1",
                                    color: "white",
                                  },
                                };
                              },
                              singleValue: (base) => ({
                                ...base,
                                color: "#555",
                                fontSize: 14,
                              }),
                              control: (styles, state) => ({
                                ...styles,
                                cursor: "pointer",
                                outline: "none",
                                border: state.isFocused
                                  ? "1px solid #CFDBD5"
                                  : "1px solid #CFDBD5",
                                "&:hover": {
                                  border: state.isFocused
                                    ? "1px solid #CFDBD5"
                                    : "1px solid #CFDBD5",
                                },
                                boxShadow: "none",
                                paddingTop: 3,
                                paddingBottom: 3,
                                margin: 0,
                                minHeight: 40,
                                borderRadius: 8,
                              }),
                              valueContainer: (styles) => ({
                                ...styles,
                                paddingRight: 20,
                                paddingLeft: 20,
                                fontSize: 14,
                              }),
                            }}
                            onChange={(value) => onChange(value?.value)}
                            isDisabled={!canEditListing}
                          />
                        )}
                      />
                    </div>
                  ) : (
                    attractionDetails?.published_version
                      ?.show_subscription_options && (
                      <div className="mt-5 w-full">
                        <label
                          htmlFor="listing_package"
                          className="mb-2.5 text-sm font-bold text-[#212121] flex items-center"
                        >
                          Listing Plan
                          <span className="text-[#6836D1] mr-1">*</span>
                        </label>
                        <SubscriptionSection
                          showHeader={isStepperActive}
                          onNext={onNextPage}
                          isOverviewTab={true}
                        />
                      </div>
                    )
                  ))}

                <div className="w-full md:w-3/4 xl:w-1/2 inline-flex flex-col gap-y-5 flex-wrap mt-5">
                  <div>
                    <legend className="mb-4 text-[22px] font-extrabold text-black xl:whitespace-nowrap">
                      {isStepperActive
                        ? "Attraction Contact Details"
                        : "How can our website users get in touch with you?"}
                    </legend>

                    {!isStepperActive && (
                      <p className="text-[#5F646D] my-5 text-sm font-normal">
                        These are displayed on the site
                      </p>
                    )}
                    <TextInput
                      className="w-full mt-4 group"
                      labelClassName="group-focus-within:text-cs-pink text-sm font-bold"
                      inputClassName="border-cs-1 border-cs-bright-lt-gray text-cs-gray rounded-md py-2.5 px-5 text-sm flex flex-col w-full focus:outline-cs-pink h-10"
                      control={control}
                      name={"phoneNumber"}
                      label="Attraction Phone Number"
                      showLightning={
                        !isStepperActive && !!voScoreImprovements.phoneNumber
                      }
                      lightningTooltip={getVoScoreTooltip(
                        voScoreImprovements.phoneNumber
                      )}
                      required
                      inputProps={{ ...register("phoneNumber") }}
                      readonly={watchNoPhoneNumber === true}
                    />

                    <div className="mt-3">
                      <label
                        className={`flex items-center text-sm font-normal checkbox-item primary-checkmark text-cs-gray md:whitespace-nowrap md:w-auto w-full md:my-0 my-2`}
                      >
                        If your attraction does not have a phone number, please
                        tick this box.
                        <Controller
                          control={control}
                          name={`noPhoneNumber`}
                          render={({
                            field: { ref, value, onChange },
                            fieldState: { error },
                          }) => (
                            <input
                              ref={ref}
                              className="!w-20 !h-5"
                              type="checkbox"
                              checked={value}
                              onChange={onChange}
                            />
                          )}
                        />
                        <span className="checkmark !rounded-full border-cs-bright-lt-gray"></span>
                      </label>
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor="websiteAddress"
                      className="block text-sm font-bold text-[#212121] mb-2"
                    >
                      Attraction Website address
                      <span className="text-[#6836D1]">*</span>
                      {!isStepperActive &&
                        !!voScoreImprovements.websiteAddress && (
                          <LightningIcon
                            tooltip={getVoScoreTooltip(
                              voScoreImprovements.websiteAddress
                            )}
                            className="ml-1"
                          />
                        )}
                      <span
                        className={`inline-block text-sm leading-[21px] text-[#5F646D] font-normal`}
                      >
                        Only visible to website users with Standard Listing
                        Plans and above.{" "}
                        <button
                          type="button"
                          className="underline text-[#6836D1]"
                          onClick={() =>
                            navigate(
                              `/attractions/${
                                attractionDetails?.short_id ??
                                attractionId ??
                                ""
                              }/subscription`
                            )
                          }
                        >
                          Upgrade Now
                        </button>
                      </span>
                    </label>

                    <TextInput
                      className="w-full group"
                      labelClassName="group-focus-within:text-cs-pink text-sm font-bold"
                      inputClassName="border-[1px] border-[#CFDBD5] text-cs-gray rounded-lg py-2.5 px-5 text-sm flex flex-col w-full focus:outline-cs-pink h-10"
                      control={control}
                      name={"websiteAddress"}
                      label=""
                      required
                      inputProps={{ ...register("websiteAddress") }}
                    />
                  </div>

                  <fieldset className="inline-flex flex-col flex-wrap w-full">
                    {user?.role === "ADMIN" && (
                      <div className="mt-5">
                        <legend className="mb-4 text-[22px] font-extrabold text-black xl:whitespace-nowrap">
                          Ticketing Options
                        </legend>

                        <TextInput
                          className="w-full mt-4 group"
                          labelClassName="group-focus-within:text-cs-pink text-sm font-bold"
                          inputClassName="border-cs-1 border-cs-bright-lt-gray text-cs-gray rounded-md py-2.5 px-5 text-sm flex flex-col w-full focus:outline-cs-pink h-10"
                          control={control}
                          name={"bookCta"}
                          label="Book Ticketing Button Text"
                          inputProps={{ ...register("bookCta") }}
                        />
                        <div className="mt-4">
                          <label className={`block text-black font-bold mb-2`}>
                            Link Book Tickets Button to Attraction Website
                          </label>

                          <Controller
                            control={control}
                            name="bookCtaLink"
                            render={({
                              field: { ref, value, onChange },
                              fieldState: { error },
                            }) => (
                              <BooleanSelect
                                value={value}
                                onChange={onChange}
                              />
                            )}
                          />
                        </div>
                        <div className="mt-4">
                          <label className={`block text-black font-bold mb-2`}>
                            Hide Ticketing Widget
                          </label>

                          <Controller
                            control={control}
                            name="hideTicketing"
                            render={({
                              field: { ref, value, onChange },
                              fieldState: { error },
                            }) => (
                              <BooleanSelect
                                value={value}
                                onChange={onChange}
                              />
                            )}
                          />
                        </div>

                        <div className="mt-4">
                          <label className={`block text-black font-bold mb-2`}>
                            Show in description tickets
                          </label>

                          <Controller
                            control={control}
                            name="show_in_description_tickets_cta"
                            render={({
                              field: { ref, value, onChange },
                              fieldState: { error },
                            }) => (
                              <BooleanSelect
                                value={value}
                                onChange={onChange}
                              />
                            )}
                          />
                        </div>
                      </div>
                    )}
                  </fieldset>
                </div>
              </fieldset>
            </Stepper.Step>
            {isStepperActive &&
              (attractionDetails?.published_version?.has_paid_to_publish ||
                attractionDetails?.published_version?.skip_payment) && (
                <Stepper.Step componentType="Step" hideTitle={true}>
                  <div className="relative">
                    <SubscriptionSection
                      showHeader={isStepperActive}
                      onNext={onNextPage}
                      isOverviewTab={true}
                    />
                  </div>
                </Stepper.Step>
              )}
          </Stepper>

          {isSubmitted && !isValid && Object.keys(errors).length !== 0 && (
            <ErrorMessage>
              You have some errors! Scroll up to view & fix
            </ErrorMessage>
          )}
        </form>

        <RevertToSavedModal
          getShowRevertModal={getShowRevertModal}
          setShowRevertModal={setShowRevertModal}
          refetch={refetch}
        />

        <UnsavedModal isDirty={isDirty} />
      </SingleAttractionTemplate>
      <Footer />
    </div>
  );
};

const LoadingIndicator: React.FC = () => {
  return (
    <svg
      className="w-5 h-5 mr-3 -ml-1 animate-spin text-cs-pink"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      ></circle>
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  );
};

export default AttractionGeneral;

function Detail({ title, children }: { title: string; children: ReactNode }) {
  return (
    <div className="flex items-center mb-3 text-cs-gray">
      <span className="font-bold text-black w-44 sm:w-56 text-sm shrink-0">
        {title}
      </span>
      <span className="font-normal text-sm">{children}</span>
    </div>
  );
}
