import { useLogout } from "../../../hooks/useAuth";
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { ReactNode, useEffect, useState } from "react";
import { useWindowResize } from "../../../hooks/useWindowResize";
import { useAppDispatch, useAppSelector } from "../../../store";
import { globalActions } from "../../../store/globalSlice";
import { apiSlice } from "../../../store/apiSlice";
import { restartAnimation } from "../../../components/global/amp/ToastNotification";
import ModalWrapper from "../ModalWrapper";
import { createPortal } from "react-dom";
import type { UseFormHandleSubmit } from "react-hook-form";
import { UnsavedModalInternal } from "./UnsavedModal";
import ClaimModal, {
  iPendingClaims,
} from "../../../components/global/ClaimModal";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { type AmpUserDetail } from "../../../types/user";
import { VerifyEmailBox } from "../www/modals/VerifyEmailModal";
import { OutlineButton } from "../OutlineButton";
import { PrimaryButton } from "../PrimaryButton";
import moment from "moment";

interface IHeader {
  title?: string;
  full?: boolean;
  showPublishingButtons?: boolean;
  triggerValidation?: UseFormHandleSubmit<any>;
  saveFn?: any;
  statusOnlySaveFn?: any;
  getPayload?: () => Record<string, any>;
  isDirty?: boolean;
  mobilePublishButtons?: boolean;
  claimModalState?: [
    iPendingClaims | undefined,
    (val: iPendingClaims | undefined) => void
  ];
  showBack?: boolean;
}

const Header: React.FC<IHeader> = ({
  showPublishingButtons,
  full = false,
  triggerValidation,
  saveFn,
  statusOnlySaveFn,
  getPayload,
  isDirty = false,
  mobilePublishButtons = false,
  claimModalState,
  title = "",
  showBack = false,
}) => {
  const dispatch = useAppDispatch();
  const [logout] = useLogout();
  const windowSize = useWindowResize();
  const location = useLocation();
  const { isNavigationOpen, isClosedAlertOpen, user, toastNotification } =
    useAppSelector((state) => state.global);
  const { attractionId } = useParams();

  const [showSubNav, setShowSubNav] = useState<boolean>(false);
  const [unsavedModal, setUnsavedModal] = useState(false);

  const [searchAttractionTrigger] = apiSlice.useLazySearchAttractionsQuery();
  const rawSaveFunction = saveFn as (
    publish: boolean,
    isSilent?: boolean
  ) => () => Promise<EntityDetailsResponse<AttractionDetails>>;

  const { data: attractionDetailsResult, refetch } =
    apiSlice.useGetAttractionQuery(attractionId ?? "");

  const { result: attractionDetails } = attractionDetailsResult ?? {};
  const isStepperActive = user?.role !== 'ADMIN' && !attractionDetails?.finished_onboarding;

  useEffect(() => {
    (windowSize?.width ?? 0) < 1280 && isNavigationOpen
      ? document.body.classList.add("overflow-hidden")
      : document.body.classList.remove("overflow-hidden");
  }, [isNavigationOpen, windowSize?.width]);

  useEffect(() => {
    (async () => {
      if (attractionDetails) {
        const publishedSearch = await searchAttractionTrigger(
          attractionDetails.name ?? ""
        ).unwrap();

        const published = publishedSearch?.results.find(
          (a) => a.name === attractionDetails.name
        );

        dispatch(
          globalActions.setIsClosedAlertOpen(
            (published?.status ?? "open") as
              | "open"
              | "temporarily_closed"
              | "permanently_closed"
          )
        );
      }
    })();
  }, [attractionDetails]);

  // Claim approve / deny
  const [claimData, setClaimData] = useState(
    (location?.state?.claim ?? undefined) as
      | {
          created_by_user: AmpUserDetail;
          id: number;
          claimed: boolean;
          operator: {
            title: string;
            id: number;
          };
        }
      | undefined
  );
  const [
    updatePendingApproval,
    {
      data: pendingApprovalData,
      isLoading: isPendingApprovalLoading,
      isError,
      isSuccess: isUpdateSuccess,
      isUninitialized,
    },
  ] = apiSlice.useUpdatePendingApprovalMutation();
  const [getShowClaimModal, setShowClaimModal] = useState<iPendingClaims>({
    alreadyClaimed: false,
    operatorTitle: "",
    claimedBy: "",
    owners: [],
    managedByUsers: [],
    showApprove: false,
    show: false,
    id: "",
    primaryImage: "/assets/amp/dowtk-mono.png",
    name: "",
    address: "",
  });

  const schema = z.object({
    note: z.string().min(1, "Reason for claim denied is required"),
  });

  const {
    register,
    handleSubmit,
    formState: { isValid, isSubmitted, errors },
    setValue,
    getValues,
  } = useForm<iPendingClaims>({
    resolver: async (data, context, options) => {
      return zodResolver(schema)(data, context, options);
    },
    defaultValues: {
      note: "",
    },
  });

  const onSubmitClaimApproval: SubmitHandler<iPendingClaims> = async ({
    id,
    status,
    note,
  }) => {
    await updatePendingApproval({
      id: id || getShowClaimModal.id,
      payload: {
        status: status || getShowClaimModal.status,
        note: getValues("note"),
      },
    });
    await refetch();
    restartAnimation();
  };

  useEffect(() => {
    isUpdateSuccess &&
      dispatch(
        globalActions.setToastNotifcation({
          ...toastNotification,
          pendingType: "claim",
          type: "SUCCESS",
          message: "Your changes have been saved",
          attractionApprovalStatus: pendingApprovalData?.claims.reduce(
            (a: any, b: any) => (a.resolved_at > b.resolved_at ? a : b)
          ).status,
          attractionName: pendingApprovalData?.name,
          attractionAddress: pendingApprovalData?.address,
        })
      );
    setShowClaimModal({
      alreadyClaimed: false,
      show: false,
      id: "",
      status: null,
      primaryImage: "/assets/amp/dowtk-mono.png",
      name: "",
      address: "",
    });
    claimModalState && claimModalState[1](undefined);
    setValue("note", "");
    isUpdateSuccess && setClaimData(undefined);
  }, [isUpdateSuccess]);

  useEffect(() => {
    isError &&
      dispatch(
        globalActions.setToastNotifcation({
          ...toastNotification,
          type: "ERROR",
          message: "Cannot save changes",
        })
      );
  }, [isError]);

  useEffect(() => {
    if (!!claimModalState && !!claimModalState[0]) {
      setShowClaimModal(claimModalState[0]);
    }
  }, [claimModalState?.[0]]);

  const navigate = useNavigate();
  const bypassQueue =
    user?.role === "ADMIN" ||
    ["ENHANCED", "PREMIUM"].includes(attractionDetails?.listing_package ?? "");

  const approvedPrimaryButtonLabel = ["BASIC", "STANDARD"].includes(
    attractionDetails?.listing_package ?? ""
  )
    ? !attractionDetails?.approved
      ? bypassQueue || attractionDetails?.published_version?.has_paid_to_publish || attractionDetails?.published_version?.skip_payment
        ? "Publish"
        : "Pay & Publish"
      : bypassQueue
      ? "Publish"
      : attractionDetails?.queue_details?.length
      ? "Pending Approval"
      : "Submit for Approval"
    : "Publish";
  const nonApprovedPrimaryButtonLabel =
    user?.role === "ADMIN"
      ? "Publish"
      : attractionDetails?.published_version?.has_paid_to_publish || attractionDetails?.published_version?.skip_payment
      ? "Submit for Approval"
      : "Pay & Publish";
  return (
    <>
      <header className="fixed top-0 right-0 z-20 flex flex-col w-full xl:z-10 border-b border-[#CFDBD5]">
        <nav
          className={`flex items-center ml-auto w-full bg-[#F5F5FA] text-cs-gray overflow-hidden justify-center z-[100] relative px-10 py-5 xl:py-9 xl:justify-end xl:ml-auto xl:mr-0 ${
            full ? "" : "xl:pl-[300px]"
          }`}
        >
          {showBack && (
            <button
              type="button"
              className="pl-[21px] pr-[25px]"
              onClick={(e) => {
                e.preventDefault();
                navigate(-1);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6 stroke-black stroke-[2px]"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                />
              </svg>
            </button>
          )}
          <div
            className={`mr-auto text-[22px] font-extrabold text-black ${
              title === "Edit Attraction" ? "hidden xl:block" : ""
            } ${showBack ? "" : "pl-[70px]"}`}
          >
            {title}
          </div>
          {showPublishingButtons && (
            <div className="items-center gap-2 hidden ml-auto xl:flex">
              <PublishButtons
                attractionId={attractionId ?? ""}
                getPayload={getPayload}
                rawSaveFunction={rawSaveFunction}
                triggerValidation={triggerValidation}
                previewButton={({ onClick }) => (
                  <OutlineButton
                    scale="sm"
                    onClick={() => onClick()}
                    disabled={isStepperActive}
                    className={
                      isStepperActive ? "cursor-not-allowed opacity-50" : ""
                    }
                  >
                    Preview
                  </OutlineButton>
                )}
                publishButton={({
                  onClick,
                  loading,
                  currentClaim,
                  attractionDetails,
                }) => (
                  <div className="relative" id="publishButton">
                    <PrimaryButton
                      scale="sm"
                      onClick={() => onClick()}
                      loading={loading}
                      disabled={
                        isStepperActive
                      }
                    >
                      {currentClaim?.submit_approval !== null
                        ? approvedPrimaryButtonLabel
                        : nonApprovedPrimaryButtonLabel}
                    </PrimaryButton>

                    {attractionDetails?.queue_details?.[0]?.resolves_at && (
                      <span className="whitespace-nowrap text-[10px] absolute -bottom-[24px] right-[11px]">
                        Publishes on:{" "}
                        {moment(
                          new Date(
                            attractionDetails?.queue_details?.[0]
                              ?.resolves_at ?? ""
                          )
                        ).format("MMM DD HH:mm")}
                      </span>
                    )}
                  </div>
                )}
              />

              {!!claimData && user?.role === "ADMIN" && (
                <div className="flex gap-8 ml-8">
                  {!isPendingApprovalLoading ? (
                    <button
                      disabled={
                        claimData?.created_by_user?.email_verified ? false : true
                      }
                      onClick={() => {
                        setShowClaimModal({
                          alreadyClaimed: claimData?.claimed ? true : false,
                          showApprove: true,
                          status: "APPROVED",
                          name: attractionDetails?.name,
                          address: attractionDetails?.address,
                          claimedBy: claimData?.operator.title ?? "",
                          operatorTitle: attractionDetails?.operator
                            ? attractionDetails?.operator.title
                            : "",
                          id: claimData?.id,
                          owners: attractionDetails?.operator
                            ? (
                                attractionDetails?.operator as any
                              ).owners.filter(
                                (owner: any) => owner.role == "OPERATOR_OWNER"
                              )
                            : "",
                          primaryImage:
                            attractionDetails?.display_image?.cloudinary_url ??
                            attractionDetails?.display_image?.url ??
                            "/assets/amp/dowtk-mono.png",
                        });
                      }}
                      className={`font-normal text-sm ${
                        claimData?.created_by_user?.email_verified
                          ? "text-cs-pink"
                          : "text-cs-gray"
                      }`}
                    >
                      Approve
                    </button>
                  ) : (
                    <span
                      className={`font-normal text-sm ${
                        !claimData?.created_by_user?.email_verified
                          ? "text-cs-pink"
                          : "text-cs-gray"
                      }`}
                    >
                      Approve
                    </span>
                  )}
                  <button
                    onClick={() => {
                      setShowClaimModal({
                        alreadyClaimed: false,
                        show: true,
                        id: claimData?.id,
                        status: "DENIED",
                        primaryImage:
                          attractionDetails?.display_image?.cloudinary_url ??
                          attractionDetails?.display_image?.url ??
                          "/assets/amp/dowtk-mono.png",
                        name: attractionDetails?.name,
                        address: attractionDetails?.address,
                      });
                    }}
                    className={`font-normal text-sm text-cs-pink`}
                  >
                    Deny
                  </button>
                </div>
              )}
            </div>
          )}

          <div
            className={`flex xl:hidden px-8 items-center gap-2 ${
              full ? "mr-auto h-11" : ""
            }`}
          >
            <Link to={"/dashboard"} className="flex-shrink-0">
              <img src={"/assets/www/logo.png"} alt="Connect" />
            </Link>
            <h1 className="text-black font-extrabold text-[25px]">Connect</h1>
          </div>

          <div className="absolute top-0 right-0 flex items-center h-full xl:hidden">
            <button
              id="hamburger"
              className={`${isNavigationOpen ? "open" : ""}`}
              onClick={() =>
                dispatch(globalActions.setIsNavigationOpen(isNavigationOpen))
              }
            >
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </button>
          </div>
        </nav>
        {mobilePublishButtons && (
          <div className="xl:hidden w-full grid grid-cols-2 gap-5 px-5 py-3 bg-white">
            <PublishButtons
              attractionId={attractionId ?? ""}
              getPayload={getPayload}
              rawSaveFunction={rawSaveFunction}
              triggerValidation={triggerValidation}
              previewButton={({ onClick }) => (
                <OutlineButton
                  type="button"
                  onClick={() => onClick()}
                  disabled={isStepperActive}
                  className={
                    isStepperActive ? "cursor-not-allowed opacity-50" : ""
                  }
                >
                  Preview
                </OutlineButton>
              )}
              publishButton={({
                onClick,
                loading,
                currentClaim,
                attractionDetails,
              }) => (
                <div id="publishButton">
                  <PrimaryButton
                    onClick={() => onClick()}
                    loading={loading}
                    disabled={
                      isStepperActive
                    }
                  >
                    {currentClaim?.submit_approval !== null
                      ? approvedPrimaryButtonLabel
                      : nonApprovedPrimaryButtonLabel}
                  </PrimaryButton>
                </div>
              )}
            />
          </div>
        )}
        <ul
          className={`w-44 bg-white cs-admin-top-dropdown-nav absolute flex-wrap flex-col right-28 z-0 hidden duration-300 transition-transform ${
            showSubNav && location.pathname == "/search-claim"
              ? "translate-y-cs-60"
              : showSubNav && showPublishingButtons
              ? "translate-y-cs-60"
              : showSubNav && !showPublishingButtons
              ? "translate-y-10"
              : "-translate-y-40"
          } xl:flex`}
        >
          <li className="border-2 border-cs-bright-lt-gray hover:bg-cs-pink hover:text-white">
            <Link to={"/my-account"} className="block p-2 text-sm font-normal">
              My Account
            </Link>
          </li>
          <li className="text-sm font-normal border-2 border-t-0 border-cs-bright-lt-gray hover:bg-cs-pink hover:text-white">
            <button
              className="p-2"
              onClick={() => {
                logout();
              }}
            >
              Log out
            </button>
          </li>
        </ul>

        {!isStepperActive && isClosedAlertOpen &&
          isClosedAlertOpen !== "open" &&
          attractionDetails?.short_id &&
          location.pathname.startsWith(
            `/attractions/${attractionDetails?.short_id ?? ""}/`
          ) && (
            <Link
              to={`/attractions/${
                attractionDetails?.short_id ?? ""
              }/opening-times`}
              className={`flex ml-auto text-center font-semibold text-sm w-full text-white bg-cs-pink overflow-hidden justify-center z-[-1] relative px-10 py-3 xl:ml-auto xl:mr-0 ${
                full ? "" : "xl:pl-[300px]"
              }`}
            >
              Venue is marked as "
              {isClosedAlertOpen === "temporarily_closed"
                ? "Temporarily closed"
                : "Permanently closed"}
              ". Change this status in "Opening times" tab.
            </Link>
          )}

        {createPortal(
          <UnsavedModalInternal
            isActive={isDirty && unsavedModal}
            onConfirm={() => logout()}
            onCancel={() => setUnsavedModal(false)}
          />,
          document.body
        )}

        {createPortal(
          <ClaimModal
            className={`w-[787px] ${
              !getShowClaimModal.showApprove && "min-h-[400px] px-10 xl:px-0"
            }`}
            setValue={setValue}
            open={getShowClaimModal}
            setOpen={(val) => {
              setShowClaimModal(val);
              claimModalState && claimModalState[1](undefined);
            }}
          >
            <h3 className="text-2xl font-bold text-cs-gray">
              {getShowClaimModal.alreadyClaimed &&
              !getShowClaimModal.showApprove
                ? "Already claimed"
                : getShowClaimModal.showApprove
                ? "Approve?"
                : "Deny claim for"}
            </h3>
            <div className="flex w-full mt-10 overflow-hidden bg-white rounded-lg border-cs-lt-gray border-cs-1 min-h-cs-100">
              <div
                className={`w-1/4 max-w-cs-112 bg-no-repeat bg-cs-off-white bg-center ${
                  getShowClaimModal.primaryImage == "/assets/amp/dowtk-mono.png"
                    ? "bg-[length:80%]"
                    : "bg-cover"
                }`}
                style={{
                  backgroundImage: `url(${getShowClaimModal.primaryImage})`,
                }}
              ></div>
              <div className="flex flex-col justify-center w-3/4 p-5">
                <h4 className="w-full overflow-hidden text-xl font-bold text-cs-pink whitespace-nowrap text-ellipsis">
                  {getShowClaimModal.name}
                </h4>
                <span className="block w-full overflow-hidden font-normal text-cs-gray whitespace-nowrap text-ellipsis">
                  {getShowClaimModal.address}
                </span>
              </div>
            </div>

            {getShowClaimModal.alreadyClaimed &&
            !getShowClaimModal.showApprove ? (
              <>
                <p className="mt-8 text-base font-normal text-center mb-7 text-cs-gray">
                  Is already claimed by
                </p>
                <h4 className="text-2xl font-bold text-cs-pink">
                  {getShowClaimModal.owners?.map(
                    (owner: any, i: any) =>
                      i === 0 && `${owner?.first_name} ${owner?.last_name}`
                  )}
                  , {getShowClaimModal.operatorTitle ?? ""}
                </h4>
                <p className="text-base font-normal text-center mb-7 text-cs-gray">
                  {getShowClaimModal.owners?.map(
                    (owner: any, i: any) => i === 0 && owner?.email
                  )}
                </p>
                {getShowClaimModal?.managedByUsers?.length! > 0 ? (
                  <p className="mb-5 text-base font-normal text-center text-cs-gray">
                    Managed by{" "}
                    {getShowClaimModal.managedByUsers?.map(
                      (owner: any, i: any) =>
                        i === 0 &&
                        `${owner?.first_name} ${owner?.last_name} ${owner?.email}`
                    )}
                  </p>
                ) : (
                  <></>
                )}
              </>
            ) : getShowClaimModal.showApprove ? (
              <>
                <p className="my-4 text-base font-normal text-center text-cs-gray">
                  {getShowClaimModal.alreadyClaimed ? (
                    <>
                      This will transfer ownership of the attraction from{" "}
                      <h4 className="inline-block text-xl font-bold text-cs-pink">
                        {getShowClaimModal.operatorTitle}
                      </h4>{" "}
                      to{" "}
                      <h4 className="inline-block text-xl font-bold text-cs-pink">
                        {getShowClaimModal.claimedBy}
                      </h4>
                      .{" "}
                      <h3 className="text-2xl font-bold mt-7">Are you sure?</h3>
                    </>
                  ) : (
                    <>
                      This will transfer ownership of the attraction to{" "}
                      <h4 className="inline-block text-xl font-bold text-cs-pink">
                        {getShowClaimModal.claimedBy}
                      </h4>
                      .{" "}
                      <h3 className="text-2xl font-bold mt-7">Are you sure?</h3>
                    </>
                  )}
                </p>

                <div className="flex flex-row mt-4">
                  <button
                    type="button"
                    className="px-6 py-2 text-sm text-white border-2 h-cs-55 rounded-2xl mr-14 bg-cs-pink border-cs-pink hover:bg-white hover:text-cs-pink"
                    onClick={() => {
                      onSubmitClaimApproval({
                        alreadyClaimed: false,
                        id: getShowClaimModal.id,
                        status: "APPROVED",
                      });
                      setShowClaimModal({
                        ...getShowClaimModal,
                        showApprove: false,
                      });
                      claimModalState && claimModalState[1](undefined);
                    }}
                  >
                    Yes, approve
                  </button>

                  <button
                    type="button"
                    className="px-6 py-2 text-sm text-cs-gray hover:text-cs-pink hover:underline"
                    onClick={() => {
                      setShowClaimModal({
                        ...getShowClaimModal,
                        showApprove: false,
                      });
                      claimModalState && claimModalState[1](undefined);
                    }}
                  >
                    No
                  </button>
                </div>
              </>
            ) : (
              <>
                <p className="text-base font-normal text-center my-7 text-cs-gray">
                  Enter a reason for denying this claim. This text will be sent
                  directly to the claimer via email, so check spelling & grammar
                </p>

                <form
                  className="flex flex-col w-full h-full"
                  onSubmit={handleSubmit(onSubmitClaimApproval)}
                >
                  <input
                    {...register("id")}
                    value={getShowClaimModal.id}
                    hidden
                  />
                  <fieldset className="flex flex-col w-full">
                    <label className="flex font-normal text-cs-gray">
                      Why was this claim denied?
                    </label>
                    <textarea
                      {...register("note")}
                      className="w-full h-32 p-3 mt-2 rounded-sm text-cs-gray text-md border-cs-lt-gray border-cs-1 outline-cs-pink"
                    ></textarea>
                    {errors.note && (
                      <div className="flex items-center mt-2 mb-5 text-cs-red">
                        <span className="w-5 h-5 bg-cs-red rounded-full mr-3 text-white before:relative before:left-2 before:-top-0.5 before:content-['!']"></span>
                        {errors.note?.message}
                      </div>
                    )}
                  </fieldset>

                  <button
                    type="submit"
                    disabled={isPendingApprovalLoading}
                    className={`mx-auto mt-14 mb-5 leading-none rounded-full h-cs-55 w-full py-2 px-12 text-white text-md border-cs-1 font-bold shadow-cs-button-backdrop hover:text-cs-pink hover:outline-cs-pink md:w-auto ${
                      isSubmitted && !isValid && Object.keys(errors).length != 0
                        ? "bg-cs-red border-cs-red hover:bg-white"
                        : isPendingApprovalLoading
                        ? "bg-cs-bright-lt-gray border-cs-bright-lt-gray text-opacity-50 !hover:bg-cs-bright-lt-gray hover:border-cs-bright-lt-gray hover:text-opacity-50 hover:text-white"
                        : "bg-cs-pink border-cs-pink hover:bg-white"
                    }`}
                  >
                    Deny & send to claimer
                  </button>

                  {isSubmitted &&
                    !isValid &&
                    Object.keys(errors).length != 0 && (
                      <div className="flex items-center self-center justify-center mt-3 text-cs-red">
                        <span className="w-5 h-5 bg-cs-red rounded-full mr-3 text-white before:relative before:left-2 before:-top-0.5 before:content-['!']"></span>
                        <span className="flex-1">
                          Please fix the errors indicated above to continue
                        </span>
                      </div>
                    )}
                </form>
              </>
            )}
          </ClaimModal>,
          document.body
        )}
      </header>
    </>
  );
};

export default Header;

export function isWithin60Days(startDate: Date, endDate: Date): boolean {
  const diffInDays =
    Math.abs(endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24);

  if (diffInDays < 60) {
    return true;
  } else {
    return false;
  }
}
function hasErrors(obj: Record<string, string[]>) {
  for (let key in obj) {
    if (
      key !== "payment" &&
      key !== "unverified" &&
      obj[key].filter((v) => v).length > 0
    ) {
      return true;
    }
  }
  return false;
}

export function PublishButtons({
  attractionId,
  previewButton,
  publishButton,
  rawSaveFunction,
  getPayload,
  triggerValidation,
  isOnboarding
}: {
  attractionId: string;
  previewButton: (props: { onClick: Function }) => ReactNode;
  publishButton: (props: {
    onClick: Function;
    loading: boolean;
    currentClaim: any;
    attractionDetails: AttractionDetails | undefined;
    basicPlanPrice?: number;
  }) => ReactNode;
  rawSaveFunction: (
    publish: boolean,
    isSilent?: boolean
  ) => () => Promise<EntityDetailsResponse<AttractionDetails>>;
  getPayload?: () => Record<string, any>;
  triggerValidation?: Function;
  isOnboarding?: boolean
}) {
  const { user, toastNotification } = useAppSelector((state) => state.global);
  const location = useLocation();
  const { data: getAttraction } = apiSlice.useGetAttractionDraftPreviewQuery(
    location.pathname.split("/")[2]
  );

  const { data: attractionDetailsResult, refetch } =
    apiSlice.useGetAttractionQuery(attractionId ?? "");
  const { result: attractionDetails } = attractionDetailsResult ?? {};

  const currentClaim = (attractionDetails as any)?.claims?.find(
    (c: any) =>
      c.created_by_user?.email === user?.email && c.status !== "APPROVED"
  );

  const [actionRestrictModalContext, setActionRestrictModalContext] = useState<
    "Preview" | "Publish"
  >("Publish");
  const [newAttractionData, setNewAttractionData] =
    useState<AttractionDetails | null>(null);
  const [showPublishRestrictModal, setShowPublishRestrictModal] =
    useState(false);
  const [showClosedWarningModal, setShowClosedWarningModal] = useState(false);
  const [publishRestrictionErrors, setPublishRestrictionErrors] = useState({
    unverified: [""],
    general: [""],
    location: [""],
    details: [""],
    opening: [""],
    images: [""],
    payment: [""],
  });
  const validateBeforePublish = async (
    details: AttractionDetails,
    saveBeforeShowModal?: boolean,
    skipPaymentCheck?: boolean
  ): Promise<boolean> => {
    let status = true;
    const errors = {
      unverified: [""],
      general: [""],
      location: [""],
      details: [""],
      opening: [""],
      images: [""],
      payment: [""],
    } satisfies Record<string, string[]>;

    if (!(user as AmpUserDetail)?.email_verified) {
      errors.unverified.push("UNVERIFIED");
      status = false;
    }

    if (!details?.name) {
      errors.general.push("Display name");
      status = false;
    }

    if (!details.phone && !details.no_phone_number) {
      errors.general.push("Phone number");
      status = false;
    }

    if (!details?.town?.name) {
      errors.location.push("Town/city");
      status = false;
    }

    if (!details?.postcode) {
      errors.location.push("postcode");
      status = false;
    }

    if (!details?.summary) {
      errors.details.push("Short description");
      status = false;
    }

    if (!details?.status) {
      errors.opening.push("Status");
      status = false;
    }

    if (!details?.media_json || details?.media_json.length < 3) {
      errors.images.push(`at least 3 images are required`);
      status = false;
    }

    if (
      !details.published_version?.has_paid_to_publish &&
      !details.published_version?.skip_payment &&
      user?.role !== "ADMIN" &&
      !skipPaymentCheck
    ) {
      errors.payment.push("PAYMENT");
      status = false;
    }

    /*
          Removed primary image requirement, if there is no primary image set then the first image will be defaulted to, this is 
          how it works for the display_image property and is the logic for choosing the initial primary image on the images tab - WC

      if (details.media_json.length > 1 && !details?.media_json.find(m => m.isPrimary)){
          errors.images.push("Primary image must be set.");
          status = false;
      }*/

    setPublishRestrictionErrors(errors);

    if (!status) {
      if (saveBeforeShowModal) {
        rawSaveFunction && (await rawSaveFunction(false)()); // Save before showing "cannot publish" modal
      }
      setShowPublishRestrictModal(true);
    }

    return status;
  };

  const [trigger] = apiSlice.useLazyGetAttractionHeaderQuery();
  const [publishLoading, setPublishLoading] = useState(false);
  const isValidForPublish = async (
    saveBeforeShowModal?: boolean,
    skipPaymentCheck?: boolean
  ) => {
    return trigger(attractionId ?? "")
      .unwrap()
      .then(async (data) => {
        setNewAttractionData(data.result);
        return validateBeforePublish(
          { ...data.result, ...(getPayload ? getPayload() : {}) },
          saveBeforeShowModal,
          skipPaymentCheck
        );
      })
      .catch((err) => false);
  };
  const viewDraftPreview = async () => {
    setActionRestrictModalContext("Preview");
    if (await isValidForPublish(false, true)) {
      window.open(getAttraction?.url, "_blank", "noreferrer");
    }
  };

  const [searchAttractionTrigger] = apiSlice.useLazySearchAttractionsQuery();
  const shouldShowClosedWarning = async () => {
    if (!newAttractionData) return false;
    const publishedSearch = await searchAttractionTrigger(
      newAttractionData.name ?? ""
    ).unwrap();

    const published = publishedSearch?.results.find(
      (a) => a.name === newAttractionData.name
    );
    const currentPayload = getPayload ? getPayload() : {};

    return (
      published &&
      published.status === "open" &&
      ((currentPayload.status !== "open" &&
        currentPayload.status !== undefined) ||
        newAttractionData.status !== "open")
    );
  };
  const publishTheAttraction = async () => {
    setPublishLoading((v) => true);
    setActionRestrictModalContext("Publish");

    // if (attractionId) {
    //   await statusOnlySaveFn?.(); // Save only the status
    //   const payload = (getPayload ? getPayload() : {})
    //   const hasPublishedStatus = await publishStatus({
    //     id: attractionId,
    //     forcePublish: payload?.status === 'permanently_closed'
    //   });

    //   if ('data' in hasPublishedStatus && hasPublishedStatus.data.status === true) {
    //     dispatch(
    //       globalActions.setToastNotifcation({
    //         ...toastNotification,
    //         type: "SUCCESS",
    //         message: "Status published successfully.",
    //         attractionApprovalStatus: "",
    //         attractionImage: "",
    //         attractionName: "",
    //         attractionAddress: "",
    //       })
    //     );

    //     restartAnimation();
    //   }
    // }

    if ((await isValidForPublish()) && rawSaveFunction) {
      if (!(await shouldShowClosedWarning())) {
        await rawSaveFunction(true)();

        restartAnimation();
      } else {
        setShowClosedWarningModal(true);
      }
    }
    setPublishLoading((v) => false);
  };

  const [sessionDataTrigger] = apiSlice.useLazySessionQuery();
  const [submitApproval] = apiSlice.useUpdateSubmitClaimMutation();
  const submitClaim = async () => {
    setPublishLoading((v) => true);
    setActionRestrictModalContext("Publish");

    await sessionDataTrigger(undefined);

    if (rawSaveFunction) {
      if (!(await shouldShowClosedWarning())) {
        await rawSaveFunction(false)();

        if (await isValidForPublish()) {
          await submitApproval(currentClaim?.id ?? "");
        }
        restartAnimation();
      } else {
        setShowClosedWarningModal(true);
      }
    }
    setPublishLoading((v) => false);
  };

  const publishOnClick =
    currentClaim?.submit_approval !== null
      ? triggerValidation
        ? triggerValidation(publishTheAttraction)
        : publishTheAttraction
      : triggerValidation
      ? triggerValidation(
          user?.role === "ADMIN" ? publishTheAttraction : submitClaim
        )
      : user?.role === "ADMIN"
      ? publishTheAttraction
      : submitClaim;

  const publishDisabled =
    currentClaim?.submit_approval !== null
      ? publishLoading ||
        !attractionDetails?.approved ||
        attractionDetails?.queue_details?.length > 0
      : publishLoading;

  // Modals
  const dispatch = useAppDispatch();

  const { data: pricingPlans, isLoading: isLoadingPricingPlans } =
    apiSlice.useGetSubscriptionPlansQuery({
      venueId: (newAttractionData?.published_version?.id as string) || "",
    });
  const basicPlan = pricingPlans?.find((p) => p.id === "basic-plan-monthly");
  const basicPlanPrice = basicPlan?.price ? basicPlan?.price / 100 : 29;

  const [checkoutUrl] = apiSlice.useCreatePaymentUrlMutation();
  const [loadingPayment, setLoadingPayment] = useState(false);
  async function startPayment(attractionId: string, isOnboarding?: boolean) {
    setLoadingPayment(true);
    const checkoutPage = await checkoutUrl({
      id: attractionId,
      submit_approval: currentClaim?.id,
      planId: "basic-plan-monthly",
      isOnboarding
    });

    if ("data" in checkoutPage) {
      window.location.href = new URL(
        checkoutPage.data?.sessionUrl ?? ""
      ).toString();
      return;
    } else {
      dispatch(
        globalActions.setToastNotifcation({
          ...toastNotification,
          type: "ERROR",
          message: "Failed to redirect to checkout",
          attractionApprovalStatus: "",
          attractionImage: "",
          attractionName: "",
          attractionAddress: "",
        })
      );
      restartAnimation();
    }

    setLoadingPayment(false);
  }

  const getRestrictTitle = (key: "Preview" | "Publish") =>
    key === "Preview" ? "previewing" : "publishing";

  const publishWithoutChecks = async () => {
    setPublishLoading((v) => true);
    setShowClosedWarningModal(false);
    await rawSaveFunction(true)();
    restartAnimation();
    setPublishLoading((v) => false);
  };

  return (
    <>
      {previewButton({ onClick: viewDraftPreview })}

      {publishButton({
        onClick: publishOnClick,
        loading: publishDisabled,
        currentClaim,
        attractionDetails,
        basicPlanPrice,
      })}

      {createPortal(
        <ModalWrapper
          className={`${
            publishRestrictionErrors.payment.filter((e) => e).length > 0
              ? "w-[500px]"
              : "w-[700px]"
          } ${
            publishRestrictionErrors.payment.filter((e) => e).length > 0
              ? ""
              : publishRestrictionErrors.unverified.filter((e) => e).length > 0
              ? "min-h-cs-100"
              : "min-h-cs-394"
          } px-10 xl:px-0`}
          open={showPublishRestrictModal}
          setOpen={setShowPublishRestrictModal}
        >
          {hasErrors(publishRestrictionErrors) ? (
            <>
              <h3 className="mb-3 text-2xl font-bold text-center text-cs-gray">
                We need more information before{" "}
                {getRestrictTitle(actionRestrictModalContext)}
              </h3>
              <p className="text-base font-normal text-center mt-7 text-cs-gray">
                Some of the minimum information we need to{" "}
                {actionRestrictModalContext == "Preview"
                  ? "preview"
                  : "publish"}{" "}
                this attraction is missing.
              </p>
              <p className="text-base font-normal text-center text-cs-gray">
                Please make sure the following information is completed:
              </p>

              <div className="text-center mt-7">
                {publishRestrictionErrors.general.filter((e) => e).length >
                  0 && (
                  <div className="text-cs-gray">
                    Overview /
                    {publishRestrictionErrors.general
                      .filter((e) => e)
                      .map((e, index) => (
                        <>
                          <span className="font-semibold text-black"> {e}</span>
                          {index + 2 !==
                            publishRestrictionErrors.general.length && (
                            <span> & </span>
                          )}
                        </>
                      ))}
                  </div>
                )}

                {publishRestrictionErrors.location.filter((e) => e).length >
                  0 && (
                  <div className="text-cs-gray">
                    Location /
                    {publishRestrictionErrors.location
                      .filter((e) => e)
                      .map((e, index) => (
                        <>
                          <span className="font-semibold text-black"> {e}</span>
                          {index + 2 !==
                            publishRestrictionErrors.location.length && (
                            <span> & </span>
                          )}
                        </>
                      ))}
                  </div>
                )}

                {publishRestrictionErrors.details.filter((e) => e).length >
                  0 && (
                  <div className="text-cs-gray">
                    Attraction Details /
                    {publishRestrictionErrors.details
                      .filter((e) => e)
                      .map((e, index) => (
                        <>
                          <span className="font-semibold text-black"> {e}</span>
                          {index + 2 !==
                            publishRestrictionErrors.details.length && (
                            <span> & </span>
                          )}
                        </>
                      ))}
                  </div>
                )}

                {publishRestrictionErrors.opening.filter((e) => e).length >
                  0 && (
                  <div className="text-cs-gray">
                    Opening times /
                    {publishRestrictionErrors.opening
                      .filter((e) => e)
                      .map((e, index) => (
                        <>
                          <span className="font-semibold text-black"> {e}</span>
                          {index + 2 !==
                            publishRestrictionErrors.opening.length && (
                            <span> & </span>
                          )}
                        </>
                      ))}
                  </div>
                )}

                {publishRestrictionErrors.images.filter((e) => e).length >
                  0 && (
                  <div className="text-cs-gray">
                    Images /
                    {publishRestrictionErrors.images
                      .filter((e) => e)
                      .map((e, index) => (
                        <>
                          <span className="font-semibold text-black"> {e}</span>
                          {index + 2 !==
                            publishRestrictionErrors.images.length && (
                            <span> & </span>
                          )}
                        </>
                      ))}
                  </div>
                )}
              </div>

              <div className="flex flex-row mt-10">
                <button
                  className="px-16 py-2 mx-auto text-sm text-white border-2 h-cs-55 rounded-2xl bg-cs-pink border-cs-pink hover:bg-white hover:text-cs-pink"
                  onClick={() => setShowPublishRestrictModal(false)}
                >
                  OK
                </button>
              </div>
            </>
          ) : publishRestrictionErrors.unverified.filter((e) => e).length >
            0 ? (
            <>
              <h3 className="mb-3 text-2xl font-bold text-center text-cs-gray">
                You need to verify your email to continue
              </h3>

              <VerifyEmailBox email={user?.email ?? ""} />

              <div className="flex flex-row mt-10">
                <button
                  className="px-16 py-2 mx-auto text-sm text-white border-2 h-cs-55 rounded-2xl bg-cs-pink border-cs-pink hover:bg-white hover:text-cs-pink"
                  onClick={() => setShowPublishRestrictModal(false)}
                >
                  OK
                </button>
              </div>
            </>
          ) : publishRestrictionErrors.payment.filter((e) => e).length > 0 ? (
            <>
              <h3 className="text-[22px] font-bold text-center text-black">
                Make Payment And Publish
              </h3>

              <p className="mt-5 text-base text-center text-black font-light">
                You’re almost there! Simply click below to make payment.
              </p>

              <PrimaryButton
                type="button"
                className="mt-5 w-[145px] px-[30px]"
                scale={"sm"}
                loading={loadingPayment || isLoadingPricingPlans}
                onClick={() =>
                  startPayment(
                    ((attractionDetails as any)?.venue_id as string) ?? "",
                    isOnboarding
                  )
                }
              >
                Pay Now{" "}
                {(attractionDetails as any)?.duration_type === "temporary"
                  ? "£89"
                  : `£${basicPlanPrice}`}
              </PrimaryButton>
              {/* <button
                  type="button"
                  className="text-xs text-black font-light mt-2.5 text-center"
                  onClick={() => setShowPublishRestrictModal(false)}
                >
                  Remind me later
                </button> */}
            </>
          ) : (
            <></>
          )}
        </ModalWrapper>,
        document.body
      )}

      {createPortal(
        <ModalWrapper
          className="w-[500px] px-10 xl:px-0"
          open={showClosedWarningModal}
          setOpen={setShowClosedWarningModal}
        >
          <h3 className="text-2xl font-bold text-cs-gray">
            Closed - are you sure?
          </h3>
          <p className="text-base font-normal text-center my-7 text-cs-gray">
            You have selected to show the attraction as temporarily or
            permanently closed. This will go live as soon as you confirm.
          </p>
          <h4 className="text-lg font-bold text-cs-gray">
            Please confirm that you wish to do this.
          </h4>

          <div className="flex flex-row mt-10">
            <button
              className="px-6 py-2 mx-auto text-sm text-white border-2 h-cs-55 rounded-2xl bg-cs-pink border-cs-pink hover:bg-white hover:text-cs-pink"
              onClick={publishWithoutChecks}
            >
              Yes, Confirm
            </button>

            <button
              className="ml-20 text-sm text-cs-gray hover:text-cs-pink hover:underline"
              onClick={() => setShowClosedWarningModal(false)}
            >
              No, Cancel
            </button>
          </div>
        </ModalWrapper>,
        document.body
      )}
    </>
  );
}
