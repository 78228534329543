import { useState, type ReactNode, useEffect, useMemo } from "react";
import { apiSlice } from "../../store/apiSlice";
import { Tooltip, TooltipTrigger, TooltipContent } from "../global/Tooltip";
import { imageCount } from "../../pages/amp/attractions/AttractionImages";
import { useAppDispatch } from "../../store";
import { globalActions } from "../../store/globalSlice";

export type Packages = "BASIC" | "STANDARD" | "ENHANCED" | "PREMIUM" | "EVENT";
export type PackageDetails = {
  id: SubscriptionPlan["name"];
  title: string;
  messaging: string | ReactNode;
  features: (string | { value: string; tooltip: string })[];
  plus?: (string | { value: string; tooltip: string })[];
  vat: string;
  getInTouch?: boolean;
  mostPopular?: boolean;
};

export const packages: PackageDetails[] = [
  {
    id: "basic",
    title: "Basic",
    features: [
      "This plan is only for free listings like beaches, council playgrounds, parks etc. Paid entry attractions selecting this plan will not be visible on the website. Please choose a Standard Plan or above.",
      "This plan does not give you access to Analytics, Instant content updates, Ticket Manager, Venue Optimisation Score or a Website Link.",
      "Max 3 Images",
      "Content Updates Take 30 Days",
      "Attraction Info & FAQs",
      "Opening Times & Pricing",
      "Address & Map View",
    ],
    messaging: "Only for Free Attractions",
    vat: "",
  },
  {
    id: "standard",
    title: "Standard",
    features: [
      `Max ${imageCount["STANDARD"]} images`,
      {
        value: "Instant Content Updates",
        tooltip:
          "Changes to your listing copy are published instantly on the website",
      },
      "Attraction Info & FAQs",
      "Opening Times & Pricing",
      "Address & Map View",
    ],
    plus: [
      {
        value: "Venue Optimisation Score",
        tooltip:
          "Ai powered optimisation score to help you achieve maximum exposure of your attraction on DOWTK",
      },
      {
        value: "Featured Website Link",
        tooltip: "Prominent, high-impact button linked to your own website",
      },
      {
        value: "Analytics & Insights",
        tooltip:
          "Live analytics on your listings impressions, views and clicks",
      },
      {
        value: "Reviews & Replies",
        tooltip: "Users can leave reviews directly on your listing page",
      },
      {
        value: "Ticket Manager",
        tooltip: "Start selling tickets within 30 minutes without any technical work required. Super easy!"
      }
    ],
    messaging: <>150% Increase In Traffic</>,
    vat: "",
    mostPopular: true,
  },
  {
    id: "enhanced",
    title: "Enhanced",
    features: [
      `Max ${imageCount["ENHANCED"]} images`,
      {
        value: "Instant Content Updates",
        tooltip:
          "Changes to your listing copy are published instantly on the website",
      },
      "Attraction Info & FAQs",
      "Opening Times & Pricing",
      "Address & Map View",
      {
        value: "Venue Optimisation Score",
        tooltip:
          "Ai powered optimisation score to help you achieve maximum exposure of your attraction on DOWTK",
      },
      {
        value: "Featured Website Link",
        tooltip: "Prominent, high-impact button linked to your own website",
      },
      {
        value: "Analytics & Insights",
        tooltip:
          "Live analytics on your listings impressions, views and clicks",
      },
      {
        value: "Reviews & Replies",
        tooltip: "Users can leave reviews directly on your listing page",
      },
      {
        value: "Ticket Manager",
        tooltip: "Start selling tickets within 30 minutes without any technical work required. Super easy!"
      }
    ],
    plus: [
      {
        value: "Featured on Page One",
        tooltip:
          "Your attraction will appear on the 1st page of attractions in your area (within the first 20 attractions)",
      },
      {
        value: "Optimised Content by DOWTK",
        tooltip:
          "Unique, engaging attraction content written by our in-house copywriters",
      },
      {
        value: "Account Support Contact",
        tooltip: "Email access to our attraction support team",
      },
    ],
    messaging: <>400% Increase In Traffic</>,
    vat: "",
  },
  {
    id: "premium",
    title: "Premium",
    features: [
      `Max ${imageCount["PREMIUM"]} images`,
      {
        value: "Instant Content Updates",
        tooltip:
          "Changes to your listing copy are published instantly on the website",
      },
      "Attraction Info & FAQs",
      "Bespoke Attraction Description",
      "Opening Times & Pricing",
      "Address & Map View",
      {
        value: "Featured Website Link",
        tooltip: "Prominent, high-impact button linked to your own website",
      },
      {
        value: "Analytics & Insights",
        tooltip:
          "Live analytics on your listings impressions, views and clicks",
      },
      {
        value: "Reviews & Replies",
        tooltip: "Users can leave reviews directly on your listing page",
      },
      {
        value: "Ticket Manager",
        tooltip: "Start selling tickets within 30 minutes without any technical work required. Super easy!"
      },
      {
        value: "Optimised Content by DOWTK",
        tooltip:
          "Unique, engaging attraction content written by our in-house copywriters",
      },
    ],
    plus: [
      {
        value: "Guaranteed Top of Page One",
        tooltip:
          "Top of page for attractions in your area (within the first 5 attractions)",
      },
      {
        value: "Featured on Homepage",
        tooltip: "Your attraction will be featured on the DOWTK homepage",
      },
      {
        value: "Dedicated Account Manager",
        tooltip: "Email & Phone access to our attraction support manager",
      }, 
    ],
    messaging: <>700% Increase In Traffic</>,
    vat: "",
  },
];

export function ListingTypeSelect({
  venueId,
  defaultValue,
  disabled = false,
  showLabel = true,
  showStandardTrial = false,
  onSelectedPlanChange,
  isClaim,
  enhancedSelector = false,
  isOverviewTab = false,
  onNext,
}: {
  venueId: string;
  disabled?: boolean;
  defaultValue?: string;
  showLabel?: boolean;
  showStandardTrial?: boolean;
  onSelectedPlanChange?: (value: SubscriptionPlan) => void;
  isClaim: boolean;
  enhancedSelector?: boolean;
  isOverviewTab?: boolean;
  onNext?: () => Promise<void>;
}) {
  const [showingAnnual, setShowingAnnual] = useState(false);
  const packagesData = useMemo<
    (PackageDetails & { interval: string; cta?: string })[]
  >(() => {
    const basicPackage = packages.find((p) => p.id === "basic")!;
    const standardPackage = packages.find((p) => p.id === "standard")!;
    const enhancedPackage = packages.find((p) => p.id === "enhanced")!;
    const premiumPackage = packages.find((p) => p.id === "premium")!;

    return [
      {
        ...basicPackage,
        interval: ``,
      },
      {
        ...standardPackage,
        interval: `Billed ${showingAnnual ? "annually" : "monthly"}`,
        cta: showStandardTrial
          ? isOverviewTab
            ? "Try 1 Month FREE Trial"
            : "1 Month FREE Trial"
          : "",
      },
      {
        ...enhancedPackage,
        interval: `Billed ${showingAnnual ? "annually" : "monthly"}`,
      },
      {
        ...premiumPackage,
        interval: `Billed ${showingAnnual ? "annually" : "monthly"}`,
      },
    ];
  }, [showingAnnual, showStandardTrial]);

  const { data, isLoading } = apiSlice.useGetSubscriptionPlansQuery({
    venueId: venueId || "",
  });
  const [plans, setPlans] = useState<SubscriptionPlan[]>(
    (data as SubscriptionPlan[]) || []
  );

  const [prices, setPrices] = useState<
    Record<SubscriptionPlan["name"], SubscriptionPlan | undefined>
  >({
    basic: undefined,
    enhanced: undefined,
    event: undefined,
    premium: undefined,
    standard: undefined,
  });

  const [currentPlan, setCurrentPlan] = useState<SubscriptionPlan | undefined>(
    undefined
  );

  useEffect(() => {
    if (isLoading) return;

    const relevantPlans = data?.filter(
      (plan) =>
        (showingAnnual
          ? plan.billing_frequency === "annual"
          : plan.billing_frequency === "monthly") ||
        plan.billing_frequency === "one-off"
    );

    const basicPlan = relevantPlans?.find((plan) => plan.name === "basic");
    const eventPlan = relevantPlans?.find((plan) => plan.name === "event");
    const standardPlan = relevantPlans?.find(
      (plan) => plan.name === "standard"
    );
    const enhancedPlan = relevantPlans?.find(
      (plan) => plan.name === "enhanced"
    );
    const premiumPlan = relevantPlans?.find((plan) => plan.name === "premium");

    setPrices({
      basic: basicPlan,
      enhanced: enhancedPlan,
      event: eventPlan,
      premium: premiumPlan,
      standard: standardPlan,
    });

    setPlans(relevantPlans!);

    if (defaultValue) {
      const foundPlan = data?.find((plan) => plan.id === defaultValue);
      foundPlan && setCurrentPlan(foundPlan);

      setSelectedPlan(
        foundPlan?.name === "basic" && showStandardTrial
          ? standardPlan
          : foundPlan?.name === "standard" && enhancedSelector
          ? enhancedPlan
          : foundPlan
      );
    }
  }, [showingAnnual, data, isLoading]);

  const [selectedPlan, setSelectedPlan] = useState<
    SubscriptionPlan | undefined
  >();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (selectedPlan) {
      onSelectedPlanChange?.(selectedPlan);
      dispatch(globalActions.setSelectedPlan(selectedPlan));
      dispatch(
        globalActions.setIsSelectedPlanCurrent(
          selectedPlan?.id === currentPlan?.id
        )
      );
    }
  }, [selectedPlan, onSelectedPlanChange, currentPlan?.id, dispatch]);

  const [subscriptionCheckout] = apiSlice.useSubscriptionCheckoutMutation();
  const createSubscriptionCheckout = async (selectedPlanId: string) => {
    if (!selectedPlanId) return;
    const checkoutPage = await subscriptionCheckout({
      venueId: venueId!,
      planId: selectedPlanId,
    });

    if ("data" in checkoutPage) {
      const paymentUrl = checkoutPage?.data?.sessionUrl;
      if (!paymentUrl) return;
      window.location.href = new URL(paymentUrl).toString();
    }
  };

  const [launchPayment, setLaunchPayment] = useState(false);
  async function startPayment() {
    await new Promise((res) => setTimeout(res, 100));
    if (onNext) {
      return onNext();
    }
    const newPlan = plans?.find((r) => r.name === "standard");
    await createSubscriptionCheckout(
      selectedPlan?.id || newPlan?.id || "standard"
    );
  }

  useEffect(() => {
    if (launchPayment) {
      setLaunchPayment(false);
      startPayment();
    }
  }, [launchPayment]);

  return (
    <>
      {isLoading || !plans || plans?.length === 0 ? (
        <div className="w-full h-64 flex items-center justify-center">
          <LoadingIndicator />
          Loading Plans
        </div>
      ) : (
        <>
          {showLabel && (
            <label className="flex items-center mb-2 font-bold text-black md:whitespace-nowrap">
              Listing Type
            </label>
          )}

          <div className="flex justify-center my-9">
            <div className="bg-[#F2F2F2] py-[5px] px-[10px] rounded-full flex items-center gap-2.5">
              <button
                type="button"
                className={`transition-colors w-[157px] text-[#212121] text-sm font-semibold py-2.5 px-[15px] rounded-full hover:bg-white ${
                  showingAnnual ? "bg-white" : ""
                }`}
                onClick={() => setShowingAnnual(true)}
              >
                Annual <span className="text-[#61616D]">(save 20%)</span>
              </button>
              <button
                type="button"
                className={`transition-colors w-[157px] text-[#212121] text-sm font-semibold py-2.5 px-[15px] rounded-full hover:bg-white ${
                  !showingAnnual ? "bg-white" : ""
                }`}
                onClick={() => setShowingAnnual(false)}
              >
                Monthly
              </button>
            </div>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-2.5">
            {packagesData.map((p) => {
              const price = prices[p.id]?.price
                ? +(
                    prices[p.id]!.price /
                    100 /
                    (showingAnnual ? 12 : 1)
                  ).toFixed(0)
                : -1;
              const isSelected =
                currentPlan?.name === p.id &&
                (currentPlan.billing_frequency ===
                  (showingAnnual ? "annual" : "monthly") ||
                  currentPlan.id === "basic-plan-monthly");
              return (
                <Plan
                  {...p}
                  isSelected={isSelected}
                  isHighlighted={selectedPlan?.name === p.id}
                  isCurrent={currentPlan?.name === p.id}
                  disabled={
                    // currentPlan?.name === p.id &&
                    // (currentPlan.billing_frequency ===
                    //   (showingAnnual ? "annual" : "monthly") ||
                    //   currentPlan.id === "basic-plan-monthly")
                    false
                  }
                  isUpgrading={false}
                  price={p.id === "basic" ? 0 : price}
                  onClick={async () => {
                    const newPlan = plans?.find((r) => r.name === p.id);
                    if (!newPlan) return;
                    setSelectedPlan(newPlan);
                    onSelectedPlanChange?.(newPlan);
                    dispatch(globalActions.setSelectedPlan(newPlan));
                    dispatch(
                      globalActions.setIsSelectedPlanCurrent(
                        newPlan?.id === currentPlan?.id
                      )
                    );
                  }}
                  onButtonClick={async () => {
                    const newPlan = plans?.find((r) => r.name === p.id);
                    if (!newPlan) return;
                    setSelectedPlan(newPlan);
                    onSelectedPlanChange?.(newPlan);
                    dispatch(globalActions.setSelectedPlan(newPlan));
                    dispatch(
                      globalActions.setIsSelectedPlanCurrent(
                        newPlan?.id === currentPlan?.id
                      )
                    );

                    if (isOverviewTab) {
                      setLaunchPayment(true);
                    }
                  }}
                  customPricing={
                    p.id === "basic" ? (
                      <div>
                        <div className="text-[#212121] text-sm font-bold">
                          £
                          {prices[p.id]?.price
                            ? +(prices[p.id]!.price / 100).toFixed(0)
                            : -1}{" "}
                          Listing Fee
                        </div>
                        <div className="text-[#6A6E7E] text-xs font-semibold pt-[5px]">
                          One off charge for new listings
                        </div>
                      </div>
                    ) : null
                  }
                  isClaim={isClaim}
                  isOverviewTab={isOverviewTab}
                />
              );
            })}

            {/*             
            <div className="text-xs font-light mt-5 w-full text-center">
                <span className="!text-red-500 font-bold">*</span> Average results, with some partners achieving much greater results
            </div> */}
          </div>
        </>
      )}
    </>
  );
}

const Plan: React.FC<{
  id: string;
  className?: string;
  title: string;
  price?: number;
  features: (string | { value: string; tooltip: string })[];
  messaging: ReactNode;
  vat: string;
  plus?: (string | { value: string; tooltip: string })[];
  interval: string;
  cta?: string;
  mostPopular?: boolean;
  isSelected: boolean;
  isHighlighted: boolean;
  disabled: boolean;
  isUpgrading: boolean;
  onClick: Function;
  onButtonClick: Function;
  customPricing?: ReactNode;
  isClaim: boolean;
  isOverviewTab?: boolean;
  isCurrent: boolean;
}> = ({
  id,
  className,
  title,
  price,
  features,
  messaging,
  plus = [],
  interval,
  cta,
  mostPopular,
  isSelected,
  isHighlighted,
  disabled,
  isUpgrading,
  onClick,
  customPricing,
  isClaim,
  isOverviewTab,
  onButtonClick,
  isCurrent,
}) => {
  const nonNullPrice = price ?? 0;
  return (
    <div
      className={`${className} flex flex-col ${
        !isUpgrading ? "cursor-pointer" : ""
      }`}
      onClick={() => {
        if (!isUpgrading) {
          onClick();
        }
      }}
    >
      <div className="h-[26px] flex mb-[15px]">
        {mostPopular && (
          <div className="bg-[#F6F8FA] p-[5px] rounded-lg text-[#FB7037] text-xs font-bold">
            MOST POPULAR
          </div>
        )}
      </div>

      <div
        className={`flex-grow p-[15px] bg-white flex flex-col rounded-lg ${
          isHighlighted
            ? "border-2 border-[#6836D1]"
            : "border border-[#CFDBD5]"
        }`}
      >
        <h3 className="text-[#212121] font-bold text-lg leading-4">{title}</h3>
        <p className="text-[#212121] font-semibold text-xs leading-4 mt-[5px]">
          {messaging}
        </p>

        <div className="flex mt-5">
          <div className="text-[#212121] font-bold min-h-[44px]">
            <div>
            {id !== 'basic' && <span className="text-xs">from </span>}
              <span className="text-lg">{nonNullPrice > 0 ? `£${nonNullPrice}${id === 'basic' ? ' Listing Fee' : ''}` : ' '}</span>
              {id !== 'basic' && <span className="text-xs"> per month</span>}
            </div>
            <div className="text-xs text-[#6A6E7E] font-semibold">
              {interval}
            </div>
          </div>
        </div>

        <button
          type="button"
          className={`my-5 py-2.5 text-center font-bold text-sm rounded-full text-white flex justify-center ${
            isHighlighted ? "bg-[#6836D1]" : "bg-[#999999]"
          }`}
          disabled={disabled}
          onClick={() => onButtonClick()}
        >
          {isCurrent
            ? `Current Plan`
            : cta
            ? cta
            : isOverviewTab && ["enhanced", "premium"].includes(id)
            ? `Upgrade to ${title} Plan`
            : `Select ${title} Plan`}
        </button>

        <ul>
          {features.map((feature, index) => (
            <PointItem
              key={index}
              feature={feature}
              fontWeight="font-semibold"
            />
          ))}
        </ul>

        {plus.length > 0 && (
          <>
            <p className="my-1.5 font-extrabold text-xs text-[#555555]">PLUS</p>
            <ul>
              {plus.map((feature, index) => (
                <PointItem
                  fontWeight={"font-semibold"}
                  key={index}
                  feature={feature}
                />
              ))}
            </ul>
          </>
        )}

        {customPricing && !isClaim && (
          <div className="mt-auto">{customPricing}</div>
        )}
      </div>
    </div>
  );
};

const PointItem = ({
  feature,
  key,
  fontWeight = "font-normal",
}: {
  feature: string | { value: string; tooltip: string };
  key: string | number;
  fontWeight?: string;
}) => {
  return (
    <li
      key={key}
      className={`text-xs text-[#212121] ${fontWeight} mb-2 flex items-center gap-2`}
    >
      {typeof feature === "string" ? feature : feature.value}

      {typeof feature !== "string" && feature.tooltip && (
        <Tooltip>
          <TooltipTrigger>
            <svg
              width="11"
              height="12"
              viewBox="0 0 11 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.95801 4.29484H6.04134V3.21151H4.95801M5.49967 10.2532C3.11092 10.2532 1.16634 8.30859 1.16634 5.91984C1.16634 3.53109 3.11092 1.58651 5.49967 1.58651C7.88842 1.58651 9.83301 3.53109 9.83301 5.91984C9.83301 8.30859 7.88842 10.2532 5.49967 10.2532ZM5.49967 0.503174C4.78835 0.503174 4.08399 0.64328 3.42681 0.915493C2.76963 1.18771 2.1725 1.58669 1.66951 2.08968C0.653691 3.1055 0.0830078 4.48325 0.0830078 5.91984C0.0830078 7.35643 0.653691 8.73418 1.66951 9.75C2.1725 10.253 2.76963 10.652 3.42681 10.9242C4.08399 11.1964 4.78835 11.3365 5.49967 11.3365C6.93626 11.3365 8.31401 10.7658 9.32984 9.75C10.3457 8.73418 10.9163 7.35643 10.9163 5.91984C10.9163 5.20851 10.7762 4.50415 10.504 3.84697C10.2318 3.18979 9.83282 2.59266 9.32984 2.08968C8.82685 1.58669 8.22972 1.18771 7.57254 0.915493C6.91536 0.64328 6.211 0.503174 5.49967 0.503174ZM4.95801 8.62817H6.04134V5.37817H4.95801V8.62817Z"
                fill="#616C7A"
              />
            </svg>
          </TooltipTrigger>
          <TooltipContent className="Tooltip">
            <div className="w-[144px] translate-y-2">
              <div className="bg-[#0A0A0A] p-2.5 text-white text-xs font-medium">
                {feature.tooltip}
              </div>

              <svg
                width="144"
                height="11"
                viewBox="0 0 144 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="-translate-y-1"
              >
                <path
                  d="M80.5 2L72 10.5L64 2H0V0.5L144 0V2H80.5Z"
                  fill="black"
                />
              </svg>
            </div>
          </TooltipContent>
        </Tooltip>
      )}
    </li>
  );
};

const LoadingIndicator = ({ className }: { className?: string }) => {
  return (
    <svg
      className={`w-5 h-5 mr-3 -ml-1 animate-spin ${
        className || "text-cs-pink"
      }`}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      ></circle>
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  );
};
