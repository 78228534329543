import AsyncSelect from "react-select/async";
import SingleSelectOption from "../../lib/react-select/SingleSelectOption";
import { useCallback, useState } from "react";
import { apiSlice } from "../../store/apiSlice";
import { type AttractionMinimal } from "../global/www/modals/CreateClaimModal";
import { debounce } from "../../helpers/debounce";

type Media = {
  url: string;
  id: string;
};
type SearchOption = {
  label: string | JSX.Element;
  value: string;
  attraction: AttractionMinimal;
  media?: Media[];
};
export function AttractionSearch({
  onChange,
  onMediaChange,
  value,
  defaultOptions,
}: {
  onChange: (value: string) => void;
  onMediaChange?: (value: { url: string; id: string }[]) => void;
  value: string;
  defaultOptions: SearchOption;
}) {
  const [trigger] = apiSlice.useLazySearchAttractionsQuery();
  const filterAttractions = (items: AttractionDetails[]): SearchOption[] => {
    const options = [
      ...items
        .map((item) => ({
          value: item.short_id,
          label: item.name ?? "",
          attraction: {
            id: (item.id as string) ?? "",
            title: item.name ?? "",
            image: item.display_image?.url ?? "",
            image_alt: item.display_image?.alt_text ?? "",
            location: item.town?.name ?? "",
          },
          media: item.media,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    ];
    if (options.filter(o => !!o.value).length === 0) {
      return [defaultOptions];
    }

    return options;
  };

  let currentOptions = filterAttractions([])

  const loadOptions = (
    inputValue: string,
    callback: (options: SearchOption[]) => void
  ) => {
    trigger(inputValue)
      .unwrap()
      .then((data) => {
        const options = filterAttractions(data?.results ?? [])
        currentOptions = options;
        callback(options);
      });
  };

  const loadOptionsDebounced = useCallback(
    debounce((inputValue: string, callback: (options: any) => void) => {
      loadOptions(inputValue, callback);
    }, 1000),
    []
  );

  return (
    <>
      <AsyncSelect
        components={{
          Option: SingleSelectOption,
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          LoadingIndicator: () => <LoadingIndicator />,
        }}
        cacheOptions
        loadOptions={loadOptionsDebounced as typeof loadOptions}
        defaultOptions={[]}
        value={value ? currentOptions.find(
          (v) => v.value === value || v.attraction?.id === value
        ) : undefined}
        noOptionsMessage={() => null}
        isSearchable={true}
        placeholder="Search attractions"
        styles={{
          menu: (styles) => ({
            ...styles,
            margin: 0,
            border: "1px solid #F1F1F1",
            boxShadow: "none",
          }),
          menuList: (base, isSelected) => ({
            ...base,
            paddingTop: 0,
            paddingBottom: 0,
            background: "white",
            color: "#000",

            "::-webkit-scrollbar": {
              width: 14,
            },
            "::-webkit-scrollbar-thumb": {
              background: "#c2c2c2",
              borderRadius: 10,
              border: "4px solid white",
            },
          }),
          option: (base, { data, isDisabled, isFocused, isSelected }) => {
            let extra =
              data.value === "-1"
                ? {
                    background: "#eee",
                  }
                : {
                    background: "white",
                  };
            return {
              ...base,
              border: "1px solid #F1F1F1",
              color: isSelected ? "white" : "#555",
              "&:hover": {
                backgroundColor: "#F1F1F1",
                color: "#000",
                border: isFocused ? "1px solid white" : "",
              },
              "&:active": {
                backgroundColor: "#6836D1",
                color: "white",
              },
              ...extra,
              height: "auto !important",
              padding: "5px 10px !important",
              fontSize: 14,
            };
          },
          singleValue: (base) => ({
            ...base,
            color: "#000",
            fontSize: 14,
          }),
          control: (styles, state) => ({
            ...styles,
            cursor: "pointer",
            outline: "none",
            border: state.isFocused ? "1px solid #6836D1" : "1px solid #CFDBD5",
            "&:hover": {
              border: state.isFocused
                ? "1px solid #6836D1"
                : "1px solid #6836D1",
            },
            boxShadow: "none",
            margin: 0,
            height: "40px",
            borderRadius: 8,
            fontSize: 14,
            fontWeight: 400,
          }),
        }}
        onChange={(value) => {
          if (!value) return;
          onChange(value?.attraction?.id);
          onMediaChange?.(value?.media ?? []);
        }}
      />
    </>
  );
}

const LoadingIndicator: React.FC = () => {
  return (
    <svg
      className="w-5 h-5 mr-3 -ml-1 animate-spin text-cs-pink"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      ></circle>
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  );
};
