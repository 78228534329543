import { VenueOptTask } from "../pages/amp/attractions/AttractionVenueOptScore";
import { useAppSelector } from "../store";

type Result = Record<
  string,
  Record<string, VenueOptTask | (VenueOptTask | undefined)[] | undefined>
>;
export function useVoScoreNeedImprovementsFields({
  allMetrics,
  completedMetrics,
  watched,
  dirtyFields,
  schema,
  results,
}: {
  allMetrics: VenueOptTask[];
  completedMetrics: AttractionDetails["published_version"]["optimisation_scores"];
  watched?: Record<string, any>;
  dirtyFields?: Record<string, boolean | undefined>;
  schema?: any;
  results: AttractionDetails | undefined
}) {
  const { user } = useAppSelector((state) => state.global);
  const incomplete = allMetrics?.filter(
    (metric) =>
      !completedMetrics?.find((s) => s.metric_key === metric.key)?.fulfilled
  );
  const isStepperActive = !results?.finished_onboarding;
  const isClaim = results?.ownership_claim?.created_by_user?.email !== results?.creation_claim?.created_by_user?.email;
  const findBasedOnKey = (key: string) => {
    if (user?.role !== 'ADMIN') {
      if (!results?.listing_package || results?.listing_package === 'BASIC') return undefined
      if (!isClaim && !results?.creation_claim?.submit_approval) return undefined
      if (isStepperActive) return undefined
    }
    return incomplete.find((s) => s.key === key);
  };

  const result: Result = {
    ATTRACTION_GENERAL: {
      phoneNumber: (!results?.phone && !results?.no_phone_number) ? findBasedOnKey("attraction_contact_details") : undefined,
      websiteAddress: (!results?.website || !isValidURL(results?.website)) ? findBasedOnKey("attraction_contact_details") : undefined,
    },
    ATTRACTION_DETAILS: {
      activityOptions: findBasedOnKey("basic_details"),
      primaryCategory: findBasedOnKey("basic_details"),
      minSuitableAge: findBasedOnKey("basic_details"),
      maxSuitableAge: findBasedOnKey("basic_details"),
      suitableForAdults: findBasedOnKey("basic_details"),
      suggestionDuration: findBasedOnKey("basic_details"),
      facilities: findBasedOnKey("basic_details"),

      highlight1: findBasedOnKey("attraction_highlights"),
      highlight1_description: findBasedOnKey("attraction_highlights"),

      highlight2: findBasedOnKey("attraction_highlights"),
      highlight2_description: findBasedOnKey("attraction_highlights"),

      highlight3: findBasedOnKey("attraction_highlights"),
      highlight3_description: findBasedOnKey("attraction_highlights"),

      descriptionHtml: findBasedOnKey("attraction_long_description")
    },
    ATTRACTION_LOCATION: {
  
    },
    ATTRACTION_OPENING_TIMES: {
      opening_times_json: !results?.opening_times_json ? findBasedOnKey("opening_times_and_pricing") : undefined,
    },
    ATTRACTION_PRICES: {
      pricing_json: !results?.pricing_json ? findBasedOnKey("opening_times_and_pricing") : undefined,
      ticketBookable: findBasedOnKey('tickets_bookable'),
      vipDiscount: findBasedOnKey('vip_pass_discount')
    },
    ATTRACTION_FAQS: {
      faqs_json: findBasedOnKey("faqs"),
    },
    ATTRACTION_IMAGES: {
      images: [
        findBasedOnKey('attraction_images')
      ],
    },
  };

  return result;
}

export function isTabRequireImprovements(tab: string, result: Result) {
  return (
    Object.entries(result[tab] || {})
      .map((s) => s[1])
      .flat()
      .filter((s) => s).length > 0
  );
}
export function getVoScoreTooltip(
  task: VenueOptTask | undefined | (VenueOptTask | undefined)[]
) {
  if (Array.isArray(task)) {
    const single = task.filter((s) => s)[0];
    return single?.description || single?.display || "";
  }

  const tooltip = task?.description || task?.display || ""
  const overrides: Record<string, string> =  {
    "4 - 12 Images": "Upload between 4-12 images to increase your VO Score"
  }
  return overrides?.[tooltip] || tooltip;
}

export function isLightningIconActive(task: VenueOptTask | undefined | (VenueOptTask | undefined)[]) {
  if (Array.isArray(task)) {
    return task.filter((s) => s).length > 0
  }
  return !!task
}
function isValidURL(string: string) {
  const res = string.match(/(https?:\/\/[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/[a-zA-Z0-9]+\.[^\s]{2,}|[a-zA-Z0-9]+\.[^\s]{2,})/gi);

  return (res !== null);
};