import { useEffect, type ReactNode } from "react";
import { useAppDispatch, useAppSelector } from "../../store";
import { useWindowResize } from "../../hooks/useWindowResize";
import { globalActions } from "../../store/globalSlice";

export default function DashboardHeaderContainer({
  children,
  slim = false
}: {
  children: ReactNode;
  slim?: boolean
}) {
  const dispatch = useAppDispatch();
  const windowSize = useWindowResize();

  const {isNavigationOpen} = useAppSelector(state => state.global);
  useEffect(() => {
    (windowSize?.width ?? 0) < 1280 && isNavigationOpen ?
        document.body.classList.add('overflow-hidden')
    :
        document.body.classList.remove('overflow-hidden')
}, [isNavigationOpen, windowSize?.width])

  return (
    <>
      <header className={`min-h-[85px] py-[22px] px-5 ${slim ? 'xl:px-[20px]' : 'xl:px-[70px]'} border-b border-[#CFDBD5] flex items-center`}>
        <div className="flex items-center flex-wrap gap-y-2 xl:pl-[300px] flex-grow">
          <button
            id="hamburger"
            className={`${isNavigationOpen ? "open" : ""} !ml-0 mr-2 xl:hidden block flex-shrink-0`}
            onClick={() =>
              dispatch(globalActions.setIsNavigationOpen(isNavigationOpen))
            }
          >
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </button>
          {children}
        </div>
      </header>
    </>
  );
}
