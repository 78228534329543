import React, { useEffect, useRef, useState } from "react";
import {
  TableInstance,
  usePagination,
  UsePaginationInstanceProps,
  UsePaginationState,
  useTable,
  Column,
  useGlobalFilter,
  useSortBy,
  UseSortByInstanceProps,
  CellProps,
} from "react-table";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import Table from "../../../lib/react-table/Table";
import Footer from "../../../components/global/amp/Footer";
import Select from "react-select";
import SingleSelectOption from "../../../lib/react-select/SingleSelectOption";
import moment from "moment";
import { useWindowResize } from "../../../hooks/useWindowResize";
import { apiSlice } from "../../../store/apiSlice";
import DashboardContainer from "../../../components/amp/DashboardContainer";
import DashboardHeaderContainer from "../../../components/amp/DashboardHeaderContainer";
import { SearchField } from "../../../components/global/SearchField";
import { Button } from "../../../components/global/Button";
import { Colors, TableText } from "../../../components/global/TableText";
import ContextMenu from "../../../components/global/ContextMenu";
import { TableTextNormal } from "../../../components/global/TableTextNormal";
import { VenueOptScoreProgressCircular } from "../../../components/amp/VenueOptScoreProgressCircular";

type TableInstanceWithHooks<T extends object> = TableInstance<T> &
  UsePaginationInstanceProps<T> &
  UseSortByInstanceProps<T> & {
    state: UsePaginationState<T>;
  };

const Attractions: React.FC = () => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [getQueryPageSize, setQueryPageSize] = useState<string>("25");
  const [getQueryPage, setQueryPage] = useState<string>(
    searchParams.get("page")
      ? parseInt(searchParams?.get("page") ?? "0").toString()
      : "0"
  );
  const [getQuerySortBy, setQuerySortBy] = useState<string>("name");
  const [getQuerySearchQuery, setQuerySearchQuery] = useState<string>("");
  const [getQuerySortDesc, setQuerySortDesc] = useState<string>("false");
  const [getFilter, setFilter] = useState<
    | "all"
    | "approved"
    | "submitted"
    | "verified"
    | "basic"
    | "premium"
    | "enhanced"
    | "standard"
  >("all");

  const prevQuerySearch = useRef(getQuerySearchQuery);
  const { data: tableData, isFetching } = apiSlice.useGetAttractionsListQuery({
    pageSize: getQueryPageSize,
    page: prevQuerySearch.current !== getQuerySearchQuery ? "0" : getQueryPage,
    sortBy: getQuerySortBy,
    query: getQuerySearchQuery,
    sortDesc: getQuerySortDesc,
    filter: getFilter,
  });

  const data = React.useMemo<AttractionDetails[]>(
    () => tableData?.results ?? ([] as AttractionDetails[]),
    [tableData]
  );

  function getListingPackageName(text: AttractionDetails["listing_package"]) {
    return text[0] + text.toLowerCase().slice(1);
  }

  const columns = React.useMemo<Column<AttractionDetails>[]>(
    () => [
      {
        Header: "Attraction Name",
        accessor: "name",
        Cell: ({ row: { original } }: CellProps<AttractionDetails>) => {
          return (
            <>
              <Link
                to={`/attractions/${original.short_id}`}
                state={{ from: "/dashboard/attractions" + location.search }}
                className="block w-auto truncate font-bold text-sm text-black"
              >
                {original.name}
              </Link>
            </>
          );
        },
        sortType: (a: any, b: any) => {
          if (a.original.venue["name"] > b.original.venue["name"]) return 1;
          if (b.original.venue["name"] > a.original.venue["name"]) return -1;
          return 0;
        },
        width: "37.97%",
      },
      {
        Header: "Type",
        accessor: "listing_package",
        Cell: ({ row: { original } }: CellProps<AttractionDetails>) => {
          const color: Record<string, Colors> = {
            BASIC: "default",
            STANDARD: "blue",
            PREMIUM: "orange",
            ENHANCED: "purple",
          };
          return (
            <>
              <TableText
                className="min-w-[8rem]"
                color={color[original.listing_package]}
              >
                {getListingPackageName(original.listing_package)}
              </TableText>
            </>
          );
        },
        width: "9.58%",
        id: "listing_package",
      },
      {
        Header: "Updated",
        accessor: "updated_at",
        sortType: (a: any, b: any) => {
          const previousRow = a.original.updated_at;
          const nextRow = b.original.updated_at;

          if (previousRow < nextRow) {
            return 1;
          } else {
            return -1;
          }
        },
        Cell: ({ row: { original } }: CellProps<AttractionDetails>) => {
          return (
            <>
              <TableTextNormal className="min-w-[8rem]">
                {moment(original.updated_at).format("DD/MMM/YYYY")}
              </TableTextNormal>
            </>
          );
        },
        width: "9.81%",
      },
      {
        Header: "Claimed",
        accessor: "claims",
        Cell: ({ row: { original } }: CellProps<AttractionDetails>) => {
          const claimed =
            original.claims.filter(
              (c) => c.status === "APPROVED" || c.status === "APPROVED_NEW"
            ).length <= 0
              ? false
              : true;
          return (
            <>
              <TableText
                className="min-w-[5rem]"
                color={claimed ? "lime" : "default"}
              >
                {claimed ? "Claimed" : "Unclaimed"}
              </TableText>
            </>
          );
        },
        width: "9.58%",
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ row: { original } }: CellProps<AttractionDetails>) => {
          return (
            <>
              <TableText
                className={`min-w-[110px] ${
                  original.status_display !== "Open"
                    ? "text-[10px] whitespace-nowrap"
                    : ""
                }`}
                color={original.status_display === "Open" ? "lime" : "red"}
              >
                {original.status_display}
              </TableText>
            </>
          );
        },
        width: "11.72%",
      },
      {
        Header: "VO Score",
        accessor: "total_vo_score",
        Cell: ({ row: { original } }: CellProps<AttractionDetails>) => {
          return (
            <>
              <VenueOptScoreProgressCircular
                score={original.total_vo_score || 0}
                maxScore={original.max_possible_vo_score || 100}
                size={54}
                color={
                  (original.total_vo_score || 0) < 50
                    ? "stroke-[#FF6D00]"
                    : "stroke-[#68CE2C]"
                }
              >
                <div className="flex justify-center flex-col items-center text-sm">
                  {original.total_vo_score}
                  <div className="w-[32px] h-[1px] bg-black"></div>
                  {original.max_possible_vo_score || 100}
                </div>
              </VenueOptScoreProgressCircular>
            </>
          );
        },
        width: "7%",
      },
      // {
      //   Header: "Views",
      //   accessor: "total_views",
      //   Cell: ({ row: { original } }: CellProps<AttractionDetails>) => {
      //     return <TableTextNormal>{original.total_views}</TableTextNormal>;
      //   },
      //   width: "7%",
      // },
      {
        Header: "",
        accessor: "id",
        disableSortBy: true,
        Cell: ({ row: { original } }: CellProps<AttractionDetails>) => {
          return (
            <div className="w-14 flex justify-center">
              <ContextMenu
                options={[
                  {
                    name: "View / Edit",
                    linkProps: {
                      to: `/attractions/${original.short_id}`,
                      state: {
                        from: "/dashboard/attractions" + location.search,
                      },
                    },
                  },
                ]}
              />
            </div>
          );
        },
        disableGlobalFilter: true,
        width: "6.91%",
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    setGlobalFilter,
    pageOptions,
    gotoPage,
    setPageSize,
    setSortBy,
    state: { pageSize, pageIndex, sortBy, globalFilter },
  } = useTable<AttractionDetails>(
    {
      columns,
      data,
      autoResetPage: false,
      manualPagination: true,
      manualSortBy: true,
      pageCount: tableData?.total,
      disableSortRemove: true,
      initialState: {
        pageSize:
          parseInt(searchParams.get("pageSize")!) || parseInt(getQueryPageSize),
        pageIndex: parseInt(getQueryPage),
        globalFilter: searchParams.get("query") || getQuerySearchQuery,
        sortBy: [
          {
            id: searchParams.get("sortBy") || getQuerySortBy,
            desc:
              searchParams.get("sortDesc") === "true" ||
              getQuerySortDesc !== "false",
          },
        ],
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  ) as TableInstanceWithHooks<AttractionDetails>;

  const handleFilterInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setGlobalFilter(value);
  };

  const handleFilterSelectChange = (e: { value: string; desc: boolean }) => {
    setSortBy([{ id: e.value, desc: e.desc ? true : false }]);
  };

  const allOptions: { value: string; label: string; desc: boolean }[] | any = [
    { value: "name", label: "Attraction name (A - Z)", desc: false },
    { value: "name", label: "Attraction name (Z - A)", desc: true },
    { value: "postcode", label: "Postcode (A - Z)", desc: false },
    { value: "postcode", label: "Postcode (Z - A)", desc: true },
    { value: "status", label: "Opening status", desc: true },
    { value: "updated_at", label: "Last updated (recent first)", desc: false },
    { value: "updated_at", label: "Last updated (recent last)", desc: true },
  ];

  useEffect(() => {
    document.title = "Attractions | Day Out With The Kids";
  }, []);

  useEffect(() => {
    if (getQuerySearchQuery !== prevQuerySearch.current) {
      setQueryPage("0");
      gotoPage(0);

      prevQuerySearch.current = getQuerySearchQuery;
    }
  }, [getQuerySearchQuery]);
  useEffect(() => {
    if (!isFetching) {
      setSearchParams(
        {
          pageSize: pageSize.toString(),
          page: pageIndex.toString(),
          query: globalFilter ? globalFilter : "",
          sortBy: sortBy[0].id.toLowerCase(),
          sortDesc: sortBy[0].desc!.toString(),
        },
        { replace: JSON.stringify(searchParams) === "{}" }
      );

      setQueryPageSize(pageSize.toString());
      setQueryPage(pageIndex.toString());
      setQuerySearchQuery(globalFilter ? globalFilter : "");
      setQuerySortBy(sortBy[0].id.toLowerCase());
      setQuerySortDesc(sortBy[0].desc!.toString());

      prevQuerySearch.current = getQuerySearchQuery;
    } else {
    }
  }, [
    pageSize,
    pageIndex,
    sortBy[0].id,
    globalFilter,
    sortBy[0].desc,
    isFetching,
  ]);

  function escapeCsvField(field: string) {
    if (typeof field === "string") {
      return `"${field.replace(/"/g, '""')}"`;
    }
    return field;
  }

  function convertObjectToCsv(data: any) {
    const csvRows = [];
    const headers = Object.keys(data[0]);
    csvRows.push(headers.join(","));

    for (const row of data) {
      const values = headers.map((header) => escapeCsvField(row[header]));
      csvRows.push(values.join(","));
    }

    return csvRows.join("\n");
  }

  function exportCSV() {
    const csvString = convertObjectToCsv(
      data.map((attraction) => {
        const claimed =
          attraction.claims.filter(
            (c) => c.status === "APPROVED" || c.status === "APPROVED_NEW"
          ).length > 0;
        return {
          "Attraction Name": attraction.name,
          Type: getListingPackageName(attraction.listing_package),
          "Updated At": moment(attraction.updated_at).format("DD/MMM/YYYY"),
          Claimed: claimed ? "Claimed" : "Unclaimed",
          Status: attraction.status_display,
          "VO Score": `${attraction.total_vo_score || 0}/${
            attraction.max_possible_vo_score || 100
          }`,
        };
      })
    );
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `export-attractions-${new Date().toLocaleDateString()}.csv`
    );

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (
    <div className="flex flex-col flex-1 bg-[#F5F5FA] doodle-bg">
      <DashboardHeaderContainer slim={true}>
        <h1 className="font-extrabold text-xl text-black mr-7">Attractions</h1>

        <SearchField
          parentClassName="max-w-full w-[437px] ml-auto"
          placeholder="Search for an attraction, location, operator or manager"
          defaultValue={searchParams.get("query") ?? ""}
          onChange={handleFilterInputChange}
        />

        <div className="flex ml-2.5 w-full xl:w-auto">
          <Link to="/search-claim">
            <Button>Add listing</Button>
          </Link>
        </div>
      </DashboardHeaderContainer>

      <DashboardContainer>
        <div className="px-5 xl:px-[20px] flex xl:flex-row flex-col gap-y-2">
          <div className="flex flex-wrap gap-2.5">
            <Button
              color={getFilter === "all" ? "primary" : "outlineGray"}
              scale={"md"}
              onClick={() => setFilter("all")}
            >
              All Listings
            </Button>
            <Button
              color={getFilter === "basic" ? "primary" : "outlineGray"}
              scale={"md"}
              onClick={() => setFilter("basic")}
            >
              Basic
            </Button>
            <Button
              color={getFilter === "premium" ? "primary" : "outlineGray"}
              scale={"md"}
              onClick={() => setFilter("premium")}
            >
              Premium
            </Button>
            <Button
              color={getFilter === "enhanced" ? "primary" : "outlineGray"}
              scale={"md"}
              onClick={() => setFilter("enhanced")}
            >
              Enhanced
            </Button>
            <Button
              color={getFilter === "standard" ? "primary" : "outlineGray"}
              scale={"md"}
              onClick={() => setFilter("standard")}
            >
              Standard
            </Button>
          </div>

          <div className="flex justify-between items-center xl:ml-auto">
            <div className="block xl:hidden min-w-[100px]">
              {/* <span className="font-bold text-xs text-black xl:hidden">
                Sort by
              </span> */}
              <Select
                className="xl:hidden"
                defaultValue={allOptions[0]}
                components={{
                  Option: SingleSelectOption,
                  DropdownIndicator: () => (
                    <div className="px-2.5">
                      <svg
                        width="10"
                        height="6"
                        viewBox="0 0 10 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.55469 0.353516L5 3.79883L8.44531 0.353516L9.5 1.4082L5 5.9082L0.5 1.4082L1.55469 0.353516Z"
                          fill="black"
                        />
                      </svg>
                    </div>
                  ),
                  IndicatorSeparator: () => null,
                }}
                onChange={handleFilterSelectChange}
                options={allOptions}
                isSearchable={false}
                styles={{
                  menu: (styles) => ({
                    ...styles,
                    margin: 0,
                    border: "none",
                  }),
                  menuList: (base, isSelected) => ({
                    ...base,
                    paddingTop: 0,
                    paddingBottom: 0,
                    background: "white",

                    "::-webkit-scrollbar": {
                      width: 14,
                    },
                    "::-webkit-scrollbar-thumb": {
                      background: "#c2c2c2",
                      borderRadius: 10,
                      border: "4px solid white",
                    },
                  }),
                  option: (
                    base,
                    { data, isDisabled, isFocused, isSelected }
                  ) => {
                    return {
                      ...base,
                      background: "white",
                      border: "1px solid #c2c2c2",
                      color: isSelected ? "white" : "#555",
                      "&:hover": {
                        backgroundColor: "#6836D1",
                        color: isSelected || isFocused ? "white" : "#555",
                        border: isFocused ? "1px solid white" : "",
                      },
                      "&:active": {
                        backgroundColor: "#6836D1",
                        color: "white",
                      },
                    };
                  },
                  singleValue: (base) => ({
                    ...base,
                    color: "#555",
                    fontSize: 14,
                  }),
                  control: (styles, state) => ({
                    ...styles,
                    cursor: "pointer",
                    outline: "none",
                    border: state.isFocused
                      ? "1px solid #CED3CF"
                      : "1px solid #CED3CF",
                    "&:hover": {
                      border: state.isFocused
                        ? "1px solid #CED3CF"
                        : "1px solid #CED3CF",
                    },
                    boxShadow: "none",
                    paddingTop: 3,
                    paddingBottom: 3,
                    margin: 0,
                    fontSize: 12,
                    borderRadius: 8,
                    maxHeight: 36,
                    width: "100%",
                  }),
                }}
              />
            </div>
            <Button
              color="outlineGray"
              scale="md"
              className="gap-2"
              onClick={() => {
                setSortBy([
                  {
                    id: "name",
                    desc: getQuerySortDesc === "false" ? true : false,
                  },
                ]);
              }}
            >
              <svg
                className={`${
                  getQuerySortDesc === "false" ? `rotate-180` : ""
                } transition-transform`}
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3 6.95215H21M6 12.9521H18M9 18.9521H15"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
              Sort A-Z
            </Button>

            <Button
              color="outlineGray"
              scale="md"
              className="gap-2 ml-2.5"
              onClick={() => {
                exportCSV();
              }}
            >
              Export CSV
            </Button>
          </div>
        </div>
        <section className="flex flex-col px-5 xl:px-[20px]">
          <div
            id="attractionsTable"
            className="mt-5 max-w-full sm:max-w-[calc(100vw-3rem)]"
          >
            <Table<AttractionDetails>
              // <table id>
              tableId="attractionsTable"
              // <table className>
              tableClassNames="border-none w-full border-separate border-spacing-0 relative min-w-[70vw] min-h-cs-50 xl:min-h-cs-100 [@media(max-width:1279px)]:block"
              // <thead className>
              tableHeadTrThClassNames="relative text-sm font-bold text-black text-left px-4 py-5 hidden last-of-type:hidden xl:table-cell whitespace-nowrap first-of-type:rounded-tl-xl first-of-type:rounded-bl-xl last-of-type:rounded-tr-xl last-of-type:rounded-br-xl"
              // <tbody className>
              tableTbodyClassNames={`relative ${
                page.length === 0 ? "h-[60px]" : ""
              }`}
              // Mobile <tbody className>
              tableTbodyMobileClassNames="block"
              // <tbody><tr className>
              tableTbodyTrClassNames="h-1 last-of-type:hidden"
              // Empty <tbody><tr className>
              tableTbodyTrEmptyMobileClassNames="border-none"
              // Mobile <tbody><tr className>
              tableTbodyTrMobileClassNames="flex flex-col min-h-cs-64 relative bg-white border-2 border-cs-off-white font-sm font-normal text-cs-gray rounded-xl p-3"
              // <tbody><tr><td className>
              tableTbodyTrTdClassNames="bg-white px-4 py-5 break-all first-of-type:rounded-tl-xl first-of-type:rounded-bl-xl last-of-type:rounded-tr-xl last-of-type:rounded-br-xl"
              // Empty <tbody><tr><td className>
              tableTbodyTrEmptyTdClassNames="shadow-none border-l-none border-r-cs-1 border-cs-off-white p-1 last-of-type:border-r-0"
              // Mobile <tbody><tr><td className>
              tableTbodyTrEmptyTdMobileClassNames="h-2"
              // Hide Columns On Mobile
              hideColumnOnMobile={["Last Logged In", "Status", "Attractions"]}
              // react-table props
              getTableProps={getTableProps}
              getTableBodyProps={getTableBodyProps}
              headerGroups={headerGroups}
              page={page}
              pages={tableData?.total}
              prepareRow={prepareRow}
              pageIndex={pageIndex}
              pageSize={pageSize}
              pageOptions={pageOptions}
              gotoPage={gotoPage}
              setPageSize={setPageSize}
              isFetching={isFetching}
            />
          </div>
        </section>
      </DashboardContainer>

      <Footer />
    </div>
  );
};

export default Attractions;
