import { type RouteKeys } from "../App";

const STORAGE_KEY = "new_attractions_tab_permissions";

type FilterKeys<T extends string> = T extends `ATTRACTION_${infer U}` ? T : never;
type AttractionKeys = FilterKeys<RouteKeys>
type Permission = Record<AttractionKeys, 'LOCKED' | "UNLOCKED">

type Storage = {
    [attractionId: string]: Permission
}

function getStorage() {
    const raw = localStorage.getItem(STORAGE_KEY);
    if (!raw) return undefined;

    return JSON.parse(raw) as Storage
}
export function getNewAttractionPermissionFromStorage(attractionId: string) {
    const storage = getStorage();
    return storage?.[attractionId]
}
export function initiateNewAttractionPermission(attractionId: string) {
    const storage = getStorage() ?? {};
    if (storage[attractionId]) return;
    storage[attractionId] = {
        ATTRACTION_GENERAL: 'UNLOCKED',
        ATTRACTION_CHANGE_LOG: "LOCKED",
        ATTRACTION_DETAILS: "LOCKED",
        ATTRACTION_FAQS: "LOCKED",
        ATTRACTION_IMAGES: "LOCKED",
        ATTRACTION_LOCATION: "LOCKED",
        ATTRACTION_OPENING_TIMES: "LOCKED",
        ATTRACTION_PREMIUM_ENHANCED: "LOCKED",
        ATTRACTION_PRICES: "LOCKED",
        ATTRACTION_UPGRADE_PLAN: "LOCKED",
        ATTRACTION_VENUE_OPT_SCORE: "LOCKED",
        ATTRACTION_PAY_PUBLISH: "LOCKED",
        ATTRACTION_TICKETS: "LOCKED"
    }
    localStorage.setItem(STORAGE_KEY, JSON.stringify(storage))
}
export function clearNewAttractionPermission(attractionId: string) {
    const storage = getStorage() ?? {};
    delete storage[attractionId];
    localStorage.setItem(STORAGE_KEY, JSON.stringify(storage))
}
export function unlockNewAttractionTab(attractionId: string, key: AttractionKeys) {
    initiateNewAttractionPermission(attractionId);

    const storage = getStorage() ?? {};

    storage[attractionId]![key] = "UNLOCKED";

    localStorage.setItem(STORAGE_KEY, JSON.stringify(storage))
}

const alwaysLockedTabs: string[] = [] satisfies AttractionKeys[]
export function isTabActive(attractionId: string, key: string) {
    if (alwaysLockedTabs.includes(key)) return 'LOCKED';

    const storage = getStorage();
    if (!storage) return "UNLOCKED"; // Return true if storage not found (we don't want to block users from accessing the tabs)
    if (key === 'ATTRACTION_GENERAL') return 'UNLOCKED';
    return storage[attractionId]?.[key as AttractionKeys] || "LOCKED"
}
export function isVOScoreUnlocked(attractionId: string) {
    const tabs = getNewAttractionPermissionFromStorage(attractionId);
    if (tabs) {
        return tabs.ATTRACTION_VENUE_OPT_SCORE === 'UNLOCKED'
    } else {
        return true
    }
}
export function isImageTabLocked(attractionId: string) {
    return isTabActive(attractionId, 'ATTRACTION_IMAGES') === 'LOCKED'
} 